import { Module } from 'vuex';

import { marketplaceSkusService } from '@/services/marketplace-skus/marketplace-skus.service';
import { RootState } from '@/store';
import { Thresholds } from '@/services/marketplace-skus/marketplace-skus.types';

export interface MarketplaceSkuState {
  thresholds: Thresholds;
  thresholdLoading: boolean;
  marketplaceSkuLoading: boolean;
  marketplaceSkuImporting: boolean;
}

const state: MarketplaceSkuState = {
  thresholds: null,
  thresholdLoading: false,
  marketplaceSkuLoading: false,
  marketplaceSkuImporting: false
};

const options: Module<MarketplaceSkuState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    importMarketplaceSkus: ({ commit, dispatch }, { file }: { file: File }): Promise<void> => {
      commit('marketplaceSkuImporting', true);
      return marketplaceSkusService
        .importMarketplaceSkus(file)
        .then(resp =>
          dispatch(
            'alert/pushSuccess',
            `${resp.count} correspondance${resp.count > 1 ? 's' : ''} importée${resp.count > 1 ? 's' : ''} !`,
            { root: true }
          )
        )
        .then(() => dispatch('table/listRows', null, { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('marketplaceSkuImporting', false));
    },
    deleteMarketplaceSku: ({ commit, dispatch }, marketplaceSkuId: number): Promise<void> => {
      commit('marketplaceSkuLoading', true);
      return marketplaceSkusService
        .delete(marketplaceSkuId)
        .then(() => dispatch('alert/pushSuccess', 'Correspondance SKU supprimée !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('marketplaceSkuLoading', false));
    },
    activeThresholds: ({ commit, dispatch, rootGetters }): Promise<void> => {
      commit('thresholdLoading', true);
      return marketplaceSkusService
        .thresholds(rootGetters['table/queryOptions'])
        .then(ths => ths && commit('thresholds', ths))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('thresholdLoading', false));
    },
    addThresholds: ({ commit }, ths: number[]): void => {
      commit('addThreshold', ths);
    },
    uptThresholds: ({ commit }, payload): void => {
      commit('uptThreshold', payload);
    },
    removeThresholds: ({ commit }, index: number): void => {
      commit('rmvThreshold', index);
    },
    saveThresholds: ({ commit, dispatch, rootGetters }, thresholds: number[][]): Promise<void> => {
      commit('thresholdLoading', true);
      return marketplaceSkusService
        .saveThresholds(rootGetters['table/queryOptions'], thresholds)
        .then(ths => ths && commit('thresholds', ths))
        .then(() => dispatch('alert/pushSuccess', 'Seuils sauvegardés avec succès !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('thresholdLoading', false));
    }
  },
  mutations: {
    thresholds: (state, thresholds) => (state.thresholds = thresholds),
    thresholdLoading: (state, loading: boolean) => (state.thresholdLoading = loading),
    addThreshold: (state, thresholds) => state.thresholds.thresholds.push(thresholds),
    uptThreshold: (state, payload) => (state.thresholds.thresholds[payload.index] = payload.ths),
    rmvThreshold: (state, index: number) => state.thresholds.thresholds.splice(index, 1),
    marketplaceSkuLoading: (state, loading) => (state.marketplaceSkuLoading = loading),
    marketplaceSkuImporting: (state, importing) => (state.marketplaceSkuImporting = importing)
  },
  getters: {
    allThresholds: state => state.thresholds.thresholds,
    loading: state => state.thresholdLoading
  }
};

export default options;
