import { AppExternalError } from '@/core/errors/app-external-error.class';
import { CollectionName } from '@/services/collections/collections.types';
import { SaveBase64Options } from '../file/file.types';
import { TablePaginationOptions } from '../tables/tables.types';

export enum DataExportStatus {
  WAITING = 'waiting',
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error'
}

export interface DataExport {
  id: string;
  export_type:
    | 'device_stock'
    | 'device_stock_adv'
    | 'accessory_stock'
    | 'device_orders'
    | 'accessory_orders'
    | 'orders_adv'
    | 'stock_movements'
    | 'controls'
    | 'preparations'
    | 'shipments'
    | 'process_errors';
  status: DataExportStatus;
  file_id?: string;
  file_name?: string;
  nb_lines?: number;
  error?: string;
  created_at: string;
}

export interface DataExportsList {
  count: number;
  exports: DataExport[];
}

export type DataExportFile = SaveBase64Options;

export interface DataExportsListOptions {
  filters?: Partial<Record<keyof DataExport, string[]>>;
  pagination?: TablePaginationOptions;
}

export class InvalidDataExportFileError extends AppExternalError {
  constructor() {
    super(`Le fichier d'export n'a pas le format attendu.`);
  }
}

export interface DataExportFilters {
  start_date: string;
  end_date: string;
}

export interface DataExportOptions extends DataExportFilters {
  collection: CollectionName;
}
