import { Module } from 'vuex';
import { uniqueId } from 'lodash';

import { RootState } from '@/store';
import { Alert } from '@/components/Misc/AlertFeed/AlertFeed.types';

export interface AlertState {
  alerts: Alert[];
}

const state: AlertState = {
  alerts: []
};

const options: Module<AlertState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    pushAlert: ({ commit, state, dispatch }, alert: Alert): void => {
      if (state.alerts.find(al => al.text === alert.text)) return;
      const alertId = alert.id || (alert.id = uniqueId());
      commit('alerts', [...state.alerts, alert]);
      if (alert.duration) setTimeout(() => dispatch('deleteAlert', alertId), alert.duration * 1000);
    },
    pushInfo: ({ dispatch }, notification: string): void => {
      const alert = {
        type: 'primary',
        text: notification,
        duration: 5
      };
      dispatch('pushAlert', alert);
    },
    pushSuccess: ({ dispatch }, notification: string): void => {
      const alert = {
        type: 'success',
        text: notification,
        duration: 5
      };
      dispatch('pushAlert', alert);
    },
    pushError: ({ dispatch }, error: Error): void => {
      const alert = {
        type: 'error',
        text: error.message,
        duration: 5
      };
      dispatch('pushAlert', alert);
      throw error;
    },
    deleteAlert: ({ commit, state }, alertId: string): void => {
      commit('alerts', [...state.alerts.filter(alert => alert.id !== alertId)]);
    }
  },
  mutations: {
    alerts: (state, alerts) => (state.alerts = alerts)
  }
};

export default options;
