import { Module } from 'vuex';

import { RootState } from '@/store';
import { Comment } from '@/services/comments/comments.types';
import { commentsService } from '@/services/comments/comments.service';

export interface CommentState {
  comment: Comment;
  commentLoading: boolean;
  comments: Comment[];
  commentsLoading: boolean;
}

const state: CommentState = {
  comment: null,
  commentLoading: false,
  comments: [],
  commentsLoading: false
};

const options: Module<CommentState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    indexComments: async (
      { commit, dispatch },
      { subject, subjectId }: { subject: string; subjectId: number }
    ): Promise<void> => {
      commit('comments', []);
      commit('commentsLoading', true);

      return commentsService
        .index(subject, subjectId)
        .then(comments => comments && commit('comments', comments))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('commentsLoading', false));
    },
    pushComment: (
      { commit, dispatch, state },
      { subject, subjectId, content }: { subject: string; subjectId: number; content: string }
    ): Promise<void> => {
      return commentsService
        .create(subject, subjectId, content)
        .then(comment => comment && commit('comments', [comment, ...state.comments]))
        .catch(error => dispatch('alert/pushError', error, { root: true }));
    },
    clearComments: ({ commit }): void => {
      commit('comment', null);
      commit('commentLoading', false);
      commit('comments', []);
      commit('commentsLoading', false);
    }
  },
  mutations: {
    comment: (state, comment) => (state.comment = comment),
    commentLoading: (state, isLoading) => (state.commentLoading = isLoading),
    comments: (state, comments) => (state.comments = comments),
    commentsLoading: (state, isLoading) => (state.commentsLoading = isLoading)
  }
};

export default options;
