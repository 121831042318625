import { Module } from 'vuex';

import { reportsService } from '@/services/reports/reports.service';
import { Report } from '@/services/reports/reports.types';
import { RootState } from '@/store';

export interface ReportsState {
  reports: Report[];
  reportLoading: boolean;
}

const state: ReportsState = {
  reports: [],
  reportLoading: false
};

const options: Module<ReportsState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    saveReport: ({ commit, dispatch }, options: { report_id: number; language: string }): Promise<void> => {
      commit('reportLoading', true);
      const { report_id, language } = options;
      return reportsService
        .downloadReport(report_id, language)
        .then(report => report && dispatch('file/saveBase64As', report, { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('reportLoading', false));
    },
    generateNewReport: ({ commit, dispatch }, { serial_no, step }): Promise<void> => {
      commit('reportLoading', true);
      return reportsService
        .createReport(serial_no, step)
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('reportLoading', false));
    },
    getReports: ({ commit, dispatch }, serial_no: string): Promise<void> => {
      commit('reportLoading', true);
      return reportsService
        .list(serial_no)
        .then(reports => reports && commit('reports', reports))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('reportLoading', false));
    }
  },
  mutations: {
    reports: (state, loading) => (state.reports = loading),
    reportLoading: (state, loading: boolean) => (state.reportLoading = loading)
  }
};

export default options;
