import axios from '@/core/axios';

import { Location } from './locations.types';

class LocationsService {
  index(): Promise<Location[]> {
    return axios.get('/locations').then(resp => resp?.data?.locations);
  }

  get(name: string): Promise<Location> {
    return axios.get<Location>(`/locations/${name}`).then(resp => {
      return resp?.data;
    });
  }

  put(payload: any): Promise<any> {
    return axios.post('locations/put', payload).then(resp => resp?.data);
  }

  replace(payload: any): Promise<any> {
    return axios.post('locations/replace', payload).then(resp => resp?.data);
  }

  move(payload: any): Promise<any> {
    return axios.post('locations/move', payload).then(resp => resp?.data);
  }

  release(payload: any): Promise<any> {
    return axios.post('locations/release', payload).then(resp => resp?.data);
  }
}

export const locationsService = new LocationsService();
