import { User } from '../users/users.types';
import { AppExternalError } from '@/core/errors/app-external-error.class';

export interface Credentials {
  email: string;
  password: string;
}

export interface SignInResponse {
  user: User;
  token: string;
}

export class SessionExpiredError extends AppExternalError {
  constructor() {
    super('Votre session a expiré, veuillez vous reconnecter.');
  }
}
