import { Module } from 'vuex';

import { RootState } from '@/store';
import { Reference, ReferencesListOptions } from '@/services/references/references.types';
import { referencesService } from '@/services/references/references.service';

export interface ReferenceState {
  reference: Reference;
  referenceLoading: boolean;
}

const state: ReferenceState = {
  reference: null,
  referenceLoading: false
};

const options: Module<ReferenceState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setRef: ({ commit, dispatch }, reference: Reference | string | number): Promise<void> => {
      if (typeof reference === 'number' || typeof reference === 'string') return dispatch('getReference', reference);
      else commit('reference', reference);
    },
    getReference: ({ commit, dispatch }, referenceId: string): Promise<void> => {
      commit('reference', null);
      commit('referenceLoading', true);
      return referencesService
        .get(referenceId)
        .then(reference => reference && commit('reference', reference))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('referenceLoading', false));
    },
    listReferences: ({ commit, dispatch }, options?: ReferencesListOptions): Promise<void> => {
      commit('referencesListLoading', true);
      return referencesService
        .list(options)
        .then(list => list && commit('referencesList', list))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('referencesListLoading', false));
    }
  },
  mutations: {
    reference: (state, reference) => (state.reference = reference),
    referenceLoading: (state, loading) => (state.referenceLoading = loading)
  }
};

export default options;
