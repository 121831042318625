import axios from '@/core/axios';

import { Consumable, ConsumablesListOptions } from './consumables.types';
import { CollectionList } from '../collections/collections.types';

export class ConsumablesService {
  list(opts: ConsumablesListOptions = {}): Promise<CollectionList<'consumables'>> {
    const { filters, pagination, options } = opts;

    return axios
      .get<CollectionList<'consumables'>>(`/consumables`, { params: { ...filters, ...pagination, ...options } })
      .then(resp => resp?.data);
  }

  update(consumableId: number, consumable: Partial<Consumable>): Promise<Consumable> {
    if (!consumableId) throw new Error('Missing consumable id in update');
    return axios.put<Consumable>(`consumables/${consumableId}`, { consumable }).then(resp => resp?.data);
  }
}

export const consumablesService = new ConsumablesService();
