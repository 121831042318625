import axios from '@/core/axios';

import { DeviceEvent } from './device-events.types';

export class DeviceEventsService {
  list(deviceId: number): Promise<DeviceEvent[]> {
    return axios.get<DeviceEvent[]>('/device-events', { params: { device_id: deviceId } }).then(resp => resp?.data);
  }
}

export const deviceEventsService = new DeviceEventsService();
