import { Module } from 'vuex';

import { RootState } from '@/store';
import { Device } from '@/services/devices/devices.types';
import { devicesService } from '@/services/devices/devices.service';
import { Location } from '@/services/locations/locations.types';
import { locationsService } from '@/services/locations/locations.service';
import { Picking } from '@/services/pickings/pickings.types';
import { pickingsService } from '@/services/pickings/pickings.service';
import { Match } from '@/services/matches/matches.types';
import { matchesService } from '@/services/matches/matches.service';

export interface RetrievalState {
  device: Device;
  deviceLoading: boolean;
  location: Location;
  locationLoading: boolean;
  picking: Picking;
  pickingLoading: boolean;
  matchesList: Match[];
  matchesListLoading: boolean;
}

const state: RetrievalState = {
  device: null,
  deviceLoading: false,
  location: null,
  locationLoading: false,
  picking: null,
  pickingLoading: false,
  matchesList: [],
  matchesListLoading: false
};

const options: Module<RetrievalState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    viewDepositItem: ({ commit, dispatch }, payload): Promise<void> => {
      commit(`${payload.type}Loading`, true);
      const service = payload.type === 'device' ? devicesService : locationsService;
      const promise = service.get(payload.value) as Promise<Device | Location>;
      return promise
        .then(result => commit(payload.type, result))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit(`${payload.type}Loading`, false));
    },
    replaceLocation: ({ dispatch, state }): Promise<void> => {
      return locationsService
        .replace({ location: state.location.name, device: state.device.serial_no })
        .then(() =>
          dispatch('alert/pushSuccess', "Ordinateur placé dans l'emplacement " + state.location.name, { root: true })
        )
        .catch(error => dispatch('alert/pushError', error, { root: true }));
    },
    deviceLost: ({ dispatch, state }): Promise<void> => {
      return devicesService
        .lost({ location: state.location.name, device: state.device.serial_no })
        .then(() =>
          dispatch('alert/pushSuccess', "Ordinateur placé dans l'emplacement " + state.location.name, { root: true })
        )
        .catch(error => dispatch('alert/pushError', error, { root: true }));
    },
    moveDevice: ({ dispatch, state }, cartName: string): Promise<void> => {
      return locationsService
        .move({
          currentLocation: state.device.location.name,
          newLocation: cartName,
          device: state.device.serial_no
        })
        .then(() => dispatch('alert/pushSuccess', "Ordinateur déplacé dans l'emplacement " + cartName, { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }));
    },
    getPicking: ({ commit, dispatch }, pickingId: number): Promise<void> => {
      commit('picking', null);
      commit('pickingLoading', true);
      return pickingsService
        .get(pickingId)
        .then(picking => picking && commit('picking', picking))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('pickingLoading', false));
    },
    getMatchesByPicking: ({ commit, dispatch }, pickingId: number): Promise<void> => {
      commit('matchesList', null);
      commit('matchesListLoading', true);
      return matchesService
        .getByPicking(pickingId)
        .then(matchesList => matchesList && commit('matchesList', matchesList))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('matchesListLoading', false));
    },
    moveStock: async (
      { commit, dispatch },
      { stockId, reason }: { stockId: number; reason: string }
    ): Promise<void> => {
      commit('deviceLoading', true);
      return devicesService
        .move(state.device.serial_no, stockId, reason)
        .then(device => device && commit('device', device))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceLoading', false));
    },
    startPicking: async ({ rootState, dispatch }): Promise<void> => {
      const user = rootState?.user?.authenticatedUser;
      if (!state.picking.id || !user) return;
      return pickingsService.start(state.picking.id).catch(error => dispatch('alert/pushError', error, { root: true }));
    },
    validatePicking: async ({ rootState, dispatch }): Promise<void> => {
      const user = rootState?.user?.authenticatedUser;
      if (!state.picking.id || !user) return;
      return pickingsService
        .validate(state.picking.id)
        .catch(error => dispatch('alert/pushError', error, { root: true }));
    },
    clearLocation({ commit }): void {
      commit('location', null);
      commit('locationLoading', false);
    },
    clearDevice({ commit }): void {
      commit('device', null);
      commit('deviceLoading', false);
    }
  },
  mutations: {
    device: (state, device) => (state.device = device),
    deviceLoading: (state, loading) => (state.deviceLoading = loading),
    location: (state, location) => (state.location = location),
    locationLoading: (state, loading) => (state.locationLoading = loading),
    picking: (state, picking) => (state.picking = picking),
    pickingLoading: (state, loading) => (state.pickingLoading = loading),
    matchesList: (state, matchesList) => (state.matchesList = matchesList),
    matchesListLoading: (state, loading) => (state.matchesListLoading = loading)
  }
};

export default options;
