import axios from '@/core/axios';

import {
  ProductionOrdersListOptions,
  ProductionOrder,
  ProductionOrderPreparationLabelData
} from './production-orders.types';
import { CollectionList } from '../collections/collections.types';

export class ProductionOrdersService {
  list(opts: ProductionOrdersListOptions = {}): Promise<CollectionList<'production-orders'>> {
    const { filters, pagination } = opts;

    return axios
      .get<CollectionList<'production-orders'>>(`/production-orders`, { params: { ...filters, ...pagination } })
      .then(resp => resp?.data);
  }

  get(productionOrderId: number): Promise<ProductionOrder> {
    return axios.get<ProductionOrder>(`/production-orders/${productionOrderId}`).then(resp => resp?.data);
  }

  newProductionOrder(): Promise<ProductionOrder> {
    return axios.get<ProductionOrder>(`production-orders/new`).then(resp => resp?.data);
  }

  create(productionOrder: ProductionOrder): Promise<ProductionOrder> {
    return axios
      .post<ProductionOrder>(`production-orders`, { production_order: productionOrder })
      .then(resp => resp?.data);
  }

  update(productionOrderId: number | string, productionOrder: Partial<ProductionOrder>): Promise<ProductionOrder> {
    if (!productionOrderId) throw new Error('Missing production order id in update');
    return axios
      .put<ProductionOrder>(`production-orders/${productionOrderId}`, { production_order: productionOrder })
      .then(resp => resp?.data);
  }

  delete(productionOrderId: number): Promise<void> {
    if (!productionOrderId) throw new Error('Missing production order id in delete');
    return axios.delete<void>(`/production-orders/${productionOrderId}`).then(resp => resp?.data);
  }

  purge(): Promise<void> {
    return axios.post<void>('/production-orders/purge').then(resp => resp?.data);
  }

  duplicate(productionOrderId: string, nTimes: number): Promise<void> {
    return axios
      .post<void>(`/production-orders/${productionOrderId}/duplicate`, { n_times: nTimes || 1 })
      .then(resp => resp?.data);
  }

  getPreparationLabel(productionOrderId: number): Promise<ProductionOrderPreparationLabelData> {
    return axios
      .get<ProductionOrderPreparationLabelData>(`/production-orders/${productionOrderId}/preparation-label`)
      .then(resp => resp?.data);
  }
}

export const productionOrdersService = new ProductionOrdersService();
