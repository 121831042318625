import { Module } from 'vuex';

import { commissionsService } from '@/services/commissions/commissions.service';
import { Commission } from '@/services/commissions/commissions.types';
import { RootState } from '@/store';
import { formsService } from '@/services/forms/forms.service';

export interface CommissionState {
  commission: Commission;
  commissionLoading: boolean;
}

const state: CommissionState = {
  commission: null,
  commissionLoading: false
};

const options: Module<CommissionState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setCommission: ({ commit, dispatch }, commission: Commission | number): Promise<void> => {
      if (typeof commission === 'number') return dispatch('getCommission', commission);
      else commit('commission', commission);
    },
    getCommission: ({ commit, dispatch }, commissionId: number): Promise<void> => {
      commit('commissionLoading', true);
      return commissionsService
        .get(commissionId)
        .then(commission => commission && commit('commission', commission))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('commissionLoading', false));
    },
    newCommission: ({ commit, dispatch }): Promise<Commission> => {
      commit('commissionLoading', true);
      return commissionsService
        .new()
        .then(commission => commission && commit('commission', commission))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('commissionLoading', false));
    },
    createCommission: ({ commit, dispatch }, commission: Commission): Promise<Commission> => {
      commit('commissionLoading', true);
      return commissionsService
        .create(commission)
        .then(commission => commission && commit('commission', commission))
        .then(() => dispatch('alert/pushSuccess', 'Commission crée !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('commissionLoading', false));
    },
    updateCommission: ({ commit, state, dispatch }, data: Commission): Promise<void> => {
      commit('commissionLoading', true);
      return commissionsService
        .update(state.commission.id, data)
        .then(commission => commission && commit('commission', commission))
        .then(() => dispatch('alert/pushSuccess', 'Commission mise à jour !', { root: true }))
        .then(() => dispatch('table/listRows', null, { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('commissionLoading', false));
    },
    deleteCommission: ({ commit, dispatch }, commissionId: number): Promise<void> => {
      commit('commissionLoading', true);
      return commissionsService
        .delete(commissionId)
        .then(() => commit('commission', null))
        .then(() => dispatch('alert/pushSuccess', 'Commission supprimé !', { root: true }))
        .then(() => dispatch('table/listRows', null, { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('commissionLoading', false));
    }
  },
  mutations: {
    commission: (state, commission: Commission) => (state.commission = commission),
    commissionLoading: (state, loading: boolean) => (state.commissionLoading = loading)
  },
  getters: {
    commissionForm: (state, getters, rootState) =>
      formsService.getForm('commission', state.commission, rootState['global-settings'].settings),
    commissionCreationForm: (state, getters, rootState) =>
      formsService.getForm('commission-creation', state.commission, rootState['global-settings'].settings, {
        marketplaces: rootState['marketplace'].marketplaces
      })
  }
};

export default options;
