import axios from '@/core/axios';

import { Marketplace } from './marketplaces.types';

export class MarketplaceService {
  async index(): Promise<Marketplace> {
    const resp = await axios.get<Marketplace>(`/marketplaces`);
    return resp?.data;
  }
}

export const marketplaceService = new MarketplaceService();
