import { Module } from 'vuex';
import { RootState } from '@/store';
import { Parts } from '@/services/parts/parts.types';
import { PartsService } from '@/services/parts/parts.service';
import { formsService } from '@/services/forms/forms.service';

const partsService = new PartsService();

export interface PartsState {
  pieces: Parts;
  piecesCount: number;
  piecesLoading: boolean;
  partLabelLoading: boolean;
  coque: any;
  dalle: any;
  coqueLoading: boolean;
  dalleLoading: boolean;
}

const state: PartsState = {
  pieces: null,
  piecesCount: 0,
  partLabelLoading: false,
  coque: null,
  dalle: null,
  piecesLoading: false,
  coqueLoading: false,
  dalleLoading: false
};

const pieces: Module<PartsState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setPiece: ({ commit, dispatch }, pieces: Parts | string | number): Promise<void> => {
      if (typeof pieces === 'number' || typeof pieces === 'string') return dispatch('getPieceByserialNumber', pieces);
      else commit('setPieces', pieces);
    },
    async fetchPieces({ commit, dispatch }): Promise<any> {
      commit('setPiecesLoading', true);
      try {
        const result = await partsService.list();
        commit('setPieces', result);
        return result;
      } catch (error) {
        console.error('An error occurred while fetching pieces:', error);
        dispatch('alert/pushError', error, { root: true });
        throw error;
      } finally {
        commit('setPiecesLoading', false);
      }
    },

    async getPiece({ dispatch, commit }, pieceId: number): Promise<Parts> {
      commit('setPiecesLoading', true);
      return partsService
        .get(pieceId)
        .then(piece => piece && commit('setPieces', pieces))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('setPiecesLoading', false));
    },

    createPiece: async ({ commit, dispatch }, piece: Parts): Promise<Parts> => {
      commit('setPiecesLoading', true);
      try {
        const createdPiece = await partsService.create(piece);
        if (createdPiece && createdPiece.serial_number) {
          commit('setPieces', createdPiece);
          dispatch('alert/pushSuccess', 'Écran démonté avec succès !', { root: true });
          return createdPiece;
        } else {
          throw new Error('Le numéro de série est manquant ou invalide');
        }
      } catch (error) {
        console.error('Erreur lors de la création:', error);
        dispatch('alert/pushError', error.message || error, { root: true });
        throw error;
      } finally {
        commit('setPiecesLoading', false);
      }
    },

    async getPieceByserialNumber({ dispatch, commit }, serialNumber: string): Promise<Parts> {
      commit('setPiecesLoading', true);
      try {
        const piece = await partsService.getBySerialNumber(serialNumber);
        commit('setPieces', piece);
        return piece;
      } catch (error) {
        dispatch('alert/pushError', error, { root: true });
        throw error;
      } finally {
        commit('setPiecesLoading', false);
      }
    },
    destructed: ({ commit, dispatch }, pieceId: number): Promise<void> => {
      commit('setPiecesLoading', true);
      return partsService
        .destructed(pieceId)
        .then(piece => piece && commit('setPieces', pieces))
        .then(() => dispatch('alert/pushSuccess', 'Piéce détruite avec succès !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('setPiecesLoading', false));
    },
    printMiniLabel: ({ commit, dispatch }, serialNo: string): Promise<void> => {
      commit('partLabelLoading', true);
      return partsService
        .getMiniLabel(serialNo)
        .then(zpl => zpl && dispatch('printer/printWith', { data: [zpl], printer: 'MINI' }, { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('partLabelLoading', false));
    },
    dismantlePiece: ({ commit, dispatch }, pieceId: number): Promise<void> => {
      commit('setPiecesLoading', true);
      return partsService
        .dismantle(pieceId)
        .then(piece => piece && commit('setPieces', pieces))
        .then(() => dispatch('alert/pushSuccess', 'Pièce démontelée avec succès !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('setPiecesLoading', false));
    },

    clearParts({ commit }): void {
      commit('setPieces', null);
      commit('setPiecesLoading', false);
    },

    assembleParts: ({ commit, dispatch }, { dalleSerialNumber, coqueSerialNumber }): Promise<void> => {
      commit('setPiecesLoading', true);
      return partsService
        .assembleParts(dalleSerialNumber, coqueSerialNumber)
        .then(piece => piece && commit('setPieces', pieces))
        .then(() => dispatch('alert/pushSuccess', 'Assemblage réussi !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('setPiecesLoading', false));
    }
  },
  mutations: {
    setPieces(state, pieces: Parts): void {
      state.pieces = pieces;
    },
    setPiecesCount(state, count: number): void {
      state.piecesCount = count;
    },
    partLabelLoading: (state, loading) => (state.partLabelLoading = loading),
    coque: (state, coque) => (state.coque = coque),
    coqueLoading: (state, loading) => (state.coqueLoading = loading),
    setPiecesLoading(state, loading): void {
      state.piecesLoading = loading;
    },
    dalle: (state, dalle) => (state.dalle = dalle),
    dalleLoading: (state, loading) => (state.dalleLoading = loading)
  },
  getters: {
    partsForm: (state, getters, rootState) =>
      formsService.getForm('parts', state.pieces, rootState['global-settings'].settings)
  }
};

export default pieces;
