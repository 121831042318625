import { Module } from 'vuex';

import { RootState } from '@/store';
import { AdminError } from '@/services/errors/errors.types';
import { errorsService } from '@/services/errors/errors.service';

export interface ErrorState {
  errors: AdminError[];
  errorsLoading: boolean;
}

const state: ErrorState = {
  errors: [],
  errorsLoading: false
};

const options: Module<ErrorState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    indexErrors: async (
      { commit, dispatch },
      { subject, subjectId }: { subject: string; subjectId: string }
    ): Promise<void> => {
      commit('errors', []);
      commit('errorsLoading', true);

      return errorsService
        .index(subject, subjectId)
        .then(errors => errors && commit('errors', errors))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('errorsLoading', false));
    },
    createError: (
      { dispatch },
      {
        subject,
        subjectId,
        details,
        type
      }: { subject: string; subjectId: string; details: string; type: 'error' | 'warning' }
    ): Promise<void> => {
      return errorsService
        .create(subject, subjectId, { details, type })
        .then(() => dispatch('alert/pushSuccess', 'Erreur signalée avec succès !', { root: true }))
        .then(() => dispatch('indexErrors', { subject, subjectId }))
        .catch(error => dispatch('alert/pushError', error, { root: true }));
    },
    resolveError: ({ dispatch }, errorId: string): Promise<void> => {
      return errorsService
        .update(errorId, { fixed: true })
        .then(() => dispatch('alert/pushSuccess', 'Erreur résolue avec succès !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }));
    },
    clearErrors: ({ commit }): void => {
      commit('errors', []);
      commit('errorsLoading', false);
    }
  },
  mutations: {
    errors: (state, errors) => (state.errors = errors),
    errorsLoading: (state, isLoading) => (state.errorsLoading = isLoading)
  }
};

export default options;
