import axios from '@/core/axios';

import { ReferencesListOptions, ReferencesList, Reference } from './references.types';

export class ReferencesService {
  list(opts: ReferencesListOptions = {}): Promise<ReferencesList> {
    const { filters, pagination } = opts;
    return axios.post<ReferencesList>(`/references`, { filters, pagination }).then(resp => resp?.data);
  }

  get(referenceId: string): Promise<Reference> {
    return axios.get<Reference>(`/reference/${referenceId}`).then(resp => resp?.data);
  }
}

export const referencesService = new ReferencesService();
