import { Module } from 'vuex';

import { RootState } from '@/store';
import { formsService } from '@/services/forms/forms.service';
import { Supplier, SupplierRib } from '@/services/suppliers/suppliers.types';
import { suppliersService } from '@/services/suppliers/suppliers.service';

export interface SupplierState {
  supplier: Supplier;
  supplierLoading: boolean;
}

const state: SupplierState = {
  supplier: null,
  supplierLoading: false
};

const options: Module<SupplierState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setSupplier: ({ commit, dispatch }, supplier: Supplier | string | number): Promise<void> => {
      if (typeof supplier === 'number' || typeof supplier === 'string') return dispatch('getSupplier', supplier);
      else commit('supplier', supplier);
    },
    getSupplier: ({ commit, dispatch }, id: number | string): Promise<void> => {
      commit('supplierLoading', true);
      return suppliersService
        .get(+id)
        .then(supplier => supplier && commit('supplier', supplier))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('supplierLoading', false));
    },
    newSupplier: ({ commit, dispatch }): Promise<void> => {
      commit('supplier', null);
      commit('supplierLoading', true);
      return suppliersService
        .new()
        .then(supplier => supplier && commit('supplier', supplier))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('supplierLoading', false));
    },
    createSupplier: ({ commit, dispatch }, supplier: Supplier): Promise<void> => {
      commit('supplierLoading', true);
      return suppliersService
        .create(supplier)
        .then(supplier => supplier && commit('supplier', supplier))
        .then(() => dispatch('alert/pushSuccess', 'Fournisseur créé avec succès !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('supplierLoading', false));
    },
    updateSupplier: ({ commit, dispatch }, supplier: Supplier): Promise<void> => {
      commit('supplierLoading', true);
      return suppliersService
        .update(supplier.id, supplier)
        .then(supplier => supplier && commit('supplier', supplier))
        .then(() => dispatch('alert/pushSuccess', 'Fournisseur mis à jour !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('supplierLoading', false));
    },
    updateSupplierFile: (
      { commit, dispatch },
      { supplier, files }: { supplier: Supplier; files: Record<string, File> }
    ): Promise<void> => {
      commit('supplierLoading', true);
      return suppliersService
        .updateFiles(supplier.id, files)
        .then(supplier => supplier && commit('supplier', supplier))
        .then(() => dispatch('alert/pushSuccess', 'Fournisseur mis à jour !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('supplierLoading', false));
    },
    createRib: ({ commit, dispatch }, { supplierId, rib }: { supplierId: number; rib: SupplierRib }): Promise<void> => {
      commit('supplierLoading', true);
      return suppliersService
        .createRib(supplierId, rib)
        .then(supplier => supplier && commit('supplier', supplier))
        .then(() => dispatch('alert/pushSuccess', 'Fournisseur mis à jour !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('supplierLoading', false));
    },
    updateRib: ({ commit, dispatch }, { supplierId, rib }: { supplierId: number; rib: SupplierRib }): Promise<void> => {
      commit('supplierLoading', true);
      return suppliersService
        .updateRib(supplierId, rib)
        .then(supplier => supplier && commit('supplier', supplier))
        .then(() => dispatch('alert/pushSuccess', 'Fournisseur mis à jour !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('supplierLoading', false));
    },
    deleteRib: ({ commit, dispatch }, { supplierId, ribId }: { supplierId: number; ribId: number }): Promise<void> => {
      commit('supplierLoading', true);
      return suppliersService
        .deleteRib(supplierId, ribId)
        .then(supplier => supplier && commit('supplier', supplier))
        .then(() => dispatch('alert/pushSuccess', 'Fournisseur mis à jour !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('supplierLoading', false));
    },
    deleteSupplier: ({ commit, dispatch }, id: number): Promise<void> => {
      commit('supplierLoading', true);
      return suppliersService
        .delete(+id)
        .then(() => commit('supplier', null))
        .then(() => dispatch('alert/pushSuccess', 'Fournisseur supprimé !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('supplierLoading', false));
    },
    clearSupplier: ({ dispatch }): Promise<void> => {
      return dispatch('setSupplier', null);
    }
  },
  mutations: {
    supplier: (state, supplier) => (state.supplier = supplier),
    supplierLoading: (state, loading) => (state.supplierLoading = loading)
  },
  getters: {
    supplierEditionForm: (state, getters, rootState) =>
      state.supplier && formsService.getForm('supplier', state.supplier, rootState['global-settings'].settings),
    supplierCreationForm: (state, getters, rootState) =>
      formsService.getForm('supplier-creation', state.supplier, rootState['global-settings'].settings),
    supplierDisplayForm: (state, getters, rootState) =>
      state.supplier && formsService.getForm('supplier-display', state.supplier, rootState['global-settings'].settings)
  }
};

export default options;
