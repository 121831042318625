import { defineComponent, PropType } from 'vue';

import { datesService } from '@/services/dates/dates.service';

export const valueControlMixin = defineComponent({
  props: {
    /* Can be a number or a date string (ISO) */
    value: { type: [Number, String], default: null },
    /* Can be a number or a date string */
    controlReference: { type: Number, default: new Date().getTime() },
    /* Can only be a number (if date, pass a date time in milliseconds) */
    controlResolution: { type: Number, default: 1000 * 60 * 60 * 24 }, // 1 day
    controlType: { type: String as PropType<'date' | 'number'>, default: null }
  },
  computed: {
    checkedValue(): number {
      if (typeof this.value === 'string') return datesService.parse(this.value).getTime();
      else if (typeof this.value === 'number') return this.value;
      else throw new Error('Unknown value type');
    },
    normalizedControlValue(): number {
      if (!this.controlType) return;
      return (this.checkedValue - this.controlReference) / this.controlResolution;
    },
    controlClass(): string {
      if (this.normalizedControlValue >= 1) return 'text-success';
      else if (this.normalizedControlValue <= -1) return 'text-danger';
      else if (this.normalizedControlValue < 1 && this.normalizedControlValue > -1) return 'text-warning';
      else return null;
    }
  }
});
