import moment from 'moment';

class DatesService {
  format(dateString: string, format = 'Do MMMM YYYY HH:mm'): string {
    try {
      return moment(dateString).format(format);
    } catch {
      return '[Invalide]';
    }
  }

  parse(dateString: string, format = 'Do MMMM YYYY HH:mm'): Date {
    return moment(dateString, format).toDate();
  }

  add(dateString: string, time: number, type, format = 'YYYY-MM-DD HH:mm Z'): string {
    return moment(dateString, format).add(time, type).toISOString();
  }

  today(): string {
    return moment().toISOString();
  }

  before(dateString: string, beforeDateString: string): boolean {
    return moment(dateString, 'YYYY-MM-DD HH:mm Z').isSameOrBefore(beforeDateString);
  }

  diff(date1: string, date2: string): number {
    const DATE1 = moment(date1, 'YYYY-MM-DD HH:mm:ss Z');
    const DATE2 = moment(date2, 'YYYY-MM-DD HH:mm:ss Z');
    return parseFloat(DATE2.diff(DATE1, 'minutes', true).toFixed(2));
  }
}

export const datesService = new DatesService();
