import { Module } from 'vuex';

import { devicesService } from '@/services/devices/devices.service';
import { Device } from '@/services/devices/devices.types';
import { locationsService } from '@/services/locations/locations.service';
import { Location } from '@/services/locations/locations.types';
import { RootState } from '@/store';

export interface DepositState {
  device: Device;
  deviceLoading: boolean;
  location: any;
  locationLoading: boolean;
  lastLocation: any;
  lastLocationLoading: boolean;
}

const state: DepositState = {
  device: null,
  deviceLoading: false,
  location: null,
  locationLoading: false,
  lastLocation: null,
  lastLocationLoading: false
};

const options: Module<DepositState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    putDeviceToLocation: ({ dispatch, state }): Promise<void> => {
      return locationsService
        .put({ location: state.location.name, device: state.device.serial_no })
        .then(() =>
          dispatch('alert/pushSuccess', "Ordinateur placé dans l'emplacement " + state.location.name, { root: true })
        );
    },
    viewDepositItem: ({ commit, dispatch }, payload): Promise<void> => {
      commit(`${payload.type}Loading`, true);
      const service = payload.type === 'device' ? devicesService : locationsService;
      const promise = service.get(payload.value) as Promise<Device | Location>;
      return promise
        .then(result => commit(payload.type, result))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit(`${payload.type}Loading`, false));
    },
    saveLastLocation: ({ commit, dispatch }, location): Promise<void> => {
      commit('lastLocationLoading', true);
      dispatch('clearLastLocation');
      return locationsService
        .get(location)
        .then(location => commit('lastLocation', location))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('lastLocationLoading', false));
    },
    replaceLocation: ({ dispatch, state }): Promise<void> => {
      return locationsService
        .replace({ location: state.location.name, device: state.device.serial_no })
        .then(() =>
          dispatch('alert/pushSuccess', "Ordinateur placé dans l'emplacement " + state.location.name, { root: true })
        )
        .catch(error => dispatch('alert/pushError', error, { root: true }));
    },
    clearDevice({ commit }): void {
      commit('device', null);
      commit('deviceLoading', false);
    },
    clearLocation({ commit }): void {
      commit('location', null);
      commit('locationLoading', false);
    },
    clearLastLocation({ commit }): void {
      commit('lastLocation', null);
      commit('lastLocationLoading', false);
    }
  },
  mutations: {
    device: (state, device) => (state.device = device),
    deviceLoading: (state, loading) => (state.deviceLoading = loading),
    location: (state, location) => (state.location = location),
    locationLoading: (state, loading) => (state.locationLoading = loading),
    lastLocation: (state, location) => (state.lastLocation = location),
    lastLocationLoading: (state, loading) => (state.lastLocationLoading = loading)
  }
};

export default options;
