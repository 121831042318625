import { Module } from 'vuex';

import { RootState } from '@/store';
import { dataExportsService } from '@/services/data-exports/data-exports.service';
import { DataExportsList, DataExportOptions } from '@/services/data-exports/data-exports.types';

export interface DataExportState {
  dataExportsList: DataExportsList;
  dataExportsLoading: boolean;
  savingFile: boolean;
}

const state: DataExportState = {
  dataExportsList: null,
  dataExportsLoading: false,
  savingFile: false
};

const options: Module<DataExportState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    indexExports({ commit, dispatch }): Promise<void> {
      commit('dataExportsLoading', true);
      return dataExportsService
        .list()
        .then(list => list && commit('dataExportsList', list))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('dataExportsLoading', false));
    },
    exportCollection({ dispatch }, options: DataExportOptions): Promise<void> {
      return dataExportsService
        .export(options)
        .then(() => dispatch('indexExports'))
        .then(() =>
          dispatch(
            'alert/pushSuccess',
            "L'export est en cours, il va s'afficher dans la liste d'ici quelques secondes.",
            { root: true }
          )
        )
        .catch(error => dispatch('alert/pushError', error, { root: true }));
    },
    saveFile: ({ commit, dispatch }, fileId: string): Promise<void> => {
      commit('savingFile', true);
      return dataExportsService
        .getFile(fileId)
        .then(file => dispatch('file/saveBase64As', file, { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('savingFile', false));
    }
  },
  mutations: {
    dataExportsList: (state, list) => (state.dataExportsList = list),
    dataExportsLoading: (state, isLoading) => (state.dataExportsLoading = isLoading),
    savingFile: (state, isSaving) => (state.savingFile = isSaving)
  },
  getters: {
    dataExports: state => state.dataExportsList?.exports || [],
    dataExportsCount: state => state.dataExportsList?.count || 0
  }
};

export default options;
