import { User, UserDivision, UserRoleType } from '@/services/users/users.types';
import { AppSectionCode } from '@/services/app-sections/app-sections.types';

interface CheckUserAccessOptions {
  division?: UserDivision;
  currentSection?: AppSectionCode;
}

const checkRoles = (user: User, roles: UserRoleType[]): boolean => {
  return user?.roles?.some(userRole => {
    return userRole.name === UserRoleType.ADMIN || roles.includes(userRole.name);
  });
};

// Check if user division matches given division
const checkDivision = (user: User, division: UserDivision): boolean => {
  return user.division === division;
};

// Check if user's division and current app section are compatible
const checkSection = (user: User, section: AppSectionCode): boolean => {
  const divisionSectionDictionary = {
    [UserDivision.CONTROL]: AppSectionCode.CONTROL,
    [UserDivision.PREPARATION]: AppSectionCode.PREPARATION,
    [UserDivision.DISPATCH]: AppSectionCode.EXPEDITION
  };
  return divisionSectionDictionary[user.division] === section;
};

export const checkUserAccess = (
  user: User,
  roles: UserRoleType[],
  { division, currentSection }: CheckUserAccessOptions = {}
): boolean => {
  if (!user) return false;
  if (currentSection) return checkRoles(user, roles) && checkSection(user, currentSection);
  if (division) return checkRoles(user, roles) && checkDivision(user, division);
  return checkRoles(user, roles);
};
