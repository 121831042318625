import { Module } from 'vuex';

import { RootState } from '@/store';
import {
  MatchError,
  MatchErrorCheckParams,
  MatchErrorsListOptions,
  ReportMatchErrorOptions
} from '@/services/match-errors/match-errors.types';
import { CollectionList } from '@/services/collections/collections.types';
import { matchErrorsService } from '@/services/match-errors/match-errors.service';
import { commentsService } from '@/services/comments/comments.service';
import { stocksStatusService } from '@/services/stocks-status/stocks-status.service';

export interface MatchErrorState {
  matchError: MatchError;
  matchErrorLoading: boolean;
  matchErrorsList: CollectionList<'match-errors'>;
}

const state: MatchErrorState = {
  matchError: null,
  matchErrorLoading: false,
  matchErrorsList: null
};

const options: Module<MatchErrorState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setMatchError: ({ commit, dispatch }, matchError: MatchError | string | number): Promise<void> => {
      if (typeof matchError === 'number' || typeof matchError === 'string')
        return dispatch('getMatchError', matchError);
      else commit('matchError', matchError);
    },
    getMatchError: ({ commit, dispatch }, matchErrorId: number): Promise<void> => {
      commit('matchErrorLoading', true);
      return matchErrorsService
        .get(matchErrorId)
        .then(matchError => matchError && commit('matchError', matchError))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('matchErrorLoading', false));
    },
    indexMatchErrors: ({ commit, dispatch }, options: MatchErrorsListOptions): Promise<void> => {
      return matchErrorsService
        .list(options)
        .then(matchErrorsList => matchErrorsList && commit('matchErrorsList', matchErrorsList))
        .catch(error => dispatch('alert/pushError', error, { root: true }));
    },
    checkMatchError: ({ commit, dispatch, state }, filters: MatchErrorCheckParams): Promise<void> => {
      const options = { filters, pagination: { limit: 1 } };
      return dispatch('indexMatchErrors', options)
        .then(() => state.matchErrorsList?.count > 0 && commit('matchError', state.matchErrorsList.values[0]))
        .catch(error => dispatch('alert/pushError', error, { root: true }));
    },
    createMatchError: ({ dispatch, commit }, options: ReportMatchErrorOptions): Promise<any> => {
      const { matchId, stockId, statusId, comment, dissociate, settings } = options;
      const action: 'dissociate' | 'relocate' = dissociate ? 'dissociate' : 'relocate';
      const level: 'error' | 'warning' = dissociate ? 'error' : 'warning';
      const stockstatusId = stocksStatusService.getWithStockAndStatus(stockId, statusId, settings.stocks_status).id;
      const error = { action, level, to_stock_id: stockstatusId, match_id: matchId, comment };
      return Promise.all([
        commentsService.create('match', matchId, comment),
        matchErrorsService
          .create(error)
          .then(matchError => matchError && commit('matchError', matchError))
          .then(() => dispatch('alert/pushSuccess', 'Erreur signalée avec succès !', { root: true }))
      ]).catch(error => dispatch('alert/pushError', error, { root: true }));
    },
    updateMatchError: ({ commit, dispatch }, data: Partial<MatchError>): Promise<void> => {
      commit('matchErrorLoading', true);
      return matchErrorsService
        .update(data)
        .then(matchError => matchError && commit('matchError', matchError))
        .then(() => dispatch('alert/pushSuccess', 'Erreur mise à jour !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('matchErrorLoading', false));
    },
    fixMatchError: ({ state, commit, dispatch }): Promise<void> => {
      if (!state.matchError) return;
      return matchErrorsService
        .fix(state.matchError.id)
        .then(() => commit('matchError', null))
        .then(() => dispatch('alert/pushSuccess', 'Erreur résolue !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('matchErrorLoading', false));
    },
    deleteMatchError: ({ commit, dispatch }, matchErrorId: number): Promise<void> => {
      commit('matchErrorLoading', true);
      return matchErrorsService
        .delete(matchErrorId)
        .then(() => commit('matchError', null))
        .then(() => dispatch('alert/pushSuccess', 'Erreur supprimée !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('matchErrorLoading', false));
    },
    clearMatchErrorsList: ({ commit }): void => {
      commit('matchErrorsList', null);
    },
    clearMatchErrorStore: ({ commit, dispatch }): Promise<void> => {
      commit('matchError', null);
      commit('matchErrorLoading', false);
      return dispatch('clearMatchErrorsList');
    }
  },
  mutations: {
    matchError: (state, matchError) => (state.matchError = matchError),
    matchErrorLoading: (state, loading) => (state.matchErrorLoading = loading),
    matchErrorsList: (state, list) => (state.matchErrorsList = list)
  }
};

export default options;
