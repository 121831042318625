import { Module } from 'vuex';

import { RootState } from '@/store';
import { authService } from '@/services/auth/auth.service';
import { Credentials } from '@/services/auth/auth.types';

export interface AuthState {
  token: string;
  tenant: string;
  signingIn: boolean;
}

const state: AuthState = {
  token: sessionStorage.getItem('auth-token'),
  tenant: sessionStorage.getItem('tenant') || 'fr',
  signingIn: false
};

const options: Module<AuthState, RootState> = {
  namespaced: true,
  state: () => state,
  getters: {
    isAuthenticated: state => !!state.token
  },
  actions: {
    getSession: ({ commit }): void => {
      commit('signingIn', false);

      const token = authService.getSession();
      commit('token', token);
    },
    signIn: ({ state, commit, dispatch }, payload: Credentials): Promise<void> => {
      commit('signingIn', true);
      commit('token', null);

      /* If there is already a token, no need to send the request */
      if (state.token) return;

      return authService
        .signIn(payload)
        .then(token => token && commit('token', token))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('signingIn', false));
    },
    signOut: ({ commit }): void => {
      commit('signingIn', false);
      commit('token', null);

      authService.signOut();
    },
    setTenant: ({ commit }, tenant): void => {
      sessionStorage.setItem('tenant', tenant);
      commit('tenant', tenant);
    }
  },
  mutations: {
    token: (state, token) => (state.token = token),
    tenant: (state, tenant) => (state.tenant = tenant),
    signingIn: (state, signingIn) => (state.signingIn = signingIn)
  }
};

export default options;
