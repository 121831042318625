import axios from '@/core/axios';

import { GlobalSettings } from './global-settings.types';

export class GlobalSettingsService {
  index(): Promise<GlobalSettings> {
    return axios.get('/global-settings').then(resp => resp?.data?.settings);
  }
}

export const globalSettingsService = new GlobalSettingsService();
