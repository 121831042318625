import axios from '@/core/axios';
import { datesService } from '../dates/dates.service';
import { dicoLabelsService } from '../dico-labels/dico-labels.service';
import { StockStatus } from '../stocks-status/stocks-status.types';

import { AdminError, AdminErrorDetails, ParsedError } from './errors.types';

class ErrorsService {
  index(subject: string, subjectId?: string): Promise<AdminError[]> {
    return axios.get<AdminError[]>(`/errors/${subject + (subjectId ? '/' + subjectId : '')}`).then(resp => resp?.data);
  }

  create(subject: string, subjectId: string, payload: AdminErrorDetails): Promise<AdminError> {
    const { details, type } = payload;
    return axios
      .post<AdminError>(`/errors/${subject}/${subjectId}`, { error: { details, type } })
      .then(resp => resp?.data);
  }

  update(errorId: string, error: Partial<AdminError>): Promise<AdminError> {
    return axios.put<AdminError>(`/errors/${errorId}`, { error }).then(resp => resp?.data);
  }

  delete(subject: string, subjectId: string, errorId: string): Promise<void> {
    return axios.delete<void>(`/errors/${subject}/${subjectId}/${errorId}`).then(resp => resp?.data);
  }

  parseError(error: AdminError): ParsedError {
    if (!error || !error.details) return null;
    const errorId = error.id;
    const [, action, stockId, stepCode] = /!{(.+)}:\[(.+)\]:\[(.+)\]/.exec(error.details) || [];
    const stepLabel = dicoLabelsService.decodeAppSubSection(stepCode);
    return action && stockId && stepCode ? { action, stockId, stepCode, stepLabel, errorId } : null;
  }

  parseErrorMessage(error: AdminError, stocks_status: Record<number, StockStatus>): string {
    if (!error || !error.details) return null;
    const { details, created_at, user } = error;
    const { action, stockId, stepLabel } = this.parseError(error);
    let stockLabel = stocks_status && stocks_status[stockId] && stocks_status[stockId].stock.display_name;
    if (stocks_status[stockId].status.display_name) {
      stockLabel =
        stocks_status &&
        stocks_status[stockId] &&
        stocks_status[stockId].stock.display_name + ' / ' + stocks_status[stockId].status.display_name;
    }
    if (action === 'dissociate') {
      return `Désassociation demandée le <b>${datesService.format(
        created_at
      )}</b> par <b>${dicoLabelsService.formatName(user)}</b>, pour affectation au stock [<b>${stockLabel}</b>]`;
    } else if (action === 'relocate') {
      return `Demande de renvoi à l'étape [<b>${stepLabel}</b>] effectuée par <b>${dicoLabelsService.formatName(
        user
      )}</b> le <b>${datesService.format(created_at)}</b>`;
    } else return details;
  }
}

export const errorsService = new ErrorsService();
