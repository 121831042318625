import axios from '@/core/axios';

import { ProcessTask } from './process-tasks.types';

class ProcessTasksService {
  index(step_id: number): Promise<ProcessTask[]> {
    return axios.get<ProcessTask[]>(`/tasks`, { params: { step_id } }).then(resp => resp?.data);
  }

  create(task: Partial<ProcessTask>): Promise<ProcessTask> {
    return axios.post<ProcessTask>(`/tasks`, { task }).then(resp => resp?.data);
  }

  update(taskId: number, task: ProcessTask): Promise<ProcessTask> {
    return axios.put<ProcessTask>(`/tasks/${taskId}`, { task }).then(resp => resp?.data);
  }

  validate(taskId: number): Promise<ProcessTask> {
    if (!taskId) throw new Error('Missing task id in validate');
    return axios.post<ProcessTask>(`tasks/${taskId}/validate`).then(resp => resp?.data);
  }

  validateBatch(taskIds: number[]): Promise<ProcessTask> {
    if (taskIds.length === 0) throw new Error('Missing task ids in validate');

    return axios.post<ProcessTask>('tasks/validate-batch', { ids: taskIds }).then(resp => resp?.data);
  }

  reset(taskId: number): Promise<ProcessTask> {
    if (!taskId) throw new Error('Missing task id in reset');
    return axios.post<ProcessTask>(`tasks/${taskId}/reset`).then(resp => resp?.data);
  }
}

export const processTasksService = new ProcessTasksService();
