import axios from '@/core/axios';

import { PurchaseQueriesListOptions, PurchaseQuery } from './purchase-queries.types';
import { CollectionList } from '../collections/collections.types';

export class PurchaseQueriesService {
  list(opts: PurchaseQueriesListOptions = {}): Promise<CollectionList<'purchase-queries'>> {
    const { filters, pagination } = opts;

    return axios
      .get<CollectionList<'purchase-queries'>>(`/purchase-queries`, {
        params: { ...filters, ...pagination }
      })
      .then(resp => resp?.data);
  }

  get(queryId: number): Promise<PurchaseQuery> {
    return axios.get<PurchaseQuery>(`/purchase-queries/${queryId}`).then(resp => resp?.data);
  }

  new(): Promise<PurchaseQuery> {
    return axios.get<PurchaseQuery>(`/purchase-queries/new`).then(resp => resp?.data);
  }

  create(query: PurchaseQuery): Promise<PurchaseQuery> {
    return axios.post<PurchaseQuery>(`/purchase-queries`, { query }).then(resp => resp?.data);
  }

  update(queryId: number | string, query: Partial<PurchaseQuery>): Promise<PurchaseQuery> {
    if (!queryId) throw new Error('Missing query id in update');
    return axios.put<PurchaseQuery>(`purchase-queries/${queryId}`, { query }).then(resp => resp?.data);
  }

  delete(queryId: number): Promise<void> {
    if (!queryId) throw new Error('Missing query id in delete');
    return axios.delete<void>(`/purchase-queries/${queryId}`).then(resp => resp?.data);
  }
}

export const purchaseQueriesService = new PurchaseQueriesService();
