import { Module } from 'vuex';

import { cagesService } from '@/services/cages/cages.service';
import { Cage, CageCarrierLabel } from '@/services/cages/cages.types';
import { RootState } from '@/store';
import { formsService } from '@/services/forms/forms.service';

export interface CageState {
  cage: Cage;
  cageLoading: boolean;
}

const state: CageState = {
  cage: null,
  cageLoading: false
};

const options: Module<CageState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setCage: ({ commit, dispatch }, cage: Cage | number): Promise<void> => {
      if (typeof cage === 'number') return dispatch('getCage', cage);
      else commit('cage', cage);
    },
    newCage: ({ commit, dispatch }): Promise<void> => {
      commit('cage', null);
      commit('cageLoading', true);
      return cagesService
        .newCage()
        .then(cage => cage && commit('cage', cage))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('cageLoading', false));
    },
    getCage: ({ commit, dispatch }, cageId: number): Promise<void> => {
      commit('cageLoading', true);
      return cagesService
        .get(cageId)
        .then(cage => cage && commit('cage', cage))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('cageLoading', false));
    },
    createCage: ({ commit, dispatch }, cage: Cage): Promise<void> => {
      commit('cageLoading', true);
      return cagesService
        .create(cage)
        .then(cage => cage && commit('cage', cage))
        .then(() => dispatch('alert/pushSuccess', 'Cage crée !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('cageLoading', false));
    },
    updateCage: ({ commit, dispatch }, cage: Cage): Promise<void> => {
      commit('cageLoading', true);
      return cagesService
        .update(cage.id, cage)
        .then(cage => cage && commit('cage', cage))
        .then(() => dispatch('alert/pushSuccess', 'Cage mise à jour !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('cageLoading', false));
    },
    cageFull: ({ commit, dispatch }, cage: Cage): Promise<void> => {
      commit('cageLoading', true);
      return cagesService
        .update(cage.id, cage)
        .then(cage => cage && commit('cage', cage))
        .then(() => dispatch('alert/pushSuccess', 'Cage pleine !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('cageLoading', false));
    },
    cagePicked: ({ commit, dispatch }, cage: Cage): Promise<void> => {
      commit('cageLoading', true);
      return cagesService
        .pickup(cage.id, cage)
        .then(cage => cage && commit('cage', cage))
        .then(() => dispatch('alert/pushSuccess', 'Cage ramassé !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('cageLoading', false));
    },
    cancelPickup: ({ commit, dispatch }, id: number): Promise<void> => {
      commit('cageLoading', true);
      return cagesService
        .cancelPickup(id)
        .then(cage => cage && commit('cage', cage))
        .then(() => dispatch('alert/pushSuccess', 'Ramassage annulé !', { root: true }))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('cageLoading', false));
    },
    addCageLabel: ({ commit, dispatch }, { id, tracking }: { id: number; tracking: string }): Promise<void> => {
      commit('cageLoading', true);
      return cagesService
        .addCageLabel(id, tracking)
        .then(cage => cage && commit('cage', cage))
        .then(() => dispatch('alert/pushSuccess', 'Carton ajouté !', { root: true }))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('cageLoading', false));
    },
    removeCageLabel: ({ commit, dispatch }, { id, list }: { id: number; list: CageCarrierLabel[] }): Promise<void> => {
      commit('cageLoading', true);
      return cagesService
        .removeCageLabel(id, list)
        .then(cage => cage && commit('cage', cage))
        .then(() => dispatch('alert/pushSuccess', 'Carton enlevé !', { root: true }))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('cageLoading', false));
    }
  },
  mutations: {
    cage: (state, cage) => (state.cage = cage),
    cageLoading: (state, loading) => (state.cageLoading = loading)
  },
  getters: {
    cageForm: (state, getters, rootState) =>
      formsService.getForm('cage', state.cage, rootState['global-settings'].settings),
    cageCreationForm: (state, getters, rootState) =>
      formsService.getForm('cage-creation', state.cage, rootState['global-settings'].settings),
    rows: state => {
      if (!state.cage?.within) return [];
      else return state.cage.within || [];
    }
  }
};

export default options;
