import axios from '@/core/axios';
import { Device } from '../devices/devices.types';

import { Control, ControlApiResponse } from './controls.types';

class ControlsService {
  getCurrent(sessionId: string): Promise<Control> {
    return axios.get<ControlApiResponse>(`/controls/poll/${sessionId}`).then(resp => resp?.data?.control);
  }

  /**
   * TODO: Loading for a modif in the back for this to work
   */
  get(controlId: number): Promise<Control> {
    return axios.get<Control>(`/controls/${controlId}`).then(resp => resp?.data);
  }

  create(control): Promise<Control> {
    return axios.post<Control>(`/controls`, { control, failures: [] }).then(resp => resp?.data);
  }

  update(controlId: number, control: Partial<Control>): Promise<Control> {
    return axios.put<Control>(`/controls/${controlId}`, { control }).then(resp => resp?.data);
  }

  delete(controlId: number): Promise<void> {
    if (!controlId) throw new Error('Missing control id in delete');
    return axios.delete<void>(`/controls/${controlId}`).then(resp => resp?.data);
  }

  matchPurchaseWithSerial(controlId: number, serialNo: string): Promise<Control> {
    if (!controlId) throw new Error('Missing control id in matchPurchaseWithSerial');
    if (!serialNo) throw new Error('Missing serial in matchPurchaseWithSerial');

    return axios
      .post<Control>(`/controls/${controlId}/match-purchase`, { serial_no: serialNo })
      .then(resp => resp?.data);
  }

  matchPurchaseWithImei(controlId: number, imei: string): Promise<Control> {
    if (!controlId) throw new Error('Missing control id in matchPurchaseWithImei');
    if (!imei) throw new Error('Missing imei in matchPurchaseWithImei');

    return axios.post<Control>(`/controls/${controlId}/match-purchase`, { imei }).then(resp => resp?.data);
  }

  matchPurchaseWithFeatures(controlId: number, features: Partial<Device>): Promise<Control> {
    if (!controlId) throw new Error('Missing control id in matchPurchaseWithFeatures');

    return axios.post<Control>(`/controls/${controlId}/match-purchase`, { features }).then(resp => resp?.data);
  }
}

export const controlsService = new ControlsService();
