import qz from 'qz-tray';

import { PrinterNotFoundError } from './printer.types';
import axios from '@/core/axios';
import { AppExternalError } from '@/core/errors/app-external-error.class';

class PrinterService {
  constructor() {
    qz.security.setCertificatePromise((resolve: any, reject: any) => this.getCertificate().then(resolve).catch(reject));
    qz.security.setSignatureAlgorithm('SHA512'); // Since 2.1
    qz.security.setSignaturePromise((certificate: string) => {
      return (resolve: any, reject: any) => this.signCertificate(certificate).then(resolve).catch(reject);
    });
  }

  printWith(data: any, printerName: string): Promise<any> {
    return this.assertConnection()
      .then(() => (printerName ? qz.printers.find(printerName) : qz.printers.getDefault()))
      .then(printer => {
        const config = qz.configs.create(
          printer,
          printerName === 'SHIPPING' ? { size: { width: 15, height: 11 }, unit: 'cm' } : {}
        );
        return qz.print(config, data);
      })
      .catch(error => {
        console.error(error);
        if (error?.message === 'Specified printer could not be found.') throw new PrinterNotFoundError(printerName);
        else throw new AppExternalError();
      });
  }

  getCertificate(): Promise<string> {
    return axios.post<{ certificate: string }>('/printer/certificate').then(resp => resp?.data?.certificate);
  }

  signCertificate(certificate: string): Promise<string> {
    return axios.post<{ encoded: string }>('/printer/sign', { certificate }).then(resp => resp?.data?.encoded);
  }

  private assertConnection(): Promise<void> {
    return qz.websocket.isActive() ? Promise.resolve() : qz.websocket.connect();
  }
}

export const printerService = new PrinterService();
