import { Module } from 'vuex';

import { marketplaceService } from '@/services/marketplaces/marketplaces.service';
import { Marketplace } from '@/services/marketplaces/marketplaces.types';
import { RootState } from '@/store';

export interface MarketplaceState {
  marketplaces: Marketplace[];
  selectedMp: string[];
  marketplaceLoading: boolean;
}

const state: MarketplaceState = {
  marketplaces: [],
  selectedMp: [],
  marketplaceLoading: false
};

const options: Module<MarketplaceState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    indexMarketplace: ({ commit, dispatch }): Promise<void> => {
      commit('marketplaceLoading', true);

      return marketplaceService
        .index()
        .then(marketplaces => marketplaces && commit('marketplaces', marketplaces))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('marketplaceLoading', false));
    },
    select: ({ commit }, name: string): void => {
      commit('addMp', name);
    },
    undo: ({ commit }, index: number): void => {
      commit('supMp', index);
    },
    setSelectedMp: ({ commit }, set: string[]): void => {
      commit('selectedMp', set);
    }
  },
  mutations: {
    marketplaces: (state, marketplaces: []) => (state.marketplaces = marketplaces),
    selectedMp: (state, selected: []) => (state.selectedMp = selected),
    addMp: (state, name: string) => state.selectedMp.push(name),
    supMp: (state, index: number) => state.selectedMp.splice(index, 1),
    marketplaceLoading: (state, loading: boolean) => (state.marketplaceLoading = loading)
  }
};

export default options;
