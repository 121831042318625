import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';

export const reportProblemMixin = defineComponent({
  computed: {
    ...mapState('match', ['match'])
  },
  methods: {
    ...mapActions('modal', ['showModal']),
    ...mapActions('comment', ['indexComments']),
    reportProblem() {
      this.showModal({
        component: 'ReportProblemModal',
        data: { match: this.match },
        onClose: async shouldReload =>
          shouldReload && this.indexComments({ subject: 'match', subjectId: this.match.id })
      });
    }
  }
});
