import axios from '@/core/axios';

import { Cage, CageCarrierLabel, CagesListOptions } from './cages.types';
import { CollectionList } from '../collections/collections.types';

export class CagesService {
  list(opts: CagesListOptions = {}): Promise<CollectionList<'dispatch-cages'>> {
    const { filters, pagination } = opts;
    return axios
      .get<CollectionList<'dispatch-cages'>>('cages', { params: { ...filters, ...pagination } })
      .then(resp => resp?.data);
  }

  newCage(): Promise<Cage> {
    return axios.get<Cage>(`/cages/new`).then(resp => resp?.data);
  }

  create(cage: Cage): Promise<Cage> {
    return axios.post<Cage>(`/cages`, { cage }).then(resp => resp?.data);
  }

  get(id: number): Promise<Cage> {
    if (!id) throw new Error('Missing cage id in get');
    return axios.get<Cage>(`/cages/${id}`).then(resp => resp?.data);
  }

  update(id: number, cage: Partial<Cage>): Promise<Cage> {
    if (!id) throw new Error('Missing cage id in update');
    return axios.put<Cage>(`/cages/${id}`, { cage }).then(resp => resp?.data);
  }

  pickup(id: number, cage: Partial<Cage>): Promise<Cage> {
    if (!id) throw new Error('Missing cage id in pickup');
    return axios.post<Cage>(`/cages/${id}/validate_pickup`, { cage }).then(resp => resp?.data);
  }

  cancelPickup(id: number): Promise<Cage> {
    if (!id) throw new Error('Missing cage id in cancelPickup');
    return axios.post<Cage>(`/cages/${id}/cancel_pickup`).then(resp => resp?.data);
  }

  addCageLabel(id: number, tracking_number: string): Promise<Cage> {
    if (!id || !tracking_number) throw new Error('Missing id or tracking in addCageLabel');
    return axios.post<Cage>(`/cages/${id}/add_label`, { tracking_number }).then(resp => resp?.data);
  }

  removeCageLabel(id: number, list: CageCarrierLabel[]): Promise<Cage> {
    if (!id || !list) throw new Error('Missing id or list in removeCageLabel');
    return axios.post<Cage>(`/cages/${id}/remove_label`, { list }).then(resp => resp?.data);
  }
}

export const cagesService = new CagesService();
