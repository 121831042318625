import axios from '@/core/axios';

import { AppSectionCode } from '../app-sections/app-sections.types';
import { CollectionList } from '../collections/collections.types';
import { Match, MatchesListOptions } from './matches.types';

export class MatchesService {
  list(opts: MatchesListOptions = {}): Promise<CollectionList<'matches'>> {
    const { process, pagination } = opts;
    return axios
      .post<CollectionList<'matches'>>(`/matches/pending/${process}`, { pagination })
      .then(resp => resp?.data);
  }

  get(matchId: number): Promise<Match> {
    return axios.get<Match>(`/matches/${matchId}`).then(resp => resp?.data);
  }

  update(matchId: number, match: Partial<Match>): Promise<Match> {
    if (!matchId) throw new Error('Missing match id in update');
    return axios.put<Match>(`matches/${matchId}`, { match }).then(resp => resp?.data);
  }

  delete(matchId: number): Promise<void> {
    return axios.delete<void>(`/matches/${matchId}`).then(resp => resp?.data);
  }

  findBySerialNo(serialNo: string, params: { stocks?: AppSectionCode[] } = {}): Promise<Match> {
    return axios.get<Match>(`/matches/devices/${serialNo}`, { params }).then(resp => resp?.data);
  }

  getByPicking(pickingId: number): Promise<CollectionList<'matches'>> {
    return axios.get<CollectionList<'matches'>>(`/matches/picking/${pickingId}`).then(resp => resp?.data);
  }

  /**
   * Matches an order and a device
   *
   */
  match(match: Match): Promise<void> {
    const { device, order_line, production_order } = match;
    return axios.post<void>('/matches', { device, order_line, production_order }).then(resp => resp?.data);
  }

  moveToStep(matchId: number, step: string): Promise<Match> {
    return axios.put<Match>(`/matches/${matchId}/force-step`, { step_code: step }).then(resp => resp?.data);
  }
}

export const matchesService = new MatchesService();
