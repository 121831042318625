import { Module } from 'vuex';

import { RootState } from '@/store';
import { formsService } from '@/services/forms/forms.service';
import { PurchaseQuery } from '@/services/purchase-queries/purchase-queries.types';
import { purchaseQueriesService } from '@/services/purchase-queries/purchase-queries.service';

export interface PurchaseQueryState {
  query: PurchaseQuery;
  queryLoading: boolean;
}

const state: PurchaseQueryState = {
  query: null,
  queryLoading: false
};

const options: Module<PurchaseQueryState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setQuery: ({ commit, dispatch }, query: PurchaseQuery | string | number): Promise<void> => {
      if (typeof query === 'number' || typeof query === 'string') return dispatch('getQuery', query);
      else commit('query', query);
    },
    getQuery: ({ commit, dispatch }, id: number | string): Promise<void> => {
      commit('queryLoading', true);
      return purchaseQueriesService
        .get(+id)
        .then(query => query && commit('query', query))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('queryLoading', false));
    },
    newQuery: ({ commit, dispatch }): Promise<void> => {
      commit('query', null);
      commit('queryLoading', true);
      return purchaseQueriesService
        .new()
        .then(query => query && commit('query', query))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('queryLoading', false));
    },
    createQuery: ({ commit, dispatch }, query: PurchaseQuery): Promise<void> => {
      commit('queryLoading', true);
      return purchaseQueriesService
        .create(query)
        .then(query => query && commit('query', query))
        .then(() => dispatch('alert/pushSuccess', 'Demande créée avec succès !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('queryLoading', false));
    },
    updateQuery: ({ commit, dispatch }, query: PurchaseQuery): Promise<void> => {
      commit('queryLoading', true);
      return purchaseQueriesService
        .update(query.id, query)
        .then(query => query && commit('query', query))
        .then(() => dispatch('alert/pushSuccess', 'Demande mise à jour !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('queryLoading', false));
    },
    deleteQuery: ({ commit, dispatch }, id: number): Promise<void> => {
      commit('queryLoading', true);
      return purchaseQueriesService
        .delete(+id)
        .then(() => commit('query', null))
        .then(() => dispatch('alert/pushSuccess', 'Demande supprimée !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('queryLoading', false));
    }
  },
  mutations: {
    query: (state, query) => (state.query = query),
    queryLoading: (state, loading) => (state.queryLoading = loading)
  },
  getters: {
    queryForm: (state, getters, rootState) =>
      formsService.getForm('purchase-query', state.query, rootState['global-settings'].settings)
  }
};

export default options;
