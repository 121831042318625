import { AppExternalError } from '@/core/errors/app-external-error.class';
import { SaveBase64Options } from '../file/file.types';

export type Report = {
  id: number;
  device_id: number;
  user_id: number;
  order_line_id: number;
  report_file_id?: string;
  report_file_path?: string;
  created_at: string;
  updated_at: string;
};

export type ReportFile = SaveBase64Options;

export class InvalidReportError extends AppExternalError {
  constructor(id: number) {
    super(`Le rapport de l'ordinateur ${id} n'a pas le format attendu.`);
  }
}
