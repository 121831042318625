import axios from '@/core/axios';
import { CollectionList } from '../collections/collections.types';
import { FileAttachmentsListOptions, FileStorageAttachments } from './file-storage-attachments.types';

export class FileStorageAttachmentsService {
  list(opts: FileAttachmentsListOptions = {}): Promise<CollectionList<'file-attachments'>> {
    const { filters, pagination } = opts;
    return axios
      .get<CollectionList<'file-attachments'>>('/attachments', { params: { ...filters, ...pagination } })
      .then(resp => {
        return resp?.data;
      });
  }
  get(fileStorageId: number): Promise<FileStorageAttachments> {
    return axios.get<FileStorageAttachments>(`/attachments/${fileStorageId}`).then(resp => resp?.data);
  }
  getAttachmentsByPurchaseId(fileStorageId: number): Promise<FileStorageAttachments> {
    return axios.get<FileStorageAttachments>(`/attachments/by_record_id/${fileStorageId}`).then(resp => resp?.data);
  }
  update(
    fileStorageId: number | string,
    fileStorage: Partial<FileStorageAttachments>
  ): Promise<FileStorageAttachments> {
    // deviceId should be serialNo in fact
    if (!fileStorageId) throw new Error('Missing device id in update');
    return axios.put<FileStorageAttachments>(`/attachments/${fileStorageId}`, { fileStorage }).then(resp => resp?.data);
  }
}

export const fileStorageAttachmentsService = new FileStorageAttachmentsService();
