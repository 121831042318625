class FileService {
  saveBase64As(base64: string, filename: string, type: string): Promise<void> {
    const blob = this.b64toBlob(base64, type);

    return this.saveBlobAs(blob, filename);
  }

  saveBlobAs(blob: any, namestring: string): Promise<void> {
    const filename = namestring.split('/').pop();
    const navigator = window.navigator as any;
    return new Promise(res => {
      if (navigator.msSaveOrOpenBlob)
        // IE10+
        navigator.msSaveOrOpenBlob(blob, filename);
      else {
        // Others
        const a = document.createElement('a'),
          url = URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      }
      res();
    });
  }

  b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}

export const fileService = new FileService();
