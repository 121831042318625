import { get } from 'lodash';

import axios from '@/core/axios';
import { CollectionName } from '../collections/collections.types';
import { FilterOptions } from './options.types';

class OptionsService {
  list<T extends CollectionName>(collection: T, filters = {}): Promise<FilterOptions<T>> {
    return axios
      .get<FilterOptions<T>>(`options`, { params: { collection, ...filters } })
      .then(resp => get(resp, 'data.options', null));
  }

  parseOptionValue(value: string): boolean | number | string {
    if (value === 'true') return true;
    else if (value === 'false') return false;
    else return isNaN(+value) ? value : +value;
  }
}

export const optionsService = new OptionsService();
