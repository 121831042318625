import axios from '@/core/axios';
import { Observable, of, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
  DataExport,
  DataExportFile,
  DataExportOptions,
  DataExportsList,
  DataExportsListOptions,
  DataExportStatus,
  InvalidDataExportFileError
} from './data-exports.types';

export class DataExportsService {
  get(id: string): Promise<DataExport> {
    return axios.get<DataExport>(`exports/${id}`).then(resp => resp?.data);
  }

  poll(dataExport: DataExport): Observable<DataExport> {
    if (dataExport?.status !== DataExportStatus.PENDING) return of(dataExport);
    else return interval(1000).pipe(switchMap(() => this.get(dataExport.id)));
  }

  list(opts: DataExportsListOptions = {}): Promise<DataExportsList> {
    const { filters, pagination } = opts;

    return axios.get<DataExportsList>(`exports`, { params: { ...filters, ...pagination } }).then(resp => resp?.data);
  }

  export(opts: DataExportOptions): Promise<DataExport> {
    return axios.post<DataExport>(`exports`, { data_export: opts }).then(resp => resp?.data);
  }

  getFile(fileId: string): Promise<DataExportFile> {
    return axios.get<DataExportFile>(`/file-storage/${encodeURIComponent(fileId)}`).then(resp => {
      const { base_64, filename, type } = resp?.data || {};
      if (!base_64 || !filename || !type) throw new InvalidDataExportFileError();
      return { base_64, filename, type };
    });
  }
}

export const dataExportsService = new DataExportsService();
