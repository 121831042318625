import { Module } from 'vuex';

import { RootState } from '@/store';
import { ProcessStep } from '@/services/process-steps/process-steps.types';
import { processStepsService } from '@/services/process-steps/process-steps.service';
import { AppSectionCode } from '@/services/app-sections/app-sections.types';
import { dicoLabelsService } from '@/services/dico-labels/dico-labels.service';
import { datesService } from '@/services/dates/dates.service';

export interface ProcessStepState {
  steps: ProcessStep[];
  stepsLoading: boolean;
  stepIndex: number;
}

const state: ProcessStepState = {
  steps: [],
  stepsLoading: false,
  stepIndex: null
};

const options: Module<ProcessStepState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    indexSteps: async (
      { commit, dispatch },
      { matchId, stock }: { matchId: number; stock: AppSectionCode }
    ): Promise<void> => {
      commit('steps', []);
      commit('stepsLoading', true);
      commit('stepIndex', null);

      return processStepsService
        .index({ match_id: matchId, stock })
        .then(steps => steps && commit('steps', steps))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('stepsLoading', false));
    },
    setStepIndex: async ({ commit, dispatch, getters }, index: number): Promise<void> => {
      commit('stepIndex', index);
      return getters.step && dispatch('process-task/setTasks', getters.step.process_tasks, { root: true });
    },
    resumeStep: async ({ state, getters, dispatch }): Promise<void> => {
      if (!state.steps) return;
      return dispatch('setStepIndex', getters.currentStepIndex);
    },
    validateStep: async ({ rootState, getters, dispatch }): Promise<void> => {
      const user = rootState?.user?.authenticatedUser;
      if (!getters.step || !user) return;
      return processStepsService
        .validate(getters.step.id)
        .then(() => dispatch('alert/pushSuccess', 'Étape validée avec succès !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }));
    },
    startStep: async ({ rootState, getters, dispatch }): Promise<void> => {
      const user = rootState?.user?.authenticatedUser;
      if (!getters.step || !user) return;
      return processStepsService
        .startStep(getters.step.id)
        .catch(error => dispatch('alert/pushError', error, { root: true }));
    }
  },
  mutations: {
    steps: (state, steps) => (state.steps = steps),
    stepsLoading: (state, isLoading) => (state.stepsLoading = isLoading),
    stepIndex: (state, index) => (state.stepIndex = index)
  },
  getters: {
    step: state => state.steps && state.stepIndex >= 0 && state.steps[state.stepIndex],
    currentStepIndex: state => state.steps?.findIndex(step => !step.done),
    currentStepCode: (state, getters) => state.steps[getters.currentStepIndex]?.code,
    isLastStep: state => state.stepIndex === state.steps?.length - 1,
    stepsBreadcrumbs: state => {
      if (!state.steps) return [];

      return state.steps.map(step => {
        let desc = null;
        let date = null;
        if (step.done) {
          desc = `Validé par ${step.user ? dicoLabelsService.formatName(step.user) : '[Inconnu]'}`;
          date = step.completed_at ? datesService.format(step.completed_at, '[Le] Do MMMM YYYY [à] HH[h]mm') : null;
        }
        return { code: step.code, name: step.title, desc, date };
      });
    }
  }
};

export default options;
