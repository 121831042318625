import axios from '@/core/axios';

import { Changelog } from './changelog.types';

export class ChangelogsService {
  list(): Promise<Changelog> {
    return axios.get<Changelog>(`/changelogs`).then(resp => resp?.data);
  }
  get(changelog_id: number): Promise<Changelog> {
    if (!changelog_id) throw new Error('Missing order id in update');
    return axios.get<Changelog>(`/changelogs/${changelog_id}`).then(resp => resp?.data);
  }
  getLastVersion(): Promise<string> {
    return axios.get<string>(`changelogs/last-version`).then(resp => resp?.data);
  }
}

export const changelogsService = new ChangelogsService();
