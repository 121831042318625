import axios from '@/core/axios';

import { CollectionList } from '../collections/collections.types';
import {
  OrderReturn,
  OrderReturnCarrierLabelData,
  OrderReturnLabelData,
  OrderReturnReceptionOptions,
  OrderReturnsListOptions,
  ReturnType
} from './order-returns.types';
import { SaveBase64Options } from '../file/file.types';

export class OrderReturnsService {
  list(opts: OrderReturnsListOptions = {}): Promise<CollectionList<'order-returns'>> {
    const { filters, pagination } = opts;

    return axios
      .get<CollectionList<'order-returns'>>(`/order-returns`, { params: { ...filters, ...pagination } })
      .then(resp => {
        const data = resp?.data;
        return data;
      });
  }
  showLitigationsTraite(opts: OrderReturnsListOptions = {}): Promise<CollectionList<'order-returns'>> {
    const { filters, pagination } = opts;
    return axios
      .get<CollectionList<'order-returns'>>('/order-returns/show_litigations_true', {
        params: { ...filters, ...pagination }
      })
      .then(resp => {
        const data = resp?.data;
        return data;
      });
  }

  get(orderReturnId: number): Promise<OrderReturn> {
    return axios.get<OrderReturn>(`/order-returns/${orderReturnId}`).then(resp => resp?.data);
  }

  getByDeviceId(deviceId: number): Promise<OrderReturn> {
    if (!deviceId) throw new Error('Missing serial Number in getBySerial');
    return axios.get<OrderReturn>(`/order-returns/${deviceId}/device-id`).then(resp => resp?.data);
  }

  newOrderReturn(): Promise<OrderReturn> {
    return axios.get<OrderReturn>(`order-returns/new`).then(resp => resp?.data);
  }

  create(orderReturn: OrderReturn, orderLineId: number): Promise<OrderReturn> {
    return axios
      .post<OrderReturn>(`order-returns`, { order_return: orderReturn, order_line_id: orderLineId })
      .then(resp => resp?.data);
  }

  update(orderReturnId: number, orderReturn: Partial<OrderReturn>): Promise<OrderReturn> {
    if (!orderReturnId) throw new Error('Missing order return id in update');
    return axios
      .put<OrderReturn>(`order-returns/${orderReturnId}`, { order_return: orderReturn })
      .then(resp => resp?.data);
  }

  updtateExchangedOrderReturn(orderReturnId: number): Promise<OrderReturn> {
    if (!orderReturnId) throw new Error('Missing order return id in updateExchangedOrderReturn');
    return axios.put<OrderReturn>(`order-returns/${orderReturnId}/exchange_status`).then(resp => resp?.data);
  }

  undoExchangedOrderReturn(orderReturnId: number): Promise<OrderReturn> {
    if (!orderReturnId) throw new Error('Missing order return id in undoExchangedOrderReturn');
    return axios.put<OrderReturn>(`order-returns/${orderReturnId}/undo_status`).then(resp => resp?.data);
  }

  delete(orderReturnId: number): Promise<void> {
    if (!orderReturnId) throw new Error('Missing order return id in delete');
    return axios.delete<void>(`/order-returns/${orderReturnId}`).then(resp => resp?.data);
  }

  resetReceptionLabel(orderReturnId: number): Promise<OrderReturn> {
    if (!orderReturnId) throw new Error('Missing order return id in reset_reception_label');

    return axios.get<OrderReturn>(`/order-returns/${orderReturnId}/reset_reception_label`).then(resp => resp?.data);
  }

  resetCarrierLabel(orderReturnId: number): Promise<OrderReturn> {
    if (!orderReturnId) throw new Error('Missing order return id in reset_carrier_label');

    return axios.get<OrderReturn>(`/order-returns/${orderReturnId}/reset_carrier_label`).then(resp => resp?.data);
  }

  validateReception(orderReturnId: number | string, params: OrderReturnReceptionOptions): Promise<OrderReturn> {
    if (!orderReturnId) throw new Error('Missing order return id in validate');
    return axios
      .post<OrderReturn>(`/order-returns/${orderReturnId}/validate-reception`, { params })
      .then(resp => resp?.data);
  }

  handleProcess(orderReturnId: number | string): Promise<OrderReturn> {
    if (!orderReturnId) throw new Error('Missing order return id in handle');
    return axios.post<OrderReturn>(`/order-returns/${orderReturnId}/handle-process`).then(resp => resp?.data);
  }

  validateProcess(orderReturnId: number | string): Promise<OrderReturn> {
    if (!orderReturnId) throw new Error('Missing order return id in handle');
    return axios.post<OrderReturn>(`/order-returns/${orderReturnId}/validate-process`).then(resp => resp?.data);
  }

  validateControl(orderReturnId: number | string): Promise<OrderReturn> {
    if (!orderReturnId) throw new Error('Missing order return id in validate control');
    return axios.post<OrderReturn>(`/order-returns/${orderReturnId}/validate-control`).then(resp => resp?.data);
  }

  validatePayment(orderReturnId: number | string): Promise<OrderReturn> {
    if (!orderReturnId) throw new Error('Missing order return id in validate payment');
    return axios.post<OrderReturn>(`/order-returns/${orderReturnId}/validate-payment`).then(resp => resp?.data);
  }

  declareLitigation(orderReturnId: number | string): Promise<OrderReturn> {
    if (!orderReturnId) throw new Error('Missing order return id in declare litigation');
    return axios.post<OrderReturn>(`/order-returns/${orderReturnId}/declare-litigation`).then(resp => resp?.data);
  }

  undoProcess(orderReturnId: number | string): Promise<OrderReturn> {
    if (!orderReturnId) throw new Error('Missing order return id in undo process');
    return axios.post<OrderReturn>(`/order-returns/${orderReturnId}/undo-process`).then(resp => resp?.data);
  }

  handleLitigation(orderReturnId: number | string): Promise<OrderReturn> {
    if (!orderReturnId) throw new Error('Missing order return id in handle litigation');
    return axios.post<OrderReturn>(`/order-returns/${orderReturnId}/handle-litigation`).then(resp => resp?.data);
  }

  matchDevice(orderReturnId: number | string, deviceId: number | string): Promise<OrderReturn> {
    if (!orderReturnId) throw new Error('Missing order return id in match device');
    return axios
      .post<OrderReturn>(`/order-returns/${orderReturnId}/match-device`, { device_id: deviceId })
      .then(resp => resp?.data);
  }

  cancelLitigation(orderReturnId: number | string): Promise<OrderReturn> {
    if (!orderReturnId) throw new Error('Missing order return id in cancel litigation');
    return axios.post<OrderReturn>(`/order-returns/${orderReturnId}/cancel-litigation`).then(resp => resp?.data);
  }

  switchReturnType(orderReturnId: number | string, newType: ReturnType): Promise<OrderReturn> {
    if (!orderReturnId) throw new Error('Missing order return id in switch type');
    return axios
      .post<OrderReturn>(`/order-returns/${orderReturnId}/switch-type`, { new_type: newType })
      .then(resp => resp?.data);
  }

  setHandledBy(orderReturnId: number | string, userId: number): Promise<OrderReturn> {
    if (!orderReturnId) throw new Error('Missing order return id in set handled by');
    return axios
      .post<OrderReturn>(`/order-returns/${orderReturnId}/handled-by`, { user_id: userId })
      .then(resp => resp?.data);
  }

  getReceptionLabel(orderReturnId: number): Promise<SaveBase64Options> {
    return axios.get<SaveBase64Options>(`/order-returns/${orderReturnId}/reception-label`).then(resp => {
      const { base_64, filename, type } = resp?.data || {};
      if (!base_64 || !filename || !type) throw new Error('Missing order return id in get reception label');
      return { base_64, filename, type };
    });
  }

  getCarrierLabel(orderReturnId: number): Promise<OrderReturnCarrierLabelData> {
    return axios
      .get<OrderReturnCarrierLabelData>(`/order-returns/${orderReturnId}/carrier-label`)
      .then(resp => resp?.data);
  }

  getOrderReturnLabel(orderReturnId: number): Promise<OrderReturnLabelData> {
    return axios.get<OrderReturnLabelData>(`/order-returns/${orderReturnId}/return-label`).then(resp => resp?.data);
  }

  isOrderFromExchange(orderId: number): Promise<boolean | number> {
    return axios.get<boolean>(`/order-returns/${orderId}/from_exchange`).then(resp => resp?.data);
  }
}

export const orderReturnsService = new OrderReturnsService();
