import axios from '@/core/axios';
import { Parts, PartsListOptions } from './parts.types';
import { CollectionList } from '../collections/collections.types';

export class PartsService {
  list(opts: PartsListOptions = {}): Promise<CollectionList<'parts'>> {
    const { filters, pagination } = opts;
    return axios.get<CollectionList<'parts'>>(`/parts`, { params: { ...filters, ...pagination } }).then(resp => {
      return resp?.data;
    });
  }
  getBySerialNumber(serialNumber: string): Promise<Parts> {
    return axios
      .get<Parts>(`/parts/${serialNumber}/scan`)
      .then(resp => {
        return resp.data;
      })
      .catch(error => {
        console.error('Error fetching part by serial number:', error);
        throw error;
      });
  }

  create(piece: Parts): Promise<Parts> {
    return axios
      .post<Parts>('/parts', piece)
      .then(resp => {
        return resp.data;
      })
      .catch(error => {
        console.error('Erreur lors de la création de la pièce:', error);
        throw error;
      });
  }
  get(pieceId: number): Promise<Parts> {
    return axios.get<Parts>(`/parts/${pieceId}`).then(resp => resp?.data);
  }

  getMiniLabel(serialNo: string): Promise<string> {
    return axios.get(`/parts/${serialNo}/mini-label`).then(response => response.data.zpl);
  }

  update(pieceId: number | string, piece: Partial<Parts>): Promise<Parts> {
    if (!pieceId) throw new Error('Missing piece id in update');
    return axios.put<Parts>(`parts/${pieceId}`, { piece }).then(resp => resp?.data);
  }
  destructed(pieceId: number): Promise<Parts> {
    if (!pieceId) throw new Error('Missing parts id in validate');
    return axios
      .put<Parts>(`parts/${pieceId}/destructed`)
      .then(resp => {
        return resp.data;
      })
      .catch(error => {
        console.error('Failed to update part:', error);
        throw error;
      });
  }

  dismantle(pieceId: number): Promise<Parts> {
    if (!pieceId) throw new Error('Missing part ID for dismantle');
    return axios
      .post<Parts>(`/parts/${pieceId}/dismantle`)
      .then(resp => resp.data)
      .catch(error => {
        console.error('Failed to dismantle part:', error);
        throw error;
      });
  }
  assembleParts(dalleSerialNumber: string, coqueSerialNumber: string): Promise<Parts> {
    return axios
      .post<Parts>(`/parts/assemble`, {
        dalle_serial_number: dalleSerialNumber,
        coque_serial_number: coqueSerialNumber
      })
      .then(resp => {
        return resp.data;
      })
      .catch(error => {
        console.error('Failed to assemble parts:', error);
        throw error;
      });
  }
}
export const partsService = new PartsService();
