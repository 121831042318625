import { every } from 'lodash';
import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import TaskCard from '@/components/Task/TaskCard/TaskCard.vue';
import { Match } from '@/services/matches/matches.types';
import { ProcessTask } from '@/services/process-tasks/process-tasks.types';
import { FailureItem } from '@/services/failures/failures.types';

export const tasksListMixin = (stock: string) =>
  defineComponent({
    components: { TaskCard },
    computed: {
      ...mapState('match', ['match']),
      ...mapState('deviceFailure', ['deviceFailures']),
      ...mapState('process-step', ['steps', 'stepIndex']),
      ...mapState('process-task', ['tasks']),
      ...mapState('failures', ['failureList']),
      ...mapGetters('process-step', ['stepsBreadcrumbs', 'step', 'currentStepCode', 'currentStepIndex', 'isLastStep']),
      allDone() {
        return this.tasks && every(this.tasks, (task: ProcessTask) => task.done);
      }
    },
    data() {
      return {
        appSectionBodyViewed: false
      };
    },
    methods: {
      ...mapActions('app-section', ['setMainSubSections', 'activateSection']),
      ...mapActions('match', ['reloadMatch']),
      ...mapActions('deviceFailure', ['createDeviceFailure']),
      ...mapActions('process-step', ['indexSteps', 'setStepIndex', 'resumeStep', 'validateStep', 'startStep']),
      ...mapActions('process-task', [
        'createTask',
        'editTaskInList',
        'saveTaskInList',
        'validateTaskInList',
        'reloadTasks',
        'cancelTaskInList',
        'validateAllTasks'
      ]),
      addDeviceFailure() {
        const idList: number[] = [];

        for (let index = 0; index < this.failureList.length; index++) {
          if (this.failureList[index].selected) idList.push(this.deviceFailures[index].id);
        }
        this.failureList.slice(this.deviceFailures.length).forEach((item: FailureItem) => {
          this.createDeviceFailure({
            serial_no: this.match.device.serial_no,
            origin: 'DEVICE',
            order_return_id: null,
            failure_id: item.id
          });
          this.createTask({ process_step_id: this.step.id, code: item.id }).then(() => this.reloadTasks(this.step.id));
        });
      },
      validateTask(options: { index: number; task: ProcessTask }) {
        return this.saveTaskInList(options)
          .then(() => this.validateTaskInList(options))
          .then(() => this.reloadMatch());
      },
      handleValidateAllTasks() {
        return this.validateAllTasks()
          .then(() => this.reloadTasks(this.step.id))
          .then(() => this.reloadMatch());
      },
      handleScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
        if (scrollTop + clientHeight >= scrollHeight - 30) {
          this.appSectionBodyViewed = true;
        }
      }
    },
    updated() {
      if (
        this.$refs.appSectionBody &&
        this.$refs.appSectionBody.$el.clientHeight >= this.$refs.appSectionBody.$el.scrollHeight
      )
        this.appSectionBodyViewed = true;
    },
    watch: {
      match: {
        immediate: true,
        handler(newValue: Match, oldValue: Match) {
          if (newValue && (!oldValue || newValue.id !== oldValue.id))
            this.indexSteps({ matchId: newValue.id, stock })
              .then(() => this.setMainSubSections(this.stepsBreadcrumbs))
              .then(() => this.resumeStep())
              .then(() => this.activateSection(this.currentStepCode))
              .then(() => this.startStep());
        }
      }
    }
  });
