import { Module } from 'vuex';

import { RootState } from '@/store';
import { SaveBase64Options } from '@/services/file/file.types';
import { fileService } from '@/services/file/file.service';

export type FileState = Record<string, never>;

const state: FileState = {};

const options: Module<FileState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    saveBase64As({ dispatch }, { base_64, filename, type }: SaveBase64Options): Promise<void> {
      return fileService
        .saveBase64As(base_64, filename, type)
        .catch(error => dispatch('alert/pushError', error, { root: true }));
    }
  },
  mutations: {}
};

export default options;
