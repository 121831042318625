import jwtDecode from 'jwt-decode';
import { get } from 'lodash';

import { Credentials, SignInResponse } from './auth.types';
import axios from '@/core/axios';
import { AppExternalError } from '@/core/errors/app-external-error.class';

class AuthService {
  constructor(private sessionKey = 'auth-token') {}

  getSession(): string {
    const token = sessionStorage.getItem(this.sessionKey);
    if (!token) return;
    const { exp } = jwtDecode(token);
    if (!exp || exp > Date.now()) return;
    return token;
  }

  signIn(user: Credentials): Promise<string> {
    return axios
      .post<SignInResponse>('/login', { user })
      .then(resp => {
        const token = get(resp, 'headers.authorization', null);
        if (!token) throw new Error('Empty Auth Header');
        this.setSession(token);
        return token;
      })
      .catch(error => {
        if (get(error, 'response.status') === 401) throw new Error('Email ou mot de passe incorrect');
        if (get(error, 'status') === 403) throw new Error('Accès non autorisé sur cette adresse IP');
        else throw new AppExternalError();
      });
  }

  signOut(): void {
    this.clearSession();
  }

  private clearSession(): void {
    sessionStorage.removeItem(this.sessionKey);
  }

  private setSession(token: string) {
    sessionStorage.setItem(this.sessionKey, token);
  }
}

export const authService = new AuthService();
