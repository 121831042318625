import { AppExternalError } from '@/core/errors/app-external-error.class';
import { Purchase } from '@/services/purchases/purchases.types';

export interface PurchaseReceptionModalData {
  purchase: Purchase;
}

export interface ReceptionOptions {
  status: 'complete' | 'partial';
  quantityReceived: number;
}

export class PurchaseReceptionEmptyError extends AppExternalError {
  constructor() {
    super('Vous devez entrer au moins une valeur non nulle.');
  }
}
