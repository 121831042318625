import { Module } from 'vuex';

import { Changelog } from '@/services/changelogs/changelog.types';
import { RootState } from '@/store';
import { changelogsService } from '@/services/changelogs/changelog.service';

export interface ChangelogState {
  lastVersion: string;
  changelog: Changelog;
  changelogList: Changelog[];
  changelogLoading: boolean;
}

const state: ChangelogState = {
  lastVersion: null,
  changelog: null,
  changelogList: null,
  changelogLoading: false
};

const options: Module<ChangelogState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setChangelog: ({ commit, dispatch }, changelog: Changelog | number): Promise<void> => {
      if (typeof changelog === 'number') return dispatch('getChangelog', changelog);
      else commit('changelog', changelog);
    },
    setList: ({ commit, dispatch }): Promise<void> => {
      commit('changelogLoading', true);
      return changelogsService
        .list()
        .then(changelogList => changelogList && commit('changelogList', changelogList))
        .then(() => dispatch('setChangelog', state.changelogList[0]))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('changelogLoading', false));
    },
    getLastVersion: ({ commit, dispatch }): Promise<void> => {
      return changelogsService
        .getLastVersion()
        .then(lastVersion => lastVersion && commit('lastVersion', lastVersion))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }));
    },
    getChangelog: ({ commit, dispatch }, changelogId: number): Promise<void> => {
      commit('changelog', null);
      commit('changelogLoading', true);

      return changelogsService
        .get(changelogId)
        .then(changelog => changelog && commit('changelog', changelog))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('changelogLoading', false));
    },
    clearChangelog: ({ dispatch }): Promise<void> => {
      return dispatch('setChangelog', null);
    }
  },
  mutations: {
    lastVersion: (state, version) => (state.lastVersion = version),
    changelog: (state, changelog) => (state.changelog = changelog),
    changelogList: (state, list) => (state.changelogList = list),
    changelogLoading: (state, loading) => (state.changelogLoading = loading)
  }
};

export default options;
