import axios from '@/core/axios';

import { StockStatus, StockStatusesList, StocksListOptions, Stock } from './stocks-status.types';

export class StocksStatusService {
  list(opts: StocksListOptions = {}): Promise<StockStatusesList> {
    const { filters, pagination } = opts;
    return axios.post<StockStatusesList>(`/stocks`, { filters, pagination }).then(resp => resp?.data);
  }

  get(stockStatusId: number): Promise<StockStatus> {
    return axios.get<StockStatus>(`/stocks/${stockStatusId}`).then(resp => resp?.data);
  }
  getStockStatusById(StatusId: number): Promise<StockStatus> {
    return axios
      .get<StockStatus>(`/stocks/${StatusId}`)
      .then(resp => {
        return resp.data;
      })
      .catch(error => {
        console.error('There was an error fetching the status!', error);
        throw error;
      });
  }
  getStatusesByIds(StatusIds: number[]): Promise<StockStatus[]> {
    return Promise.all(StatusIds.map(id => this.getStockStatusById(id)))
      .then(statuses => {
        return statuses;
      })
      .catch(error => {
        console.error('There was an error fetching the statuses!', error);
        throw error;
      });
  }

  getWithStockAndStatus(stockId: number, statusId: number, stocksStatus): StockStatus {
    stocksStatus.find(stock_status => {
      stock_status[1].stock_id == stockId && stock_status[1].status_id == statusId;
    });
    return stocksStatus.find(
      stock_status => stock_status[1].stock_id == stockId && stock_status[1].status_id == statusId
    )[1];
  }

  display_name(stockStatus: StockStatus) {
    if (!stockStatus) return;
    let displayName = null;
    if (stockStatus.status_id) {
      displayName = stockStatus.stock.display_name + ' / ' + stockStatus.status.display_name;
    } else {
      displayName = stockStatus.stock.display_name;
    }
    return displayName;
  }

  list_stock(stocksStatus): [number, Stock][] {
    let listStockId = stocksStatus.map(stock_status => stock_status[1].stock_id);
    listStockId = listStockId.filter((item, pos) => listStockId.indexOf(item) == pos);

    const listStock = listStockId.map(stock_id => [
      stock_id,
      stocksStatus.find(stock_status => stock_status[1].stock_id == stock_id)[1].stock
    ]);

    return listStock.filter(status => status[1] != null);
  }
}

export const stocksStatusService = new StocksStatusService();
