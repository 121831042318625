import axios from '@/core/axios';

import { Picking, PickingsListOptions } from './pickings.types';
import { CollectionList } from '../collections/collections.types';

export class PickingsService {
  list(opts: PickingsListOptions = {}): Promise<CollectionList<'pickings'>> {
    const { filters, pagination } = opts;

    return axios
      .get<CollectionList<'pickings'>>(`/pickings`, { params: { ...filters, ...pagination } })
      .then(resp => resp?.data);
  }

  get(pickingId: number): Promise<Picking> {
    return axios.get<Picking>(`/pickings/${pickingId}`).then(resp => resp?.data);
  }

  newPicking(): Promise<Picking> {
    return axios.get<Picking>(`pickings/new`).then(resp => resp?.data);
  }

  create(pickingId: Picking): Promise<Picking> {
    return axios.post<Picking>(`pickings`, { picking: pickingId }).then(resp => resp?.data);
  }

  update(pickingId: number | string, picking: Partial<Picking>): Promise<Picking> {
    if (!pickingId) throw new Error('Missing picking id in update');
    return axios.put<Picking>(`pickings/${pickingId}`, { picking: picking }).then(resp => resp?.data);
  }

  cancel(pickingId: number): Promise<Picking> {
    if (!pickingId) throw new Error('Missing picking id in cancel');
    return axios.delete<Picking>(`/pickings/${pickingId}`).then(resp => resp?.data);
  }

  start(pickingId: number): Promise<Picking> {
    if (!pickingId) throw new Error('Missing picking id in start');
    return axios.post<Picking>(`pickings/${pickingId}/start`).then(resp => resp?.data);
  }

  validate(pickingId: number): Promise<Picking> {
    if (!pickingId) throw new Error('Missing picking id in validate');
    return axios.post<Picking>(`pickings/${pickingId}/validate`).then(resp => resp?.data);
  }
}

export const pickingsService = new PickingsService();
