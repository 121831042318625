import { Module } from 'vuex';
import { RootState } from '@/store';
import { ItemAssociates, PurchaseItems } from '@/services/purchase-items/purchase-items.types';
import { purchaseitemsService } from '@/services/purchase-items/purchase-items.service';

export interface PurchaseItemsState {
  purchaseItems: PurchaseItems | null;
  purchaseItemsLoading: boolean;
}

const state: PurchaseItemsState = {
  purchaseItems: null,
  purchaseItemsLoading: false
};

const purchaseItemsModule: Module<PurchaseItemsState, RootState> = {
  namespaced: true,
  state,
  actions: {
    setPurchaseItems({ commit, dispatch }, purchaseItems: PurchaseItems | string | number): Promise<void> {
      if (typeof purchaseItems === 'number' || typeof purchaseItems === 'string') {
        return dispatch('getPurchaseItems', purchaseItems);
      } else {
        commit('setPurchaseItems', purchaseItems);
        return Promise.resolve();
      }
    },
    getPurchaseItems({ commit, dispatch }, id: number | string): Promise<void> {
      commit('purchaseItemsLoading', true);
      return purchaseitemsService
        .get(+id)
        .then((purchaseItems: PurchaseItems | null) => {
          if (purchaseItems) {
            commit('setPurchaseItems', purchaseItems);
          }
        })
        .catch(error => {
          dispatch('alert/pushError', error, { root: true });
        })
        .finally(() => {
          commit('purchaseItemsLoading', false);
        });
    },
    associateItem({ commit, dispatch }, Itemassociate: ItemAssociates) {
      commit('purchaseItemsLoading', true);
      return purchaseitemsService
        .associateItem(Itemassociate)
        .catch(error => {
          dispatch('alert/pushError', error, { root: true });
        })
        .finally(() => {
          commit('purchaseItemsLoading', false);
        });
    },
    completeAssociate({ commit, dispatch }, devicesSelect) {
      commit('purchaseItemsLoading', true);
      return purchaseitemsService
        .completeAssociate(devicesSelect)
        .catch(error => {
          dispatch('alert/pushError', error, { root: true });
        })
        .finally(() => {
          commit('purchaseItemsLoading', false);
        });
    }
  },
  mutations: {
    setPurchaseItems(state, purchaseItems: PurchaseItems | null) {
      state.purchaseItems = purchaseItems;
    },
    purchaseItemsLoading(state, loading: boolean) {
      state.purchaseItemsLoading = loading;
    }
  },
  getters: {}
};

export default purchaseItemsModule;
