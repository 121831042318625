import { Module } from 'vuex';
import { devicesService } from '@/services/devices/devices.service';
import { Device } from '@/services/devices/devices.types';
import { dicoLabelsService } from '@/services/dico-labels/dico-labels.service';
import { formsService } from '@/services/forms/forms.service';
import { orderReturnsService } from '@/services/order-returns/order-returns.service';
import { OrderReturn, OrderReturnReceptionOptions, ReturnType } from '@/services/order-returns/order-returns.types';
import { RootState } from '@/store';

export interface OrderReturnState {
  orderReturn: OrderReturn;
  orderReturnLoading: boolean;
  orderReturnLabelLoading: boolean;
  carrierLabelLoading: boolean;
}

const state: OrderReturnState = {
  orderReturn: null,
  orderReturnLoading: false,
  carrierLabelLoading: false,
  orderReturnLabelLoading: false
};

const options: Module<OrderReturnState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setOrderReturn: ({ commit, dispatch }, orderReturn: OrderReturn | string | number): Promise<void> => {
      if (typeof orderReturn === 'number' || typeof orderReturn === 'string')
        return dispatch('getOrderReturn', orderReturn);
      else commit('orderReturn', orderReturn);
    },
    newOrderReturn: ({ commit, dispatch }): Promise<void> => {
      commit('orderReturn', null);
      commit('orderReturnLoading', true);
      return orderReturnsService
        .newOrderReturn()
        .then(orderReturn => orderReturn && commit('orderReturn', orderReturn))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderReturnLoading', false));
    },
    createOrderReturn: (
      { commit, dispatch },
      { orderReturn, orderLineId }: { orderReturn: OrderReturn; orderLineId: number }
    ): Promise<void> => {
      commit('orderReturnLoading', true);
      return orderReturnsService
        .create(orderReturn, orderLineId)
        .then(orderReturn => orderReturn && commit('orderReturn', orderReturn))
        .then(() => dispatch('alert/pushSuccess', 'Retour créé avec succès !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderReturnLoading', false));
    },
    updateOrderReturn: ({ commit, dispatch }, orderReturn: OrderReturn): Promise<void> => {
      commit('orderReturnLoading', true);
      return orderReturnsService
        .update(orderReturn.id, orderReturn)
        .then(orderReturn => orderReturn && commit('orderReturn', orderReturn))
        .then(() => dispatch('alert/pushSuccess', 'Retour mis à jour !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderReturnLoading', false));
    },
    deleteOrderReturn: ({ commit, dispatch }, orderReturnId: number): Promise<void> => {
      commit('orderReturnLoading', true);
      return orderReturnsService
        .delete(orderReturnId)
        .then(() => commit('orderReturn', null))
        .then(() => dispatch('alert/pushSuccess', 'Retour supprimé !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderReturnLoading', false));
    },
    resetReceptionLabel: ({ commit, dispatch }, orderReturnId: number): Promise<void> => {
      commit('orderReturnLoading', true);
      return orderReturnsService
        .resetReceptionLabel(orderReturnId)
        .then(orderReturn => orderReturn && commit('orderReturn', orderReturn))
        .then(() => dispatch('alert/pushSuccess', 'Reset du bon de réception effectué', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderReturnLoading', false));
    },
    resetCarrierLabel: ({ commit, dispatch }, orderReturnId: number): Promise<void> => {
      commit('orderReturnLoading', true);
      return orderReturnsService
        .resetCarrierLabel(orderReturnId)
        .then(orderReturn => orderReturn && commit('orderReturn', orderReturn))
        .then(() => dispatch('alert/pushSuccess', 'Reset du BL effectué', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderReturnLoading', false));
    },
    getOrderReturn: ({ commit, dispatch }, orderReturnId: number): Promise<void> => {
      commit('orderReturnLoading', true);
      return orderReturnsService
        .get(orderReturnId)
        .then(orderReturn => orderReturn && commit('orderReturn', orderReturn))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderReturnLoading', false));
    },
    getOrderReturnByDeviceId: ({ commit, dispatch }, deviceId: number): Promise<void> => {
      commit('orderReturnLoading', true);
      return orderReturnsService
        .getByDeviceId(deviceId)
        .then(orderReturn => orderReturn && commit('orderReturn', orderReturn))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderReturnLoading', false));
    },

    fetchLitigationsTraite: ({ commit, dispatch }) => {
      commit('orderReturnLoading', true);
      return orderReturnsService
        .showLitigationsTraite()
        .then(orderReturn => orderReturn && commit('orderReturn', orderReturn))
        .catch(error => {
          dispatch('alert/pushError', error, { root: true });
        })
        .finally(() => {
          commit('orderReturnLoading', false);
        });
    },

    validateReception: (
      { commit, dispatch },
      { orderReturnId, options }: { orderReturnId: string; options: OrderReturnReceptionOptions }
    ): Promise<void> => {
      commit('orderReturnLoading', true);
      return orderReturnsService
        .validateReception(orderReturnId, options)
        .then(orderReturn => orderReturn && commit('orderReturn', orderReturn))
        .then(() => dispatch('alert/pushSuccess', 'Réception du retour validée !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderReturnLoading', false));
    },
    handleProcess: ({ commit, dispatch }, orderReturnId: number): Promise<void> => {
      commit('orderReturnLoading', true);
      return orderReturnsService
        .handleProcess(orderReturnId)
        .then(orderReturn => orderReturn && commit('orderReturn', orderReturn))
        .then(() => dispatch('table/listRows', null, { root: true }))
        .then(() => dispatch('alert/pushSuccess', 'Retour mis à jour avec succès!', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderReturnLoading', false));
    },
    validateControl: ({ commit, dispatch }, orderReturnId: number): Promise<void> => {
      commit('orderReturnLoading', true);
      return orderReturnsService
        .validateControl(orderReturnId)
        .then(orderReturn => orderReturn && commit('orderReturn', orderReturn))
        .then(() => dispatch('table/listRows', null, { root: true }))
        .then(() => dispatch('alert/pushSuccess', 'Controle validé !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderReturnLoading', false));
    },
    validatePayment: ({ commit, dispatch }, orderReturnId: number): Promise<void> => {
      commit('orderReturnLoading', true);
      return orderReturnsService
        .validatePayment(orderReturnId)
        .then(orderReturn => orderReturn && commit('orderReturn', orderReturn))
        .then(() => dispatch('table/listRows', null, { root: true }))
        .then(() => dispatch('alert/pushSuccess', 'Retour mis à jour avec succès!', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderReturnLoading', false));
    },
    validateProcess: ({ commit, dispatch }, orderReturnId: number): Promise<void> => {
      commit('orderReturnLoading', true);
      return orderReturnsService
        .validateProcess(orderReturnId)
        .then(orderReturn => orderReturn && commit('orderReturn', orderReturn))
        .then(() => dispatch('table/listRows', null, { root: true }))
        .then(() => dispatch('alert/pushSuccess', 'Retour mis à jour avec succès!', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderReturnLoading', false));
    },
    matchDevice: (
      { commit, dispatch },
      { orderReturnId, deviceId }: { orderReturnId: number; deviceId: string | number }
    ): Promise<void> => {
      commit('orderReturnLoading', true);
      return orderReturnsService
        .matchDevice(orderReturnId, deviceId)
        .then(orderReturn => orderReturn && commit('orderReturn', orderReturn))
        .then(() => dispatch('table/listRows', null, { root: true }))
        .then(() => dispatch('alert/pushSuccess', 'Retour mis à jour avec succès!', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderReturnLoading', false));
    },
    declareLitigation: ({ commit, dispatch }, orderReturnId: number): Promise<void> => {
      commit('orderReturnLoading', true);
      return orderReturnsService
        .declareLitigation(orderReturnId)
        .then(orderReturn => orderReturn && commit('orderReturn', orderReturn))
        .then(() => dispatch('table/listRows', null, { root: true }))
        .then(() => dispatch('alert/pushSuccess', 'Retour mis à jour avec succès!', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderReturnLoading', false));
    },
    undoProcess: ({ commit, dispatch }, orderReturnId: number): Promise<void> => {
      commit('orderReturnLoading', true);
      return orderReturnsService
        .undoProcess(orderReturnId)
        .then(orderReturn => orderReturn && commit('orderReturn', orderReturn))
        .then(() => dispatch('table/listRows', null, { root: true }))
        .then(() => dispatch('alert/pushSuccess', 'Retour mis à jour avec succès!', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderReturnLoading', false));
    },
    handleLitigation: ({ commit, dispatch }, orderReturnId: number): Promise<void> => {
      commit('orderReturnLoading', true);
      return orderReturnsService
        .handleLitigation(orderReturnId)
        .then(orderReturn => orderReturn && commit('orderReturn', orderReturn))
        .then(() => dispatch('table/listRows', null, { root: true }))
        .then(() => dispatch('alert/pushSuccess', 'Retour mis à jour avec succès!', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderReturnLoading', false));
    },
    cancelLitigation: ({ commit, dispatch }, orderReturnId: number): Promise<void> => {
      commit('orderReturnLoading', true);
      return orderReturnsService
        .cancelLitigation(orderReturnId)
        .then(orderReturn => orderReturn && commit('orderReturn', orderReturn))
        .then(() => dispatch('table/listRows', null, { root: true }))
        .then(() => dispatch('alert/pushSuccess', 'Retour mis à jour avec succès!', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderReturnLoading', false));
    },
    switchReturnType: (
      { commit, dispatch },
      { orderReturnId, newType }: { orderReturnId: number; newType: ReturnType }
    ): Promise<void> => {
      commit('orderReturnLoading', true);
      return orderReturnsService
        .switchReturnType(orderReturnId, newType)
        .then(orderReturn => orderReturn && commit('orderReturn', orderReturn))
        .then(() => dispatch('table/listRows', null, { root: true }))
        .then(() => dispatch('alert/pushSuccess', 'Retour mis à jour avec succès!', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderReturnLoading', false));
    },
    setHandledBy: (
      { commit, dispatch },
      { orderReturnId, userId }: { orderReturnId: number; userId: number }
    ): Promise<void> => {
      commit('orderReturnLoading', true);
      return orderReturnsService
        .setHandledBy(orderReturnId, userId)
        .then(orderReturn => orderReturn && commit('orderReturn', orderReturn))
        .then(() => dispatch('table/listRows', null, { root: true }))
        .then(() => dispatch('alert/pushSuccess', 'Retour mis à jour avec succès!', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderReturnLoading', false));
    },
    generateReceptionLabel: ({ commit, dispatch }, orderReturnId: number): Promise<void> => {
      commit('carrierLabelLoading', true);
      return orderReturnsService
        .getReceptionLabel(orderReturnId)
        .then(pdf => pdf && dispatch('file/saveBase64As', pdf, { root: true }))
        .then(() => dispatch('setOrderReturn', orderReturnId))
        .then(() => dispatch('alert/pushInfo', 'Génération du bon de réception...', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('carrierLabelLoading', false));
    },
    printCarrierLabel: ({ commit, dispatch }, orderReturnId: number): Promise<void> => {
      commit('carrierLabelLoading', true);
      return orderReturnsService
        .getCarrierLabel(orderReturnId)
        .then(resp =>
          Promise.all([
            resp?.zpl && dispatch('printer/printWith', { data: [resp.zpl], printer: 'SHIPPING' }, { root: true }),
            dispatch('setOrderReturn', orderReturnId),
            dispatch('alert/pushInfo', 'Impression du bon de transport...', { root: true })
          ])
        )
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('carrierLabelLoading', false));
    },
    printOrderReturnLabel: ({ commit, dispatch }, orderReturnId: number): Promise<void> => {
      commit('orderReturnLabelLoading', true);
      return orderReturnsService
        .getOrderReturnLabel(orderReturnId)
        .then(
          resp => resp?.zpl && dispatch('printer/printWith', { data: [resp.zpl], printer: 'SHIPPING' }, { root: true })
        )
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderReturnLabelLoading', false));
    },
    updateExchangedOrderReturn: ({ commit, dispatch }, orderReturnId: number): Promise<void> => {
      commit('orderReturnLoading', true);
      return orderReturnsService
        .updtateExchangedOrderReturn(orderReturnId)
        .then(orderReturn => orderReturn && commit('orderReturn', orderReturn))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderReturnLoading', false));
    },
    undoExchangedOrderReturn: ({ commit, dispatch }, orderReturnId: number): Promise<void> => {
      commit('orderReturnLoading', true);
      return orderReturnsService
        .undoExchangedOrderReturn(orderReturnId)
        .then(orderReturn => orderReturn && commit('orderReturn', orderReturn))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderReturnLoading', false));
    },
    updateExchangedDevice: ({ commit, state, dispatch }, data: Device): Promise<void> => {
      commit('orderReturnLoading', true);
      return devicesService
        .update(state.orderReturn.device.serial_no, data)
        .then(device => device && commit('orderReturnDevice', device))
        .then(() => dispatch('alert/pushSuccess', 'Ordinateur mis à jour !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderReturnLoading', false));
    },
    relocateExchangedDevice: (
      { commit, state, dispatch },
      { stockId, reason }: { stockId: number; reason: string }
    ): Promise<void> => {
      commit('orderReturnLoading', true);
      return devicesService
        .move(state.orderReturn.device.serial_no, stockId, reason)
        .then(device => device && commit('orderReturnDevice', device))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderReturnLoading', false));
    },
    clearOrderReturn: ({ commit }): void => {
      commit('orderReturn', null);
    }
  },
  mutations: {
    orderReturn: (state, orderReturn) => (state.orderReturn = orderReturn),
    orderReturnDevice: (state, device) => (state.orderReturn.device = device),
    orderReturnLoading: (state, loading) => (state.orderReturnLoading = loading),
    carrierLabelLoading: (state, loading) => (state.carrierLabelLoading = loading),
    orderReturnLabelLoading: (state, loading) => (state.orderReturnLabelLoading = loading)
  },
  getters: {
    orderReturnCreationForm: (state, getters, rootState, rootGetters) =>
      formsService.getForm('order-return-creation', state.orderReturn, rootState['global-settings'].settings, {
        UserCanValidateOrderReturnPayment: rootGetters['user/canValidateOrderReturnPayment']
      }),
    orderReturnForm: (state, getters, rootState, rootGetters) =>
      formsService.getForm('order-return', state.orderReturn, rootState['global-settings'].settings, {
        UserCanValidateOrderReturnPayment: rootGetters['user/canValidateOrderReturnPayment']
      }),
    createdByName: state => dicoLabelsService.formatName(state.orderReturn?.created_by),
    processValidatedByName: state => dicoLabelsService.formatName(state.orderReturn?.process_validated_by)
  }
};

export default options;
