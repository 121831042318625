import { get } from 'lodash';
import { Module } from 'vuex';

import { AppSectionCode } from '@/services/app-sections/app-sections.types';
import { devicesService } from '@/services/devices/devices.service';
import { Device } from '@/services/devices/devices.types';
import { formsService } from '@/services/forms/forms.service';
import { matchesService } from '@/services/matches/matches.service';
import { Match, MatchStatus } from '@/services/matches/matches.types';
import { orderLinesService } from '@/services/order-lines/order-lines.service';
import { OrderLine } from '@/services/order-lines/order-lines.types';
import { RootState } from '@/store';

export interface MatchState {
  match: Match;
  matchLoading: boolean;

  // Matching related
  deviceLoading: boolean;
  deviceCandidates: Device[];
  deviceCandidatesLoading: boolean;
  orderLineLoading: boolean;
  orderLineCandidates: OrderLine[];
  orderLineCandidatesLoading: boolean;
  productionOrderLoading: boolean;
  locked: 'device' | 'order-line';
  isMatching: boolean;

  // Association
  matchIndex: number;
  matchesList: Match[];
  matchesListLoading: boolean;
}

const state: MatchState = {
  match: null,
  matchLoading: false,
  deviceLoading: false,
  deviceCandidates: [],
  deviceCandidatesLoading: false,
  orderLineLoading: false,
  orderLineCandidates: [],
  orderLineCandidatesLoading: false,
  productionOrderLoading: false,
  locked: null,
  isMatching: false,
  matchIndex: 0,
  matchesList: [],
  matchesListLoading: false
};

const options: Module<MatchState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setMatch: ({ commit, dispatch }, match: Match | string | number): Promise<void> => {
      if (typeof match === 'number' || typeof match === 'string') return dispatch('getMatch', match);
      else {
        commit('match', match);
        if (match?.match_errors?.length > 0) {
          dispatch('match-error/setMatchError', match.match_errors[0], { root: true });
        } else dispatch('match-error/setMatchError', null, { root: true });
      }
    },
    getMatch: ({ commit, dispatch }, matchId: number): Promise<void> => {
      commit('matchLoading', true);
      return matchesService
        .get(matchId)
        .then(match => match && typeof match === 'object' && dispatch('setMatch', match))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('matchLoading', false));
    },
    /* Used in Preparation Process */
    findMatchBySerialNo: (
      { commit, dispatch },
      options: { serialNo: string; stocks?: AppSectionCode[] }
    ): Promise<void> => {
      commit('match', null);
      commit('matchLoading', true);
      const { serialNo, stocks } = options;

      return matchesService
        .findBySerialNo(serialNo, { stocks })
        .then(match => match && typeof match === 'object' && dispatch('setMatch', match))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('matchLoading', false));
    },
    lookForMatch: ({ commit, dispatch }, options: { serial_no: string; stocks?: AppSectionCode[] }): Promise<void> => {
      commit('match', null);
      commit('matchLoading', true);
      const { serial_no, stocks } = options;

      return matchesService
        .findBySerialNo(serial_no, { stocks })
        .then(match => match && typeof match === 'object' && dispatch('setMatch', match))
        .catch(error => {
          if (error.status == 404) {
            dispatch('alert/pushInfo', error.message, { root: true });
          } else {
            dispatch('alert/pushError', error, { root: true });
          }
        })
        .finally(() => commit('matchLoading', false));
    },
    reloadMatch: ({ state, dispatch }): Promise<void> => {
      if (!state.match) return;
      return dispatch('setMatch', state.match.id);
    },
    deleteMatch: ({ commit, dispatch }, matchId: number): Promise<void> => {
      return matchesService
        .delete(matchId)
        .then(() => commit('match', null))
        .catch(error => dispatch('alert/pushError', error, { root: true }));
    },
    /**
     * Used in Deposit/Retrieval processes
     */
    nextMatch({ commit, dispatch, state }): Promise<void> {
      if (!state.matchesList || !state.matchesList.length) return dispatch('listMatches');
      if (state.matchIndex < state.matchesList.length - 1) commit('matchIndex', state.matchIndex + 1);
    },
    previousMatch({ commit, dispatch, state }): Promise<void> {
      if (!state.matchesList || !state.matchesList.length) return dispatch('listMatches');
      else if (state.matchIndex > 0) commit('matchIndex', state.matchIndex - 1);
    },

    /**
     * Used in Preparation / Expedition
     */
    moveToStep: (
      { state, commit, dispatch },
      { matchId, stepCode }: { matchId?: number; stepCode: string }
    ): Promise<void> => {
      const id = matchId || get(state, 'match.id');

      if (!id) throw new Error('Missing Match id');

      return matchesService
        .moveToStep(id, stepCode)
        .then(match => match && commit('match', match))
        .catch(error => dispatch('alert/pushError', error, { root: true }));
    },

    /**
     * Used in Matching process
     */
    matchDevice({ commit, state, dispatch }, { device, isLocked }: { device: Device; isLocked: boolean }): void {
      dispatch('clearCandidates');
      commit('match', { ...state.match, device });
      if (isLocked) commit('locked', 'device');
      if (device?.match && device?.match?.order_id && !state.match?.order_line) {
        commit('orderLineLoading', true);
        orderLinesService
          .get(device.match.order_id)
          .then(orderLine => orderLine && commit('match', { ...state.match, order_line: orderLine }))
          .catch(error => dispatch('alert/pushError', error, { root: true }))
          .finally(() => commit('orderLineLoading', false));
      }
    },
    matchOrderLine(
      { commit, state, dispatch },
      { orderLine, isLocked }: { orderLine: OrderLine; isLocked: boolean }
    ): void {
      dispatch('clearCandidates');
      commit('match', { ...state.match, order_line: orderLine });
      if (isLocked) commit('locked', 'order-line');
      if (orderLine?.device_serial_no && !state.match?.device) {
        commit('deviceLoading', true);
        devicesService
          .get(orderLine.device_serial_no)
          .then(device => device && commit('match', { ...state.match, device }))
          .catch(error => dispatch('alert/pushError', error, { root: true }))
          .finally(() => commit('deviceLoading', false));
      }
    },
    matchBoth(
      { commit, state, dispatch },
      { orderLine, device, isLocked }: { orderLine: OrderLine; device: Device; isLocked: boolean }
    ): void {
      dispatch('clearCandidates');
      commit('match', { ...state.match, order_line: orderLine, device });
      if (isLocked) commit('locked', 'order-line'), commit('locked', 'device');
    },
    matchProductionOrder(
      { commit, state, dispatch },
      { productionOrder, isLocked }: { productionOrder: OrderLine; isLocked: boolean }
    ): void {
      dispatch('clearCandidates');
      commit('match', { ...state.match, production_order: productionOrder });
      if (isLocked) commit('locked', 'production_order');
    },
    confirmMatch({ state, dispatch, commit }): Promise<void> {
      if (
        (!state.match?.device && !state.match?.order_line) ||
        (!state.match?.device && !state.match?.production_order)
      )
        return;
      commit('isMatching', true);
      return matchesService
        .match(state.match)
        .then(() => dispatch('table/listRows', null, { root: true }))
        .then(() => dispatch('alert/pushSuccess', 'Association sauvegardée !', { root: true }))
        .then(() => {
          const matchingItemType = state.match?.order_line ? 'order-line' : 'production-order';
          const matchingItem = state.match?.order_line || state.match?.production_order;
          dispatch(`${matchingItemType}/printPreparationLabel`, matchingItem.id, { root: true });
        })
        .then(() => dispatch('alert/pushInfo', "Impression de l'étiquette de préparation...", { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('isMatching', false));
    },
    clearMatch({ commit, dispatch }): void {
      commit('match', null);
      commit('locked', null);
      dispatch('clearCandidates');
    },
    findDeviceCandidates(
      { commit, dispatch, rootGetters },
      { source, search }: { source: string; search: string }
    ): Promise<void> {
      commit('deviceCandidatesLoading', true);
      const matchStockId = rootGetters['global-settings/matchStockId'];
      const finishedProductStockId = rootGetters['global-settings/finishedProductStockId'];
      return devicesService
        .list({
          filters: {
            fulltext: search,
            match_status: MatchStatus.NOT_MATCHED,
            stock_status_id: source === 'order-line' ? matchStockId : finishedProductStockId
          },
          pagination: { page: 1, limit: 3, sort_by: 'stock_status_id', sort_dir: 'desc' }
        })
        .then(list => list && commit('deviceCandidates', list.values || []))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceCandidatesLoading', false));
    },
    // findOrderCandidates({ commit, dispatch }, search: string): Promise<void> {
    //   commit('orderCandidatesLoading', true);
    //   return orderLinesService
    //     .list({
    //       filters: { fulltext: search, match_status: MatchStatus.NOT_MATCHED },
    //       pagination: { page: 1, limit: 3 }
    //     })
    //     .then(list => list && commit('orderCandidates', list.values || []))
    //     .catch(error => dispatch('alert/pushError', error, { root: true }))
    //     .finally(() => commit('orderCandidatesLoading', false));
    // },
    findOrderLineCandidates({ commit, dispatch }, search: string): Promise<void> {
      commit('orderLineCandidatesLoading', true);
      return orderLinesService
        .list({
          filters: { fulltext: search, match_status: MatchStatus.NOT_MATCHED },
          pagination: { page: 1, limit: 3 }
        })
        .then(list => list && commit('orderLineCandidates', list.values || []))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderLineCandidatesLoading', false));
    },
    clearCandidates({ commit }): void {
      commit('deviceCandidates', []);
      commit('orderLineCandidates', []);
      commit('deviceCandidatesLoading', false);
      commit('orderLineCandidatesLoading', false);
    }
  },
  mutations: {
    match: (state, match) => (state.match = match),
    matchLoading: (state, loading) => (state.matchLoading = loading),
    deviceLoading: (state, loading) => (state.deviceLoading = loading),
    orderLineLoading: (state, loading) => (state.orderLineLoading = loading),
    productionOrderLoading: (state, loading) => (state.productionOrderLoading = loading),
    locked: (state, locked) => (state.locked = locked),
    isMatching: (state, isMatching) => (state.isMatching = isMatching),
    matchIndex: (state, index) => (state.matchIndex = index),
    deviceCandidates: (state, list) => (state.deviceCandidates = list),
    deviceCandidatesLoading: (state, loading) => (state.deviceCandidatesLoading = loading),
    orderLineCandidates: (state, list) => (state.orderLineCandidates = list),
    orderLineCandidatesLoading: (state, loading) => (state.orderLineCandidatesLoading = loading)
  },
  getters: {
    matchedDeviceForm: (state, getters, rootState) =>
      formsService.getForm('device', state.match?.device, rootState['global-settings'].settings),
    matchedOrderForm: (state, getters, rootState) =>
      formsService.getForm('order-line', state.match?.order_line, rootState['global-settings'].settings),
    indexedMatch: state => state.matchesList?.length > 0 && state.matchesList[state.matchIndex],
    matchedDeviceIsIMac: state => state.match?.device.model_namestring === 'iMac'
  }
};

export default options;
