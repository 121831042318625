import { Module } from 'vuex';

import { RootState } from '@/store';
import { ProcessTask } from '@/services/process-tasks/process-tasks.types';
import { processTasksService } from '@/services/process-tasks/process-tasks.service';

export interface ProcessTaskState {
  task: ProcessTask;
  taskLoading: boolean;
  tasks: ProcessTask[];
  tasksLoading: boolean;
}

const state: ProcessTaskState = {
  task: null,
  taskLoading: false,
  tasks: [],
  tasksLoading: false
};

const options: Module<ProcessTaskState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setTasks: ({ commit }, tasks: ProcessTask[]) => {
      commit('tasks', tasks);
    },
    reloadTasks: ({ commit, dispatch }, step_id) => {
      commit('tasksLoading', true);

      return processTasksService
        .index(step_id)
        .then(tasks => tasks && dispatch('setTasks', tasks))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('tasksLoading', false));
    },
    createTask: ({ commit, dispatch }, task: Partial<ProcessTask>) => {
      if (!task) return;

      return processTasksService
        .create(task)
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('tasksLoading', false));
    },
    editTaskInList: ({ commit, state }, update: { index: number; task: ProcessTask }) => {
      const { index, task } = update;
      if (!state.tasks || !task) return;

      commit('tasks', [...state.tasks.slice(0, index), task, ...state.tasks.slice(index + 1)]);
    },
    saveTaskInList: ({ state, dispatch }, update: { index: number; task: ProcessTask }) => {
      if (!state.tasks) return;
      const { index, task } = update;

      return processTasksService
        .update(task.id, task)
        .then(task => dispatch('editTaskInList', { index, task }))
        .catch(error => dispatch('alert/pushError', error, { root: true }));
    },
    validateTaskInList: async ({ state, dispatch }, validate: { index: number; task: ProcessTask }): Promise<void> => {
      if (!state.tasks) return;
      const { index, task } = validate;

      return processTasksService
        .validate(task.id)
        .then(task => dispatch('editTaskInList', { index, task }))
        .then(() => dispatch('alert/pushSuccess', 'Tâche validée avec succès !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }));
    },
    cancelTaskInList: async ({ state, dispatch }, validate: { index: number; task: ProcessTask }): Promise<void> => {
      if (!state.tasks) return;
      const { index, task } = validate;

      return processTasksService
        .reset(task.id)
        .then(task => dispatch('editTaskInList', { index, task }))
        .then(() => dispatch('alert/pushSuccess', 'Tâche annulée avec succès !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }));
    },
    validateAllTasks: async ({ state, dispatch }): Promise<void> => {
      if (!state.tasks) return;

      return processTasksService
        .validateBatch(state.tasks.map(task => task.id))
        .then(() => dispatch('alert/pushSuccess', 'Tâches validées avec succès !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }));
    }
  },
  mutations: {
    task: (state, task) => (state.task = task),
    taskLoading: (state, isLoading) => (state.taskLoading = isLoading),
    tasks: (state, tasks) => (state.tasks = tasks),
    tasksLoading: (state, isLoading) => (state.tasksLoading = isLoading)
  }
};

export default options;
