import { Module } from 'vuex';

import { RootState } from '@/store';
import { formsService } from '@/services/forms/forms.service';
import {
  Purchase,
  PurchasePauseOptions,
  PurchasePaymentEffectiveOptions,
  PurchasePaymentScheduleOptions,
  PurchaseProperties,
  PurchaseRMACreationOptions
} from '@/services/purchases/purchases.types';
import { purchasesService } from '@/services/purchases/purchases.service';

export interface PurchaseState {
  purchase: Purchase;
  purchaseLoading: boolean;
  purchaseCandidatesLoading: boolean;
  purchaseCandidates: Purchase[];
  purchaseControlCandidates: Purchase[];
}
const state: PurchaseState = {
  purchase: null,
  purchaseLoading: false,
  purchaseCandidatesLoading: false,
  purchaseCandidates: [],
  purchaseControlCandidates: []
};

const options: Module<PurchaseState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setPurchase: ({ commit, dispatch }, purchase: Purchase | string | number): Promise<void> => {
      if (typeof purchase === 'number' || typeof purchase === 'string') return dispatch('getPurchase', purchase);
      else commit('purchase', purchase);
    },
    setPurchaseProperties: ({ commit }, properties: PurchaseProperties) => {
      if (state.purchase) {
        Object.entries(properties).map(element => {
          state.purchase[element[0]] = element[1];
        });
        commit('purchase', state.purchase);
      }
    },
    getPurchase: ({ commit, dispatch }, id: number): Promise<void> => {
      commit('purchaseLoading', true);
      return purchasesService
        .get(+id)
        .then(purchase => purchase && commit('purchase', purchase))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('purchaseLoading', false));
    },
    getFiles: ({ commit, dispatch }, id: number): Promise<void> => {
      commit('purchaseLoading', true);
      return purchasesService
        .getFiles(+id)
        .then(purchase => purchase && commit('purchase', purchase))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('purchaseLoading', false));
    },
    newPurchase: ({ commit, dispatch }, init: Partial<Purchase>): Promise<void> => {
      commit('purchase', null);
      commit('purchaseLoading', true);
      return purchasesService
        .new()
        .then(purchase => purchase && commit('purchase', { ...purchase, ...init }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('purchaseLoading', false));
    },
    createPurchase: ({ commit, dispatch }, purchase: Purchase): Promise<void> => {
      commit('purchaseLoading', true);
      return purchasesService
        .create(purchase)
        .then(purchase => purchase && commit('purchase', purchase))
        .then(() => dispatch('alert/pushSuccess', 'Commande créée avec succès !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('purchaseLoading', false));
    },
    dealPurchase: ({ commit, dispatch }, purchaseId: number): Promise<void> => {
      commit('purchaseLoading', true);
      return purchasesService
        .deal(purchaseId)
        .then(purchase => {
          if (purchase) {
            commit('purchase', purchase);
          }
        })
        .catch(error => {
          console.error('Error dealing purchase:', error);
          dispatch('alert/pushError', error, { root: true });
        })
        .finally(() => {
          commit('purchaseLoading', false);
        });
    },
    receivedPurchase: ({ commit, dispatch }, purchase: Purchase): Promise<void> => {
      commit('purchaseLoading', true);
      return purchasesService
        .received(purchase.id, purchase)
        .then(purchase => commit('purchase', purchase))
        .catch(error => {
          console.error('Error reception purchase:', error);
          dispatch('alert/pushError', error, { root: true });
        })
        .finally(() => {
          commit('purchaseLoading', false);
        });
    },
    progress_reserve: ({ commit }, purchaseId: number): Promise<void> => {
      commit('purchaseLoading', true);
      return purchasesService
        .progress_reserve(purchaseId)
        .then(purchase => commit('purchase', purchase))
        .finally(() => {
          commit('purchaseLoading', false);
        });
    },
    finish_reserve: ({ commit }, purchaseId: number): Promise<void> => {
      commit('purchaseLoading', true);
      return purchasesService
        .finish_reserve(purchaseId)
        .then(purchase => commit('purchase', purchase))
        .finally(() => {
          commit('purchaseLoading', false);
        });
    },
    splitPurchase: ({ commit, dispatch }, purchaseId: number): Promise<void> => {
      commit('purchaseLoading', true);
      return purchasesService
        .split(purchaseId)
        .then(purchase => {
          if (purchase) {
            commit('purchase', purchase);
            dispatch('alert/pushSuccess', 'Division avec succès !', { root: true });
          }
        })
        .catch(error => {
          console.error('Error splitting purchase:', error.message);
          dispatch('alert/pushError', error.message, { root: true });
        })
        .finally(() => {
          commit('purchaseLoading', false);
        });
    },
    addQuantityReceived: ({ commit, dispatch }, purchaseId: number): Promise<void> => {
      commit('purchaseLoading', true);
      return purchasesService
        .split(purchaseId)
        .then(purchase => {
          if (purchase) {
            commit('purchase', purchase);
            dispatch('alert/pushSuccess', 'Division avec succès !', { root: true });
          }
        })
        .catch(error => {
          console.error('Error splitting purchase:', error.message);
          dispatch('alert/pushError', error.message, { root: true });
        })
        .finally(() => {
          commit('purchaseLoading', false);
        });
    },
    updatePurchase: ({ commit, dispatch }, purchase: Purchase): Promise<void> => {
      if (!purchase || !purchase.id) {
        console.error('Error: Invalid purchase object in Vuex action');
        return Promise.reject(new Error('Invalid purchase object: Missing ID'));
      }

      commit('purchaseLoading', true);
      return purchasesService
        .update(purchase.id, purchase)
        .then(updatedPurchase => {
          if (updatedPurchase) {
            commit('purchase', updatedPurchase);
            dispatch('alert/pushSuccess', 'Commande mise à jour !', { root: true });
          }
        })
        .catch(error => {
          console.error('Error updating purchase:', error);
          dispatch('alert/pushError', error, { root: true });
        })
        .finally(() => commit('purchaseLoading', false));
    },
    updatePurchaseFile: (
      { commit, dispatch },
      { purchase, files }: { purchase: Purchase; files: Record<string, File> }
    ): Promise<void> => {
      commit('purchaseLoading', true);
      return purchasesService
        .updateFiles(purchase.id, files)
        .then(purchase => {
          return purchase && commit('purchase', purchase);
        })
        .then(() => dispatch('alert/pushSuccess', 'Commande mise à jour !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('purchaseLoading', false));
    },
    updatePhotos: ({ commit, dispatch }, { purchaseId, photos }) => {
      commit('purchaseLoading', true);

      photos
        .filter(photo => typeof photo != 'string')
        .map(photo => {
          purchasesService.updatePhoto(purchaseId, { photos: photo });
        });

      return purchasesService
        .deletePhotos(
          purchaseId,
          photos.filter(photo => typeof photo == 'string')
        )
        .then(purchase => {
          return purchase && commit('purchase', purchase);
        })
        .then(() => dispatch('alert/pushSuccess', 'Commande mise à jour !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('purchaseLoading', false));
    },
    fixAnalysisSupplierFile: (
      { commit, dispatch },
      { purchase, files }: { purchase: Purchase; files: Record<string, File> }
    ): Promise<void> => {
      commit('purchaseLoading', true);
      return purchasesService
        .fixAnalysisSupplierFile(purchase.id, files)
        .then(response => {
          response.purchase && commit('purchase', response.purchase);
          dispatch('alert/pushSuccess', `${response.count} lignes mises à jour`, { root: true });
        })
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('purchaseLoading', false));
    },
    pausePurchase: (
      { commit, dispatch },
      { purchaseId, options, pause }: { purchaseId: string; options: PurchasePauseOptions; pause: boolean }
    ): Promise<void> => {
      commit('purchaseLoading', true);

      return purchasesService
        .pause(purchaseId, options, pause)
        .then(purchase => purchase && commit('purchase', purchase))
        .then(() => dispatch('alert/pushSuccess', 'Commande mise à jour !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('purchaseLoading', false));
    },
    schedulePayment: (
      { commit, dispatch },
      { purchaseId, options }: { purchaseId: string; options: PurchasePaymentScheduleOptions }
    ): Promise<void> => {
      commit('purchaseLoading', true);

      return purchasesService
        .schedule(purchaseId, options)
        .then(purchase => purchase && commit('purchase', purchase))
        .then(() => dispatch('alert/pushSuccess', 'Commande mise à jour !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('purchaseLoading', false));
    },
    effectivePayment: (
      { commit, dispatch },
      { purchaseId, options }: { purchaseId: string; options: PurchasePaymentEffectiveOptions }
    ): Promise<void> => {
      commit('purchaseLoading', true);

      return purchasesService
        .pay(purchaseId, options)
        .then(purchase => purchase && commit('purchase', purchase))
        .then(() => dispatch('alert/pushSuccess', 'Commande mise à jour !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('purchaseLoading', false));
    },
    deal: (
      { commit, dispatch },
      { purchaseId, options }: { purchaseId: string; options: PurchasePaymentEffectiveOptions }
    ): Promise<void> => {
      commit('purchaseLoading', true);

      return purchasesService
        .pay(purchaseId, options)
        .then(purchase => purchase && commit('purchase', purchase))
        .then(() => dispatch('alert/pushSuccess', 'Commande mise à jour !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('purchaseLoading', false));
    },
    createRMA: (
      { commit, dispatch },
      { purchaseId, options }: { purchaseId: string; options: PurchaseRMACreationOptions }
    ): Promise<void> => {
      commit('purchaseLoading', true);

      return purchasesService
        .createRMA(purchaseId, options)
        .then(purchase => purchase && commit('purchase', purchase))
        .then(() => dispatch('alert/pushSuccess', 'Commande mise à jour !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('purchaseLoading', false));
    },
    controlPurchase: ({ commit, dispatch }, purchaseId: string): Promise<void> => {
      commit('purchaseLoading', true);

      return purchasesService
        .control(purchaseId)
        .then(purchase => purchase && commit('purchase', purchase))
        .then(() => dispatch('alert/pushSuccess', 'Commande mise à jour !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('purchaseLoading', false));
    },
    validatePurchase: ({ commit, dispatch }, purchaseId: string): Promise<void> => {
      commit('purchaseLoading', true);

      return purchasesService
        .validate(purchaseId)
        .then(purchase => purchase && commit('purchase', purchase))
        .then(() => dispatch('alert/pushSuccess', 'Commande mise à jour !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('purchaseLoading', false));
    },
    deletePurchase: ({ commit, dispatch }, id: number): Promise<void> => {
      commit('purchaseLoading', true);
      return purchasesService
        .delete(+id)
        .then(() => commit('purchase', null))
        .then(() => dispatch('alert/pushSuccess', 'Commande annulée !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('purchaseLoading', false));
    },
    findPurchaseCandidates({ commit, dispatch }, search: string): Promise<void> {
      if (!search) commit('purchaseCandidates', []);
      else {
        commit('purchaseCandidatesLoading', true);
        return purchasesService
          .list({ filters: { fulltext: search, purchase_query_id: 'none' }, pagination: { page: 1, limit: 3 } })
          .then(list => list && commit('purchaseCandidates', list.values || []))
          .catch(error => dispatch('alert/pushError', error, { root: true }))
          .finally(() => commit('purchaseCandidatesLoading', false));
      }
    },
    findPurchaseControlCandidates({ commit, dispatch }, search: string): Promise<void> {
      if (!search) commit('purchaseControlCandidates', []);
      else {
        commit('purchaseCandidatesLoading', true);
        return purchasesService
          .list({
            filters: { fulltext: search, purchase_query_id: 'none', item_type: 'device', purchase_status: 'control' },
            pagination: { page: 1, limit: 3 }
          })
          .then(list => list && commit('purchaseControlCandidates', list.values || []))
          .catch(error => dispatch('alert/pushError', error, { root: true }))
          .finally(() => commit('purchaseCandidatesLoading', false));
      }
    },
    clearPurchase: ({ dispatch }): Promise<void> => {
      return dispatch('setPurchase', null);
    },
    async exportDevices({ commit, dispatch }, purchaseId: string): Promise<void> {
      commit('purchaseLoading', true);
      return purchasesService
        .exportDevices(purchaseId)
        .then(resp => dispatch('file/saveBase64As', resp, { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('purchaseLoading', false));
    }
  },
  mutations: {
    purchase: (state, purchase) => (state.purchase = purchase),
    purchaseLoading: (state, loading) => (state.purchaseLoading = loading),
    purchaseCandidatesLoading: (state, loading) => (state.purchaseCandidatesLoading = loading),
    purchaseCandidates: (state, purchases) => (state.purchaseCandidates = purchases),
    purchaseControlCandidates: (state, purchases) => (state.purchaseControlCandidates = purchases)
  },
  getters: {
    purchaseForm: (state, getters, rootState) =>
      state.purchase && formsService.getForm('purchase', state.purchase, rootState['global-settings'].settings),
    createPurchaseForm: (state, getters, rootState) =>
      state.purchase &&
      formsService.getForm('purchase-creation', state.purchase, rootState['global-settings'].settings),
    packagingForm: (state, getters, rootState) =>
      state.purchase &&
      formsService.getForm('purchase-reception', state.purchase, rootState['global-settings'].settings)
  }
};

export default options;
