import axios from '@/core/axios';

import { MacStock, MacStocksListOptions } from './mac-stocks.types';
import { CollectionList } from '../collections/collections.types';

export class MacStocksService {
  list(opts: MacStocksListOptions = {}): Promise<CollectionList<'mac-stocks'>> {
    const { filters, pagination, options } = opts;

    return axios
      .get<CollectionList<'mac-stocks'>>(`/mac-stocks`, {
        params: { ...filters, ...pagination, ...options }
      })
      .then(resp => resp?.data);
  }

  update(macStockId: number, macStock: Partial<MacStock>): Promise<MacStock> {
    if (!macStockId) throw new Error('Missing mac-stock id in update');
    return axios.put<MacStock>(`mac-stocks/${macStockId}`, { mac_stock: macStock }).then(resp => resp?.data);
  }
}

export const macStocksService = new MacStocksService();
