import axios from '@/core/axios';
import { PartsFailures } from './parts-failures.types';

export class PartFailuresService {
  create(partsFailureData: { piece_serial_no: string; serial_no: string }): Promise<PartsFailures> {
    if (!partsFailureData) throw new Error('Missing partsFailureData in create');
    return axios
      .post<PartsFailures>('/parts_failures', partsFailureData)
      .then(resp => {
        return resp.data;
      })
      .catch(error => {
        console.error('Error during the creation of partFailures: ', error);
        throw error;
      });
  }
}
export const partFailuresService = new PartFailuresService();
