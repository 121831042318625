import axios from '@/core/axios';
import { AppSectionCode } from '../app-sections/app-sections.types';

import { ProcessStep } from './process-steps.types';

class ProcessStepsService {
  index(params: { match_id: number; stock: AppSectionCode }): Promise<ProcessStep[]> {
    return axios.get<ProcessStep[]>(`/steps`, { params }).then(resp => resp?.data);
  }

  get(matchId: number, stepCode: string): Promise<ProcessStep> {
    return axios.get<ProcessStep>(`/matches/${matchId}/steps/${stepCode}`).then(resp => resp?.data);
  }

  validate(stepId: number): Promise<ProcessStep> {
    if (!stepId) throw new Error('Missing step id in validate');
    return axios.post<ProcessStep>(`steps/${stepId}/validate`).then(resp => resp?.data);
  }

  startStep(stepId: number): Promise<ProcessStep> {
    if (!stepId) throw new Error('Missing step id in validate');
    return axios.post<ProcessStep>(`steps/${stepId}/start`).then(resp => resp?.data);
  }
}

export const processStepsService = new ProcessStepsService();
