import { Subscription, interval, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Module } from 'vuex';

import { controlsService } from '@/services/controls/controls.service';
import { Control } from '@/services/controls/controls.types';
import { Device } from '@/services/devices/devices.types';
import { RootState } from '@/store';

export interface ControlState {
  control: Control;
  controlLoading: boolean;
}

const state: ControlState = {
  control: null,
  controlLoading: false
};

/**
 * Subscriptions cannot be kept in the store because they get updated outside of Vuex mutations
 */
let pollingSubscription: Subscription;

const options: Module<ControlState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    poll: async ({ commit, dispatch }, sessionId: string): Promise<void> => {
      commit('controlLoading', true);
      pollingSubscription = interval(3000)
        .pipe(
          switchMap(() => controlsService.getCurrent(sessionId)),
          catchError(error => {
            dispatch('alert/pushError', error, { root: true });
            commit('controlLoading', false);
            return of(null);
          })
        )
        .subscribe(control => {
          if (control) {
            dispatch('stopPolling');
            commit('control', control);
            commit('controlLoading', false);
          }
        });
    },
    stopPolling() {
      if (pollingSubscription) {
        if (!pollingSubscription.closed) pollingSubscription.unsubscribe();
        pollingSubscription = null;
      }
    },
    createControl: ({ commit, dispatch }, sessionId: string): Promise<void> => {
      commit('controlLoading', true);
      return controlsService
        .create({ session: sessionId })
        .then(control => control && commit('control', control))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('controlLoading', false));
    },
    getControl: ({ commit, dispatch }, controlId: number): Promise<void> => {
      commit('control', null);
      commit('controlLoading', true);
      return controlsService
        .get(controlId)
        .then(control => control && commit('control', control))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('controlLoading', false));
    },
    updateControl: ({ commit, dispatch }, control: Control): Promise<void> => {
      commit('controlLoading', true);
      return controlsService
        .update(control.id, control)
        .then(control => control && commit('control', control))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('controlLoading', false));
    },
    deleteControl: ({ commit, dispatch }, controlId: number): Promise<void> => {
      commit('controlLoading', true);
      return controlsService
        .delete(controlId)
        .then(() => commit('control', null))
        .then(() => dispatch('alert/pushSuccess', 'Contrôle annulé !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('controlLoading', false));
    },
    matchPurchaseWithSerial: ({ commit, state, dispatch }, serialNo: string): Promise<void> => {
      commit('controlLoading', true);
      return controlsService
        .matchPurchaseWithSerial(state.control.id, serialNo)
        .then(control => control && commit('control', control))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('controlLoading', false));
    },
    matchPurchaseWithImei: ({ commit, state, dispatch }, imei: string): Promise<void> => {
      commit('controlLoading', true);
      return controlsService
        .matchPurchaseWithImei(state.control.id, imei)
        .then(control => control && commit('control', control))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('controlLoading', false));
    },
    matchPurchaseWithFeatures: ({ commit, state, dispatch }, features: Partial<Device>): Promise<void> => {
      commit('controlLoading', true);
      return controlsService
        .matchPurchaseWithFeatures(state.control.id, features)
        .then(control => control && commit('control', control))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('controlLoading', false));
    },
    clearControl: ({ commit, dispatch }): void => {
      dispatch('stopPolling');
      commit('control', null);
      commit('controlLoading', true);
    }
  },
  mutations: {
    control: (state, control: Control) => (state.control = control),
    controlLoading: (state, loading) => (state.controlLoading = loading)
  }
};

export default options;
