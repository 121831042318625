import axios from '@/core/axios';

import { SkuDifferenceCostsImportResponse } from './sku-difference-costs.types';

export class SkuDifferenceCostsService {
  importSkuDifferenceCost(file: File): Promise<SkuDifferenceCostsImportResponse> {
    const formData = new FormData();
    formData.append('file', file);
    return axios
      .post<SkuDifferenceCostsImportResponse>('/sku-diff/import', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(resp => resp?.data);
  }
}

export const skuDifferenceCostsService = new SkuDifferenceCostsService();
