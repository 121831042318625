import { AppExternalError } from '@/core/errors/app-external-error.class';

export interface PrintOptions {
  data: any;
  printer?: string;
}

export class PrinterNotFoundError extends AppExternalError {
  constructor(name) {
    super(`L'imprimante ${name} est introuvable. L'impression est annulée.`);
  }
}
