import axios from '@/core/axios';

import { Invoice } from './invoices.types';

export class InvoicesService {
  list(orderId: number): Promise<Invoice[]> {
    return axios.get<Invoice[]>(`/invoices/list/${orderId}`).then(resp => {
      const invoices = resp?.data || [];
      invoices.sort((b, a) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
      return invoices;
    });
  }
}

export const invoicesService = new InvoicesService();
