import { Module } from 'vuex';

import { ModalOptions } from '@/components/Modal/ModalHolder/ModalHolder.types';
import { RootState } from '@/store';

export interface ModalState {
  modal: ModalOptions;
}

const state: ModalState = {
  modal: null
};

const options: Module<ModalState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    showModal({ commit, state, dispatch }, options: ModalOptions): Promise<void> {
      if (options.onClose) {
        const closeHook = options.onClose;
        /* We "encapsulate" the callback because we always want it to forward the info whether the user confirmed or not */
        options.onClose = async (isConfirmed?: boolean) => closeHook(isConfirmed).then(() => isConfirmed);
      } else {
        /* If there is no callback provided, we just forward the confirmation info so we can chain modals */
        options.onClose = async (isConfirmed?: boolean) => isConfirmed;
      }

      if (!state.modal) commit('modal', options);
      else {
        /* We chain the old modal close hook with the new modal options */
        const { onClose: oldCloseHook, ...currentOptions } = state.modal;
        const newCloseHook = options.onClose;
        options.onClose = (isConfirmed?: boolean) =>
          newCloseHook(isConfirmed).then((isConfirmed?: boolean) => oldCloseHook(isConfirmed));
        commit('modal', currentOptions);
        return dispatch('hideModal').then(() => commit('modal', options));
      }
    },
    hideModal({ commit, state }, options: any): Promise<void> {
      if (state.modal && state.modal.onClose && typeof state.modal.onClose === 'function') {
        const { onClose } = state.modal;
        setTimeout(() => onClose(options), 0);
      }
      commit('modal', null);
      return new Promise(resolve => setTimeout(() => resolve(), 250));
    }
  },
  mutations: {
    modal: (state, modal) => (state.modal = modal)
  }
};

export default options;
