import axios from '@/core/axios';
import { CollectionList } from '../collections/collections.types';

import { MatchError, MatchErrorsListOptions } from './match-errors.types';

export class MatchErrorsService {
  list(opts: MatchErrorsListOptions = {}): Promise<CollectionList<'match-errors'>> {
    const { filters, pagination } = opts;
    return axios
      .get<CollectionList<'match-errors'>>('/match-errors', { params: { ...filters, ...pagination } })
      .then(resp => resp?.data);
  }

  get(matchErrorId: number): Promise<MatchError> {
    return axios.get<MatchError>(`/matchError/${matchErrorId}`).then(resp => resp?.data);
  }

  create(matchError: Partial<MatchError>): Promise<MatchError> {
    return axios.post<MatchError>(`match-errors`, { match_error: matchError }).then(resp => resp?.data);
  }

  update(matchError: Partial<MatchError>): Promise<MatchError> {
    if (!matchError.id) throw new Error('Missing matchError id in update');
    return axios.put<MatchError>(`match-errors/${matchError.id}`, { match_error: matchError }).then(resp => resp?.data);
  }

  fix(matchErrorId: number): Promise<MatchError> {
    if (!matchErrorId) throw new Error('Missing matchError id in fix');
    return axios.post<MatchError>(`match-errors/${matchErrorId}/fix`).then(resp => resp?.data);
  }

  delete(matchErrorId: number): Promise<void> {
    if (!matchErrorId) throw new Error('Missing matchError id in delete');
    return axios.delete<void>(`/matchError/${matchErrorId}`).then(resp => resp?.data);
  }
}

export const matchErrorsService = new MatchErrorsService();
