import axios from '@/core/axios';

import { User, UsersList, UsersListOptions, ChangePasswordOptions } from './users.types';

export class UsersService {
  list(opts: UsersListOptions = {}): Promise<UsersList> {
    const { filters, pagination } = opts;
    return axios.get<UsersList>(`/users`, { params: { ...filters, ...pagination } }).then(resp => resp?.data);
  }

  get(userId: number): Promise<User> {
    return axios.get<User>(`/users/${userId}`).then(resp => resp?.data);
  }

  changePassword(userId: string, params: ChangePasswordOptions): Promise<User> {
    return axios.post<User>(`/users/${userId}/change_password`, params).then(resp => resp?.data);
  }

  getAuthenticatedUser(): Promise<User> {
    return axios.get('/user').then(resp => resp?.data);
  }
}

export const usersService = new UsersService();
