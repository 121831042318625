import axios from '@/core/axios';
import { CollectionList } from '../collections/collections.types';

import { MarketplaceSku, MarketplaceSkusListOptions, MarketplaceSkuImportResponse } from './marketplace-skus.types';

export class MarketplaceSkusService {
  list(opts: MarketplaceSkusListOptions = {}): Promise<CollectionList<'marketplace-skus'>> {
    const { filters, pagination } = opts;
    return axios
      .get<CollectionList<'marketplace-skus'>>('/marketplace-skus', { params: { ...filters, ...pagination } })
      .then(resp => resp?.data);
  }

  update(marketplaceSkuId: number, marketplaceSku: Partial<MarketplaceSku>): Promise<MarketplaceSku> {
    if (!marketplaceSku) throw new Error('Missing marketplace sku id in update');
    return axios
      .put<MarketplaceSku>(`marketplace-skus/${marketplaceSkuId}`, { marketplace_sku: marketplaceSku })
      .then(resp => resp?.data);
  }

  delete(marketplaceSkuId: number): Promise<void> {
    if (!marketplaceSkuId) throw new Error('Missing marketplace sku id in delete');
    return axios.delete<void>(`/marketplace-skus/${marketplaceSkuId}`).then(resp => resp?.data);
  }

  thresholds(opts: MarketplaceSkusListOptions = {}): Promise<MarketplaceSku> {
    const { filters, pagination } = opts;
    return axios
      .get<MarketplaceSku>(`marketplace-skus/thresholds`, { params: { ...filters, ...pagination } })
      .then(resp => resp?.data);
  }

  saveThresholds(opts: MarketplaceSkusListOptions = {}, ths: number[][]): Promise<MarketplaceSku> {
    const { filters, pagination } = opts;
    return axios
      .post<MarketplaceSku>(`/marketplace-skus/save-thresholds`, {
        params: { ...filters, ...pagination },
        thresholds: ths
      })
      .then(resp => resp?.data);
  }

  importMarketplaceSkus(file: File): Promise<MarketplaceSkuImportResponse> {
    const formData = new FormData();
    formData.append('file', file);
    return axios
      .post<MarketplaceSkuImportResponse>('/marketplace-skus/import', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(resp => resp?.data);
  }
}

export const marketplaceSkusService = new MarketplaceSkusService();
