import { Module } from 'vuex';

import { skuDifferenceCostsService } from '@/services/sku-difference-costs/sku-difference-costs.service';
import { SkuDifferenceCost } from '@/services/sku-difference-costs/sku-difference-costs.types';
import { RootState } from '@/store';

export interface SkuDifferenceCostState {
  ordersImporting: any;
  skuDifferenceCostsLoading: boolean;
  skuDifferenceCosts: SkuDifferenceCost[];
}

const state: SkuDifferenceCostState = {
  skuDifferenceCostsLoading: false,
  skuDifferenceCosts: [],
  ordersImporting: undefined
};

const options: Module<SkuDifferenceCostState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    importSkuDifferenceCost: ({ commit, dispatch }, { file }: { file: File }): Promise<void> => {
      commit('ordersImporting', true);
      return skuDifferenceCostsService
        .importSkuDifferenceCost(file)
        .then(resp =>
          dispatch(
            'alert/pushSuccess',
            `${resp.count} modification${resp.count > 1 ? 's' : ''} importée${resp.count > 1 ? 's' : ''} !`,
            { root: true }
          )
        )
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('ordersImporting', false));
    }
  },
  mutations: {
    ordersImporting: (state, importing) => (state.ordersImporting = importing)
  }
};

export default options;
