import axios, { AxiosInstance } from 'axios';
import { get } from 'lodash';

import store from '@/store';
import router from '@/router';
import { SessionExpiredError } from '@/services/auth/auth.types';

function axiosProvider(): AxiosInstance {
  const rootUrl = process.env.NODE_ENV === 'production' ? 'https' : 'http';
  const tenant = store.state.auth.tenant;
  const instance = axios.create({
    baseURL: `${rootUrl}://${tenant}.${process.env.VUE_APP_BACKEND_URL}`,
    headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
  });

  instance.interceptors.request.use(config => {
    const token = store.state.auth.token;
    const tenant = store.state.auth.tenant;
    config.headers.Authorization = token;
    config.baseURL = `${rootUrl}://${tenant}.${process.env.VUE_APP_BACKEND_URL}`;
    return config;
  });

  instance.interceptors.response.use(
    response => response,
    error => {
      const status = get(error, 'response.status');
      const data = get(error, 'response.data');
      const url = get(error, 'config.url');
      if (status === 403 && data.message && data.message === 'Accès non autorisé sur cette adresse IP') {
        store.dispatch('auth/signOut');
      }
      if (status === 401 && url !== '/login') {
        store.dispatch('auth/signOut');
        router.push('/login');
        return store.dispatch('alert/pushError', new SessionExpiredError());
      } else if (data && data.message) return Promise.reject(data);
      else return Promise.reject(error);
    }
  );

  return instance;
}

export default axiosProvider();
