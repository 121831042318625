import axios from '@/core/axios';

import { Grade, GradeDevice, GradeReportFile } from './grades.types';

export class GradesService {
  get(serial_no: string): Promise<Grade> {
    if (!serial_no) throw new Error('Missing serial_no in get');
    return axios.get<Grade>(`/grades/${serial_no}`).then(resp => resp?.data);
  }

  list(serial_no: string): Promise<Grade[]> {
    if (!serial_no) throw new Error('Missing serial_no in get');
    return axios.get<Grade[]>(`/grades/list/${serial_no}`).then(resp => resp?.data);
  }

  create(serial_no: string): Promise<Grade> {
    return axios.post<Grade>(`/grades`, { serial_no }).then(resp => resp?.data);
  }

  update(grade_id: number, data: any): Promise<GradeDevice> {
    if (!grade_id) throw new Error('Missing gradeId in update');
    return axios.put<GradeDevice>(`/grades/${grade_id}`, { data }).then(resp => resp?.data);
  }

  getReport(grade_id: number): Promise<GradeReportFile> {
    return axios.get<GradeReportFile>(`/grades/reports/${grade_id}`).then(resp => resp?.data);
  }

  updateOkamacGrade(grade_id: number, grade: string): Promise<Grade> {
    return axios.post<Grade>(`/grades/update-okamac-grade/${grade_id}`, { grade }).then(resp => resp?.data);
  }
}
export const gradesService = new GradesService();
