import { createWebHistory, createRouter, RouteRecordRaw } from 'vue-router';

import AdminView from '@/views/Admin/Admin.vue';
import AdminDatabaseView from '@/views/Admin/AdminDatabase.vue';
import AdminExportsView from '@/views/Admin/AdminExports.vue';
import AdminHomeView from '@/views/Admin/AdminHome.vue';
import HomeView from '@/views/Home.vue';
import LoginView from '@/views/Login.vue';
import LogoutView from '@/views/Logout.vue';
import NavbarView from '@/views/Navbar.vue';
import AdvView from '@/views/Processes/Adv/Adv.vue';
// import AdvHomeView from '@/views/Processes/Adv/AdvHome.vue';
import AdvAltHomeView from '@/views/Processes/Adv/AdvAltHome.vue';
import AdvCatalogueTableView from '@/views/Processes/Adv/AdvCatalogueTable.vue';
import AdvCommissionTableView from '@/views/Processes/Adv/AdvCommissionTable.vue';
import ProcessView from '@/views/Processes/SpecialProcess/SpecialProcess.vue';
import SpecialProcessHome from '@/views/Processes/SpecialProcess/SpecialProcessHome.vue';
import SpecialProcessPieceTableView from './views/Processes/SpecialProcess/SpecialProcessPieceTable.vue';
import ChangelogView from '@/views/Changelog/Changelog.vue';
import ChangelogHomeView from '@/views/Changelog/ChangelogHome.vue';
import ControlView from '@/views/Processes/Control/Control.vue';
import Control1NewView from '@/views/Processes/Control/Control1New.vue';
import Control2FormView from '@/views/Processes/Control/Control2Form.vue';
import Control3ConfirmView from '@/views/Processes/Control/Control3Confirm.vue';
import ControlHomeView from '@/views/Processes/Control/ControlHome.vue';
import ControlProcessView from '@/views/Processes/Control/ControlProcess.vue';
import GradeScanView from '@/views/Processes/Control/Grade/GradeScan.vue';
import GradeProcessView from '@/views/Processes/Control/Grade/GradeProcess.vue';
import GradeTasksView from '@/views/Processes/Control/Grade/GradeTasks.vue';
import ControlTableView from '@/views/Processes/Control/ControlTable.vue';
import MatchingView from '@/views/Processes/Matching/Matching.vue';
import MatchingDepositView from '@/views/Processes/Matching/MatchingDeposit.vue';
import MatchingHomeView from '@/views/Processes/Matching/MatchingHome.vue';
import MatchingPrintLabelView from '@/views/Processes/Matching/MatchingPrintLabel.vue';
import MatchingRetrievalView from '@/views/Processes/Matching/MatchingRetrieval.vue';
import MatchingRetrievalProcessView from '@/views/Processes/Matching/MatchingRetrievalProcess.vue';
import MatchingRetrievalStartView from '@/views/Processes/Matching/MatchingRetrievalStart.vue';
import MatchingRetrievalSummaryView from '@/views/Processes/Matching/MatchingRetrievalSummary.vue';
import MatchingTableView from '@/views/Processes/Matching/MatchingTable.vue';
import PreparationView from '@/views/Processes/Preparation/Preparation.vue';
import PreparationHomeView from '@/views/Processes/Preparation/PreparationHome.vue';
import PreparationProcessView from '@/views/Processes/Preparation/PreparationProcess.vue';
import PreparationScanView from '@/views/Processes/Preparation/PreparationScan.vue';
import PreparationTableView from '@/views/Processes/Preparation/PreparationTable.vue';
import PreparationTasksView from '@/views/Processes/Preparation/PreparationTasks.vue';
import PreparationInfosView from '@/views/Processes/Preparation/PreparationInfos.vue';
import ExpeditionView from '@/views/Processes/Expedition/Expedition.vue';
import ExpeditionCagesTableView from '@/views/Processes/Expedition/ExpeditionCagesTable.vue';
import ExpeditionInfosView from '@/views/Processes/Expedition/ExpeditionInfos.vue';
import ExpeditionHomeView from '@/views/Processes/Expedition/ExpeditionHome.vue';
import ExpeditionTableView from '@/views/Processes/Expedition/ExpeditionTable.vue';
import ExpeditionProcessView from '@/views/Processes/Expedition/ExpeditionProcess.vue';
import ExpeditionScanView from '@/views/Processes/Expedition/ExpeditionScan.vue';
import ExpeditionTasksView from '@/views/Processes/Expedition/ExpeditionTasks.vue';
import ProcessesHomeView from '@/views/Processes/ProcessesHome.vue';
import PurchaseOrdersView from '@/views/Processes/Purchase/Orders/PurchaseOrders.vue';
import PurchaseOrdersConsumableView from '@/views/Processes/Purchase/Orders/PurchaseOrdersConsumable.vue';
import PurchaseOrdersMacView from '@/views/Processes/Purchase/Orders/PurchaseOrdersMac.vue';
import PurchaseOrdersOtherView from '@/views/Processes/Purchase/Orders/PurchaseOrdersOther.vue';
import PurchaseOrdersSpareView from '@/views/Processes/Purchase/Orders/PurchaseOrdersSpare.vue';
import PurchaseView from '@/views/Processes/Purchase/Purchase.vue';
import PurchaseTableView from '@/views/Processes/Purchase/PurchaseTable.vue';
import PurchaseReceptionsView from '@/views/Processes/Purchase/Receptions/PurchaseReceptions.vue';
import PurchaseReceptionsConsumableView from '@/views/Processes/Purchase/Receptions/PurchaseReceptionsConsumable.vue';
import PurchaseReceptionsMacView from '@/views/Processes/Purchase/Receptions/PurchaseReceptionsMac.vue';
import PurchaseReceptionsOtherView from '@/views/Processes/Purchase/Receptions/PurchaseReceptionsOther.vue';
import PurchaseReceptionsSpareView from '@/views/Processes/Purchase/Receptions/PurchaseReceptionsSpare.vue';
import PurchaseStocksView from '@/views/Processes/Purchase/Stocks/PurchaseStocks.vue';
import PurchaseStocksConsumableView from '@/views/Processes/Purchase/Stocks/PurchaseStocksConsumable.vue';
import PurchaseStocksMacView from '@/views/Processes/Purchase/Stocks/PurchaseStocksMac.vue';
import PurchaseStocksQueriesView from '@/views/Processes/Purchase/Stocks/PurchaseStocksQueries.vue';
import PurchaseStocksSpareView from '@/views/Processes/Purchase/Stocks/PurchaseStocksSpare.vue';
import PurchaseSuppliersView from '@/views/Processes/Purchase/Suppliers/PurchaseSuppliers.vue';
import SavView from '@/views/Processes/Sav/Sav.vue';
import SavHomeView from '@/views/Processes/Sav/SavHome.vue';
import SavTableView from '@/views/Processes/Sav/SavTable.vue';
import SubNavbarView from '@/views/SubNavbar.vue';
import PartsScanView from '@/views/Processes/SpecialProcess/PartsScan.vue';
import Parts from '@/views/Processes/SpecialProcess/Parts.vue';
import PartsInfosView from '@/views/Processes/SpecialProcess/PartsInfosView.vue';
import store from '@/store';
import RepairView from '@/views/Processes/Repair/Repair.vue';
import RepairHomeView from '@/views/Processes/Repair/RepairHome.vue';
import RepairInfosView from '@/views/Processes/Repair/RepairInfos.vue';
import RepairProcessView from '@/views/Processes/Repair/RepairProcess.vue';
import RepairScanView from '@/views/Processes/Repair/RepairScan.vue';
import RepairTableView from '@/views/Processes/Repair/RepairTable.vue';
import RepairTasksView from '@/views/Processes/Repair/RepairTasks.vue';

const routes: RouteRecordRaw[] = [
  {
    name: 'login',
    path: '/login',
    component: LoginView
  },
  {
    path: '/',
    component: HomeView,
    redirect: { name: 'processes-home' },
    children: [
      {
        path: '',
        name: 'processes-home',
        component: ProcessesHomeView
      },
      {
        path: 'changelog',
        name: 'changelog',
        component: ChangelogView,
        redirect: { name: 'changelog-home' },
        children: [
          {
            path: '',
            name: 'changelog-home',
            components: {
              navbar: NavbarView,
              default: ChangelogHomeView
            }
          }
        ]
      },
      {
        path: 'control',
        name: 'control',
        component: ControlView,
        redirect: { name: 'control-home' },
        children: [
          {
            path: '',
            name: 'control-home',
            components: {
              navbar: NavbarView,
              subnavbar: SubNavbarView,
              default: ControlHomeView
            }
          },
          {
            path: 'table',
            name: 'control-table',
            components: {
              navbar: NavbarView,
              default: ControlTableView
            }
          },
          {
            path: 'process',
            name: 'control-process',
            component: ControlProcessView,
            redirect: { name: 'control-new' },
            children: [
              {
                path: '',
                name: 'control-new',
                component: Control1NewView
              },
              {
                path: ':controlId/form',
                name: 'control-form',
                component: Control2FormView,
                props: true
              },
              {
                path: ':serialNo/confirm',
                name: 'control-confirm',
                component: Control3ConfirmView,
                props: true
              }
            ]
          },
          {
            path: 'grading',
            name: 'grade-process',
            component: GradeProcessView,
            redirect: { name: 'grade-scan' },
            children: [
              {
                path: 'scan',
                name: 'grade-scan',
                component: GradeScanView
              },
              {
                path: ':deviceId',
                name: 'grade-tasks',
                components: {
                  default: GradeTasksView
                },
                props: {
                  default: true
                }
              },
              {
                path: 'table',
                name: 'grade-table',
                components: {
                  navbar: NavbarView,
                  default: PreparationTableView
                }
              }
            ]
          }
        ]
      },
      {
        path: 'matching',
        name: 'matching',
        component: MatchingView,
        redirect: { name: 'matching-home' },
        children: [
          {
            path: '',
            name: 'matching-home',
            components: {
              navbar: NavbarView,
              subnavbar: SubNavbarView,
              default: MatchingHomeView
            }
          },
          {
            path: 'table/:table',
            name: 'matching-table',
            components: {
              navbar: NavbarView,
              default: MatchingTableView
            },
            props: {
              default: true
            }
          },
          {
            path: 'deposit',
            name: 'matching-deposit',
            components: {
              default: MatchingDepositView
            }
          },
          {
            path: 'retrieval',
            name: 'matching-retrieval',
            component: MatchingRetrievalProcessView,
            redirect: { name: 'retrieval-start' },
            children: [
              {
                path: ':pickingId/start',
                name: 'retrieval-start',
                component: MatchingRetrievalStartView,
                props: true
              },
              {
                path: ':pickingId/process',
                name: 'retrieval-process',
                component: MatchingRetrievalView,
                props: true
              },
              {
                path: ':pickingId/summary',
                name: 'retrieval-summary',
                component: MatchingRetrievalSummaryView,
                props: true
              }
            ]
          },
          {
            path: 'print',
            name: 'matching-print',
            components: {
              default: MatchingPrintLabelView
            }
          }
        ]
      },
      {
        path: 'prepare',
        name: 'prepare',
        component: PreparationView,
        redirect: { name: 'prepare-home' },
        children: [
          {
            path: '',
            name: 'prepare-home',
            components: {
              navbar: NavbarView,
              subnavbar: SubNavbarView,
              default: PreparationHomeView
            }
          },
          {
            path: 'table',
            name: 'prepare-table',
            components: {
              navbar: NavbarView,
              default: PreparationTableView
            }
          },
          {
            path: 'process',
            name: 'prepare-process',
            component: PreparationProcessView,
            redirect: { name: 'prepare-scan' },
            children: [
              {
                path: 'scan',
                name: 'prepare-scan',
                component: PreparationScanView
              },
              {
                path: ':matchId',
                name: 'prepare-tasks',
                components: {
                  default: PreparationTasksView,
                  infos: PreparationInfosView
                },
                props: {
                  default: true
                }
              }
            ]
          }
        ]
      },
      {
        path: 'dispatch',
        name: 'dispatch',
        component: ExpeditionView,
        redirect: { name: 'dispatch-home' },
        children: [
          {
            path: '',
            name: 'dispatch-home',
            components: {
              navbar: NavbarView,
              subnavbar: SubNavbarView,
              default: ExpeditionHomeView
            }
          },
          {
            name: 'dispatch-table',
            path: 'table',
            components: {
              navbar: NavbarView,
              default: ExpeditionTableView
            }
          },
          {
            name: 'dispatch-cage',
            path: 'cages',
            components: {
              navbar: NavbarView,
              default: ExpeditionCagesTableView
            }
          },
          {
            path: 'dispatch-process',
            component: ExpeditionProcessView,
            redirect: { name: 'dispatch-scan' },
            children: [
              {
                path: 'scan',
                name: 'dispatch-scan',
                component: ExpeditionScanView
              },
              {
                path: ':matchId',
                name: 'dispatch-tasks',
                components: {
                  default: ExpeditionTasksView,
                  infos: ExpeditionInfosView
                },
                props: {
                  default: true
                }
              }
            ]
          }
        ]
      },
      {
        name: 'purchase',
        path: '/purchase',
        component: PurchaseView,
        redirect: { name: 'purchase-table' },
        children: [
          {
            name: 'purchase-table',
            path: 'table',
            redirect: { name: 'purchase-stocks' },
            components: {
              navbar: NavbarView,
              subnavbar: SubNavbarView,
              default: PurchaseTableView
            },
            children: [
              {
                path: 'stocks',
                name: 'purchase-stocks',
                redirect: { name: 'purchase-stocks-mac' },
                component: PurchaseStocksView,
                children: [
                  {
                    path: 'mac',
                    name: 'purchase-stocks-mac',
                    component: PurchaseStocksMacView
                  },
                  {
                    path: 'spare',
                    name: 'purchase-stocks-spare',
                    component: PurchaseStocksSpareView
                  },
                  {
                    path: 'consumable',
                    name: 'purchase-stocks-consumable',
                    component: PurchaseStocksConsumableView
                  },
                  {
                    path: 'queries',
                    name: 'purchase-stocks-queries',
                    component: PurchaseStocksQueriesView
                  }
                ]
              },
              {
                path: 'orders',
                name: 'purchase-orders',
                redirect: { name: 'purchase-orders-mac' },
                component: PurchaseOrdersView,
                children: [
                  {
                    path: 'mac',
                    name: 'purchase-orders-mac',
                    component: PurchaseOrdersMacView
                  },
                  {
                    path: 'spare',
                    name: 'purchase-orders-spare',
                    component: PurchaseOrdersSpareView
                  },
                  {
                    path: 'spare',
                    name: 'purchase-orders-consumable',
                    component: PurchaseOrdersConsumableView
                  },
                  {
                    path: 'other',
                    name: 'purchase-orders-other',
                    component: PurchaseOrdersOtherView
                  }
                ]
              },
              {
                path: 'order-lines',
                name: 'purchase-orders',
                redirect: { name: 'purchase-orders-mac' },
                component: PurchaseOrdersView,
                children: [
                  {
                    path: 'mac',
                    name: 'purchase-orders-mac',
                    component: PurchaseOrdersMacView
                  },
                  {
                    path: 'spare',
                    name: 'purchase-orders-spare',
                    component: PurchaseOrdersSpareView
                  },
                  {
                    path: 'spare',
                    name: 'purchase-orders-consumable',
                    component: PurchaseOrdersConsumableView
                  },
                  {
                    path: 'other',
                    name: 'purchase-orders-other',
                    component: PurchaseOrdersOtherView
                  }
                ]
              },
              {
                path: 'receptions',
                name: 'purchase-receptions',
                redirect: { name: 'purchase-receptions-mac' },
                component: PurchaseReceptionsView,
                children: [
                  {
                    path: 'mac',
                    name: 'purchase-receptions-mac',
                    component: PurchaseReceptionsMacView
                  },
                  {
                    path: 'spare',
                    name: 'purchase-receptions-spare',
                    component: PurchaseReceptionsSpareView
                  },
                  {
                    path: 'spare',
                    name: 'purchase-receptions-consumable',
                    component: PurchaseReceptionsConsumableView
                  },
                  {
                    path: 'other',
                    name: 'purchase-receptions-other',
                    component: PurchaseReceptionsOtherView
                  }
                ]
              },
              {
                path: 'suppliers',
                name: 'purchase-suppliers',
                component: PurchaseSuppliersView
              }
            ]
          }
        ]
      },
      {
        name: 'adv',
        path: '/adv',
        component: AdvView,
        redirect: { name: 'adv-home' },
        children: [
          {
            path: '',
            name: 'adv-home',
            components: {
              navbar: NavbarView,
              subnavbar: SubNavbarView,
              default: AdvAltHomeView
            }
          },
          {
            path: 'catalogue-table',
            name: 'adv-catalogue-table',
            components: {
              navbar: NavbarView,
              default: AdvCatalogueTableView
            }
          },
          {
            path: 'commission-table',
            name: 'adv-commission-table',
            components: {
              navbar: NavbarView,
              default: AdvCommissionTableView
            }
          }
        ]
      },
      {
        name: 'sav',
        path: '/sav',
        component: SavView,
        redirect: { name: 'sav-home' },
        children: [
          {
            path: '',
            name: 'sav-home',
            components: {
              navbar: NavbarView,
              subnavbar: SubNavbarView,
              default: SavHomeView
            }
          },
          {
            path: 'table/:table',
            name: 'sav-table',
            components: {
              navbar: NavbarView,
              default: SavTableView
            },
            props: {
              default: true
            }
          }
        ]
      },
      {
        name: 'specialprocess',
        path: '/specialprocess',
        component: ProcessView,
        redirect: { name: 'process-home' },
        children: [
          {
            path: '',
            name: 'process-home',
            components: {
              navbar: NavbarView,
              subnavbar: SubNavbarView,
              default: SpecialProcessHome
            }
          },
          {
            path: 'parts',
            name: 'process-piece-table',
            components: {
              navbar: NavbarView,
              default: SpecialProcessPieceTableView
            },
            props: {
              default: true
            }
          },
          {
            path: 'parts-process',
            component: Parts,
            redirect: { name: 'parts-scan' },
            children: [
              {
                path: 'scan',
                name: 'parts-scan',
                component: PartsScanView
              },
              {
                path: ':matchId',
                name: 'parts-infos',
                component: PartsInfosView
              }
            ]
          }
        ]
      },
      {
        name: 'repair',
        path: '/repair',
        component: RepairView,
        redirect: { name: 'repair-home' },
        children: [
          {
            path: '',
            name: 'repair-home',
            components: {
              navbar: NavbarView,
              subnavbar: SubNavbarView,
              default: RepairHomeView
            }
          },
          {
            path: 'table',
            name: 'repair-table',
            components: {
              navbar: NavbarView,
              default: RepairTableView
            }
          },
          {
            path: 'process',
            name: 'repair-process',
            component: RepairProcessView,
            redirect: { name: 'repair-scan' },
            children: [
              {
                path: 'scan',
                name: 'repair-scan',
                component: RepairScanView
              },
              {
                path: ':deviceId',
                name: 'repair-tasks',
                components: {
                  default: RepairTasksView,
                  infos: RepairInfosView
                },
                props: {
                  default: true
                }
              }
            ]
          }
        ]
      },
      {
        name: 'admin',
        path: '/admin',
        component: AdminView,
        redirect: { name: 'admin-home' },
        children: [
          {
            name: 'admin-home',
            path: '',
            components: {
              navbar: NavbarView,
              subnavbar: SubNavbarView,
              default: AdminHomeView
            }
          },
          {
            name: 'admin-database',
            path: 'database/:adminPath*',
            components: {
              navbar: NavbarView,
              subnavbar: SubNavbarView,
              default: AdminDatabaseView
            }
          },
          {
            name: 'data-exports',
            path: 'exports',
            components: {
              navbar: NavbarView,
              subnavbar: SubNavbarView,
              default: AdminExportsView
            }
          }
        ],
        beforeEnter(to, from, next) {
          if (store.getters['auth/isAuthenticated'] && store.getters['user/canAccessAdmin']) next();
          else next('login');
        }
      },
      {
        name: 'logout',
        path: '/logout',
        component: LogoutView
      }
    ],
    beforeEnter(to, from, next) {
      if (store.getters['auth/isAuthenticated']) next();
      else next('login');
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/login'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
