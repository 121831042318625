import { Module } from 'vuex';

import { RootState } from '@/store';
import { Location } from '@/services/locations/locations.types';
import { locationsService } from '@/services/locations/locations.service';

export interface LocationState {
  locations: Location[];
  locationsLoading: boolean;
}

const state: LocationState = {
  locations: [],
  locationsLoading: false
};

const options: Module<LocationState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    indexLocations: async ({ commit, dispatch }): Promise<void> => {
      commit('locationsLoading', true);

      return locationsService
        .index()
        .then(locations => locations && commit('locations', locations))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('locationsLoading', false));
    },
    releaseLocation: async ({ dispatch }, serial_number: string): Promise<void> => {
      return locationsService
        .release({ device: serial_number })
        .catch(error => dispatch('alert/pushError', error, { root: true }));
    }
  },
  mutations: {
    locations: (state, locations) => (state.locations = locations),
    locationsLoading: (state, isLoading) => (state.locationsLoading = isLoading)
  }
};

export default options;
