import { Module } from 'vuex';

import { RootState } from '@/store';
import { printerService } from '@/services/printer/printer.service';
import { PrintOptions } from '@/services/printer/printer.types';

export interface PrinterState {
  sendingToPrinter: boolean;
}

const state: PrinterState = {
  sendingToPrinter: false
};

const options: Module<PrinterState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    printWith({ commit, dispatch }, { data, printer }: PrintOptions): Promise<void> {
      commit('sendingToPrinter', true);

      return printerService
        .printWith(data, printer)
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('sendingToPrinter', false));
    }
  },
  mutations: {
    sendingToPrinter: (state, isSending) => (state.sendingToPrinter = isSending)
  }
};

export default options;
