import axios from '@/core/axios';
import { CollectionList } from '../collections/collections.types';

import { Supplier, SupplierRib, SuppliersListOptions } from './suppliers.types';

export class SuppliersService {
  list(opts: SuppliersListOptions = {}): Promise<CollectionList<'suppliers'>> {
    const { filters, pagination, options } = opts;

    return axios
      .get<CollectionList<'suppliers'>>(`/suppliers`, {
        params: { ...filters, ...pagination, ...options }
      })
      .then(resp => resp?.data);
  }

  order_list(opts: SuppliersListOptions = {}): Promise<CollectionList<'suppliers'>> {
    const { filters } = opts;

    return axios
      .get<CollectionList<'suppliers'>>(`/suppliers/order_list`, {
        params: { ...filters }
      })
      .then(resp => resp?.data);
  }

  get(supplierId: number): Promise<Supplier> {
    return axios.get<Supplier>(`/suppliers/${supplierId}`).then(resp => resp?.data);
  }

  new(): Promise<Supplier> {
    return axios.get<Supplier>(`/suppliers/new`).then(resp => resp?.data);
  }

  create(supplier: Supplier): Promise<Supplier> {
    return axios.post<Supplier>(`/suppliers`, { supplier }).then(resp => resp?.data);
  }

  update(supplierId: number | string, supplier: Partial<Supplier>): Promise<Supplier> {
    if (!supplierId) throw new Error('Missing supplier id in update');
    return axios.put<Supplier>(`suppliers/${supplierId}`, { supplier }).then(resp => resp?.data);
  }

  updateFiles(supplierId: number | string, files: Record<string, File>): Promise<Supplier> {
    if (!supplierId) throw new Error('Missing supplier id in updateFiles');

    const formData = Object.entries(files).reduce((prev, curr) => {
      prev.append(`supplier[${curr[0]}]`, curr[1]);
      return prev;
    }, new FormData());

    return axios
      .put<Supplier>(`suppliers/${supplierId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(resp => resp?.data);
  }

  createRib(supplierId: number | string, rib: SupplierRib): Promise<Supplier> {
    if (!supplierId) throw new Error('Missing supplier id in createRib');

    return axios.post<Supplier>(`suppliers/${supplierId}/ribs`, { rib }).then(resp => resp?.data);
  }

  updateRib(supplierId: number | string, rib: SupplierRib): Promise<Supplier> {
    if (!supplierId) throw new Error('Missing supplier id in updateRib');
    if (!rib.id) throw new Error('Missing RIB id in updateRib');

    return axios.put<Supplier>(`suppliers/${supplierId}/ribs/${rib.id}`, { rib }).then(resp => resp?.data);
  }

  deleteRib(supplierId: number | string, ribId: number | string): Promise<Supplier> {
    if (!supplierId) throw new Error('Missing supplier id in deleteRib');
    if (!ribId) throw new Error('Missing RIB id in deleteRib');

    return axios.delete<Supplier>(`suppliers/${supplierId}/ribs/${ribId}`).then(resp => resp?.data);
  }

  delete(supplierId: number): Promise<void> {
    if (!supplierId) throw new Error('Missing supplier id in delete');
    return axios.delete<void>(`/suppliers/${supplierId}`).then(resp => resp?.data);
  }
}

export const suppliersService = new SuppliersService();
