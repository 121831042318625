import axios from '@/core/axios';

import { InvalidReportError, Report, ReportFile } from './reports.types';

export class ReportsService {
  list(serial_no: string): Promise<Report[]> {
    return axios.get<Report[]>('/reports/list', { params: { serial_no: serial_no } }).then(resp => resp?.data);
  }

  createReport(serial_no: string, step: string): Promise<ReportFile> {
    return axios.get<ReportFile>(`/reports/${serial_no}`, { params: { step: step } }).then(resp => resp?.data);
  }

  downloadReport(control_report_id: number, language: string): Promise<ReportFile> {
    return axios
      .get<ReportFile>(`/reports/download/${control_report_id}`, { params: { language: language } })
      .then(resp => {
        const { base_64, filename, type } = resp?.data || {};
        if (!base_64 || !filename || !type) throw new InvalidReportError(control_report_id);
        return { base_64, filename, type };
      });
  }
}

export const reportsService = new ReportsService();
