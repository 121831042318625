import axios from '@/core/axios';

export class AdminDatabaseService {
  getAssets(): Promise<any> {
    return axios.get('/admin/assets', { headers: { Accept: 'application/json' } }).then(resp => {
      return resp?.data;
    });
  }

  get(path, params?): Promise<any> {
    const _path = `/admin${path ? '/' + path : ''}`;
    return axios.get(_path, { headers: { 'Content-Type': null, Accept: 'text/html' }, params }).then(resp => {
      return resp?.data;
    });
  }

  post(path, data, acceptHtml?: boolean): Promise<any> {
    const _path = `/admin${path ? '/' + path : ''}`;
    return axios
      .post(_path, data, {
        headers: { 'Content-Type': 'multipart/form-data', Accept: acceptHtml ? 'text/html' : 'application/javascript' }
      })
      .then(resp => resp);
  }

  put(path, data): Promise<any> {
    const _path = `/admin${path ? '/' + path : ''}`;
    return axios
      .put(_path, data, { headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/javascript' } })
      .then(resp => resp);
  }

  delete(path, params?): Promise<any> {
    const _path = `/admin${path ? '/' + path : ''}`;
    return axios
      .delete(_path, { headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/javascript' }, params })
      .then(resp => resp);
  }
}

export const adminDatabaseService = new AdminDatabaseService();
