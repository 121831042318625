import { Module } from 'vuex';

import { formsService } from '@/services/forms/forms.service';
import { productionOrdersService } from '@/services/production-orders/production-orders.service';
import { ProductionOrder, ProductionOrderDuplicationData } from '@/services/production-orders/production-orders.types';
import { RootState } from '@/store';

export interface ProductionOrderState {
  productionOrder: ProductionOrder;
  productionOrderLoading: boolean;
  preparationLabelLoading: boolean;
}

const state: ProductionOrderState = {
  productionOrder: null,
  productionOrderLoading: false,
  preparationLabelLoading: false
};

const options: Module<ProductionOrderState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setProductionOrder: ({ commit, dispatch }, productionOrder: ProductionOrder | string | number): Promise<void> => {
      if (typeof productionOrder === 'number' || typeof productionOrder === 'string')
        return dispatch('getProductionOrder', productionOrder);
      else commit('productionOrder', productionOrder);
    },
    newProductionOrder: ({ commit, dispatch }): Promise<void> => {
      commit('productionOrder', null);
      commit('productionOrderLoading', true);
      return productionOrdersService
        .newProductionOrder()
        .then(productionOrder => productionOrder && commit('productionOrder', productionOrder))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('productionOrderLoading', false));
    },
    createProductionOrder: ({ commit, dispatch }, productionOrder: ProductionOrder): Promise<void> => {
      commit('productionOrderLoading', true);
      return productionOrdersService
        .create(productionOrder)
        .then(productionOrder => productionOrder && commit('productionOrder', productionOrder))
        .then(() => dispatch('alert/pushSuccess', 'Ordre de fabrication créé avec succès !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('productionOrderLoading', false));
    },
    updateProductionOrder: ({ commit, dispatch }, productionOrder: ProductionOrder): Promise<void> => {
      commit('productionOrderLoading', true);
      return productionOrdersService
        .update(productionOrder.id, productionOrder)
        .then(productionOrder => productionOrder && commit('productionOrder', productionOrder))
        .then(() => dispatch('alert/pushSuccess', 'Ordre de fabrication mis à jour !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('productionOrderLoading', false));
    },
    deleteProductionOrder: ({ commit, dispatch }, productionOrderId: number): Promise<void> => {
      commit('productionOrderLoading', true);
      return productionOrdersService
        .delete(productionOrderId)
        .then(() => commit('productionOrder', null))
        .then(() => dispatch('alert/pushSuccess', 'Ordre de fabrication supprimé !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('productionOrderLoading', false));
    },
    getProductionOrder: ({ commit, dispatch }, productionOrderId: number): Promise<void> => {
      commit('productionOrderLoading', true);
      return productionOrdersService
        .get(productionOrderId)
        .then(productionOrder => productionOrder && commit('productionOrder', productionOrder))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('productionOrderLoading', false));
    },
    duplicateProductionOrder: ({ commit, dispatch }, options: ProductionOrderDuplicationData): Promise<void> => {
      commit('productionOrderLoading', true);
      return productionOrdersService
        .duplicate(options.production_order_id, options.n_times)
        .then(() =>
          dispatch('alert/pushSuccess', `Ordre de fabrication dupliqué ${options.n_times} fois !`, { root: true })
        )
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('productionOrderLoading', false));
    },
    purgeProductionOrders: ({ commit, dispatch }): Promise<void> => {
      commit('productionOrderLoading', true);
      return productionOrdersService
        .purge()
        .then(() => dispatch('alert/pushSuccess', 'Ordres de fabrication purgés !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('productionOrderLoading', false));
    },
    printPreparationLabel: ({ commit, dispatch }, productionOrderId: number): Promise<void> => {
      commit('preparationLabelLoading', true);
      return productionOrdersService
        .getPreparationLabel(productionOrderId)
        .then(
          resp => resp?.zpl && dispatch('printer/printWith', { data: [resp.zpl], printer: 'PREPA' }, { root: true })
        )
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('preparationLabelLoading', false));
    }
  },
  mutations: {
    productionOrder: (state, productionOrder) => (state.productionOrder = productionOrder),
    productionOrderLoading: (state, loading) => (state.productionOrderLoading = loading),
    preparationLabelLoading: (state, loading) => (state.preparationLabelLoading = loading)
  },
  getters: {
    productionOrderCreationForm: (state, getters, rootState) =>
      formsService.getForm(
        'production-order-line-creation',
        state.productionOrder,
        rootState['global-settings'].settings
      ),
    productionOrderForm: (state, getters, rootState) =>
      formsService.getForm('production-order', state.productionOrder, rootState['global-settings'].settings)
  }
};

export default options;
