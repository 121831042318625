import { createApp } from 'vue';
import VueViewer from 'v-viewer';
import moment from 'moment';

import router from './router';
import store from './store';
import App from './App.vue';

import '@/assets/scss/app.scss';
import 'viewerjs/dist/viewer.css';

moment.locale('fr');

export default createApp(App)
  .use(router)
  .use(store)
  .use(VueViewer, {
    defaultOptions: {
      inline: false,
      button: true,
      navbar: false,
      title: false,
      toolbar: false,
      tooltip: false,
      movable: false,
      zoomable: true,
      rotatable: false,
      scalable: false,
      transition: true,
      fullscreen: true,
      keyboard: true
    }
  })
  .mount('#body');

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app');
