import axios from '@/core/axios';

import { Commission, CommissionsListOptions } from './commissions.types';
import { CollectionList } from '../collections/collections.types';

export class CommissionsService {
  async list(opts: CommissionsListOptions = {}): Promise<CollectionList<'commissions'>> {
    const { filters, pagination, options } = opts;

    const resp = await axios.get<CollectionList<'commissions'>>(`/commissions`, {
      params: { ...filters, ...pagination, ...options }
    });
    return resp?.data;
  }

  async get(commissionId: number): Promise<Commission> {
    const resp = await axios.get<Commission>(`/commissions/${commissionId}`);
    return resp?.data;
  }

  async new(): Promise<Commission> {
    const resp = await axios.get<Commission>(`commissions/new`);
    return resp?.data;
  }

  async create(commission: Commission): Promise<Commission> {
    const resp = await axios.post<Commission>(`commissions`, { commission });
    return resp?.data;
  }

  async update(commissionId: number, commission: Partial<Commission>): Promise<Commission> {
    if (!commissionId) throw new Error('Missing consumable id in update');
    const resp = await axios.put<Commission>(`/commissions/${commissionId}`, { commission });
    return resp?.data;
  }

  async delete(commissionId: number): Promise<Commission> {
    if (!commissionId) throw new Error('Missing commission id in delete');
    const resp = await axios.delete<Commission>(`/commissions/${commissionId}`);
    return resp?.data;
  }
}

export const commissionsService = new CommissionsService();
