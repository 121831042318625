import { Module } from 'vuex';

import { RootState } from '@/store';
import { Picking } from '@/services/pickings/pickings.types';
import { pickingsService } from '@/services/pickings/pickings.service';
import { formsService } from '@/services/forms/forms.service';

export interface PickingState {
  picking: Picking;
  pickingLoading: boolean;
  location: any;
  locationLoading: boolean;
}

const state: PickingState = {
  picking: null,
  pickingLoading: false,
  location: null,
  locationLoading: false
};

const options: Module<PickingState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setPicking: ({ commit, dispatch }, picking: Picking | string | number): Promise<void> => {
      if (typeof picking === 'number' || typeof picking === 'string') return dispatch('getPicking', picking);
      else commit('picking', picking);
    },
    newPicking: ({ commit, dispatch }): Promise<void> => {
      commit('picking', null);
      commit('pickingLoading', true);
      return pickingsService
        .newPicking()
        .then(picking => picking && commit('picking', picking))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('pickingLoading', false));
    },
    createPicking: ({ commit, dispatch }, picking: Picking): Promise<void> => {
      commit('pickingLoading', true);
      return pickingsService
        .create(picking)
        .then(picking => picking && commit('picking', picking))
        .then(() => dispatch('alert/pushSuccess', 'Picking(s) créé(s) avec succès !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('pickingLoading', false));
    },
    getPicking: ({ commit, dispatch }, pickingId: number): Promise<void> => {
      commit('picking', null);
      commit('pickingLoading', true);
      return pickingsService
        .get(pickingId)
        .then(picking => picking && commit('picking', picking))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('pickingLoading', false));
    },
    cancelPicking: ({ commit, dispatch }, pickingId: number): Promise<void> => {
      commit('pickingLoading', true);
      return pickingsService
        .cancel(pickingId)
        .then(picking => picking && commit('picking', picking))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('pickingLoading', false));
    }
  },
  mutations: {
    picking: (state, picking: Picking) => (state.picking = picking),
    pickingLoading: (state, isLoading) => (state.pickingLoading = isLoading)
  },
  getters: {
    pickingCreationForm: (state, getters, rootState) =>
      formsService.getForm('picking-creation', state.picking, rootState['global-settings'].settings),
    pickingForm: (state, getters, rootState) =>
      formsService.getForm('picking', state.picking, rootState['global-settings'].settings)
  }
};

export default options;
