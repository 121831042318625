import { Module } from 'vuex';

import { RootState } from '@/store';
import { AppSection, AppSubSection } from '@/services/app-sections/app-sections.types';
import { appSectionsService } from '@/services/app-sections/app-sections.service';

export interface AppSectionState {
  sections: AppSection[];
  activeSectionTree: string[];
}

const state: AppSectionState = {
  sections: appSectionsService.sections,
  activeSectionTree: []
};

const options: Module<AppSectionState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setMainSubSections: async ({ state, commit, getters }, subSections: AppSubSection[]): Promise<void> => {
      commit('sections', [
        ...state.sections.slice(0, getters.mainSectionIndex),
        { ...getters.mainSection, subSections: subSections },
        ...state.sections.slice(getters.mainSectionIndex + 1)
      ]);
    },
    activateSection: async ({ state, commit }, code: string): Promise<void> => {
      const activeSectionTree = appSectionsService.traverseSections(code, state.sections);
      if (activeSectionTree && activeSectionTree.length > 0) commit('activeSectionTree', activeSectionTree);
    },
    clearSection: async ({ commit }): Promise<void> => {
      commit('activeSectionTree', []);
    }
  },
  mutations: {
    sections: (state, sections) => (state.sections = sections),
    activeSectionTree: (state, tree) => (state.activeSectionTree = tree)
  },
  getters: {
    sectionsDico: state => appSectionsService.createSectionsDico(state.sections),
    activeSectionCode: state => state.activeSectionTree && state.activeSectionTree[state.activeSectionTree.length - 1],
    activeSection: (state, getters) => getters.sectionsDico && getters.sectionsDico[getters.activeSectionCode],
    mainSectionCode: state => state.activeSectionTree && state.activeSectionTree[0],
    mainSection: (state, getters) => getters.sectionsDico && getters.sectionsDico[getters.mainSectionCode],
    mainSectionIndex: (state, getters) =>
      state.sections?.findIndex(section => section.code === getters.mainSectionCode),
    mainSubSections: (state, getters) => getters.mainSection && getters.mainSection.subSections,
    mainSubSectionIndex: (state, getters) => {
      if (!getters.mainSubSections || !state.activeSectionTree?.[1]) return;
      return getters.mainSubSections.findIndex(subSec => subSec.code === state.activeSectionTree[1]);
    },
    mainSectionStockId: (state, getters, rootState) => {
      const stocks_status = rootState['global-settings'].settings?.stocks_status || [];
      const stock = stocks_status.find(
        stocks_status =>
          getters.mainSection.stockName.includes(stocks_status[1].stock.name) &&
          getters.mainSection.stockName.includes(stocks_status[1].status.name)
      );
      return stock && stock[0];
    }
  }
};

export default options;
