import { FileStorageAttachmentsService } from '@/services/file-storage-attachemnts/file-storage-attachments.service';
import { FileStorageAttachments } from '@/services/file-storage-attachemnts/file-storage-attachments.types';
import { RootState } from '@/store';
import { Module } from 'vuex';

const fileStorageAttachmentsService = new FileStorageAttachmentsService();

export interface AttachmentsState {
  attachments: FileStorageAttachments[];
  attachmentsCount: number;
  attachmentsLoading: boolean;
}

const state: AttachmentsState = {
  attachments: [],
  attachmentsCount: 0,
  attachmentsLoading: false
};

const options: Module<AttachmentsState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setAttachments: ({ commit, dispatch }, attachments: FileStorageAttachments[] | number): Promise<void> => {
      if (typeof attachments === 'number') {
        return dispatch('getAttachments', attachments);
      } else {
        commit('setAttachments', attachments);
      }
      return Promise.resolve();
    },
    getAttachments: ({ commit, dispatch }, attachmentsId: number): Promise<void> => {
      commit('setAttachmentsLoading', true);
      return fileStorageAttachmentsService
        .getAttachmentsByPurchaseId(attachmentsId)
        .then(attachments => {
          if (attachments) {
            commit('setAttachments', attachments);
          }
        })
        .catch(error => {
          dispatch('alert/pushError', error, { root: true });
        })
        .finally(() => {
          commit('setAttachmentsLoading', false);
        });
    }
  },
  mutations: {
    setAttachments: (state, attachments: FileStorageAttachments[]) => {
      state.attachments = attachments;
    },
    setAttachmentsLoading: (state, loading: boolean) => {
      state.attachmentsLoading = loading;
    }
  },
  getters: {
    attachments: state => state.attachments,
    attachmentsCount: state => state.attachmentsCount,
    attachmentsLoading: state => state.attachmentsLoading
  }
};

export default options;
