import axios from '@/core/axios';

import { CollectionList } from '../collections/collections.types';
import {
  InvalidInvoiceError,
  Order,
  OrderCarrierLabelData,
  OrderInvoiceFile,
  OrderPreparationLabelData,
  OrdersImportResponse,
  OrdersListOptions
} from './orders.types';

export class OrdersService {
  list(opts: OrdersListOptions = {}): Promise<CollectionList<'orders'>> {
    const { filters, pagination } = opts;

    return axios
      .get<CollectionList<'orders'>>(`/orders`, { params: { ...filters, ...pagination } })
      .then(resp => resp?.data);
  }

  get(orderId: number): Promise<Order> {
    return axios.get<Order>(`/orders/${orderId}`).then(resp => resp?.data);
  }

  byMpOrderId(marketplaceOrderId: string): Promise<Order> {
    return axios.get<Order>(`/orders/${marketplaceOrderId}/by_mp_order_id`).then(resp => resp?.data);
  }

  newOrder(): Promise<Order> {
    return axios.get<Order>(`orders/new`).then(resp => resp?.data);
  }

  create(order: Order): Promise<Order> {
    return axios.post<Order>(`orders`, { order }).then(resp => resp?.data);
  }

  update(orderId: number | string, order: Partial<Order>): Promise<Order> {
    if (!orderId) throw new Error('Missing order id in update');
    return axios.put<Order>(`orders/${orderId}`, { order }).then(resp => resp?.data);
  }

  delete(orderId: number): Promise<void> {
    if (!orderId) throw new Error('Missing order id in delete');
    return axios.delete<void>(`/orders/${orderId}`).then(resp => resp?.data);
  }

  addAssociationError(payload: any): Promise<void> {
    return axios.post<void>('/orders/association-errors/' + payload.order, payload).then(resp => resp?.data);
  }

  getCarrierLabel(orderId: number): Promise<OrderCarrierLabelData> {
    return axios.get<OrderCarrierLabelData>(`/orders/${orderId}/carrier-label`).then(resp => resp?.data);
  }

  getPreparationLabel(orderId: number): Promise<OrderPreparationLabelData> {
    return axios.get<OrderPreparationLabelData>(`/orders/${orderId}/preparation-label`).then(resp => resp?.data);
  }

  getInvoice(orderId: number): Promise<OrderInvoiceFile> {
    return axios.get<OrderInvoiceFile>(`/invoices/${orderId}`).then(resp => {
      const { base_64, filename, type } = resp?.data || {};
      if (!base_64 || !filename || !type) throw new InvalidInvoiceError(orderId);
      return { base_64, filename, type };
    });
  }

  downloadInvoice(invoiceId: number): Promise<OrderInvoiceFile> {
    return axios.get<OrderInvoiceFile>(`/invoices/download/${invoiceId}`).then(resp => {
      const { base_64, filename, type } = resp?.data || {};
      if (!base_64 || !filename || !type) throw new InvalidInvoiceError(invoiceId);
      return { base_64, filename, type };
    });
  }

  importOrders(marketplace: string, file: File): Promise<OrdersImportResponse> {
    const formData = new FormData();
    formData.append('marketplace', marketplace);
    formData.append('file', file);
    return axios
      .post<OrdersImportResponse>('/orders/import', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(resp => resp?.data);
  }
}

export const ordersService = new OrdersService();
