import axios from '@/core/axios';

import { Comment } from './comments.types';

class Commentservice {
  index(subject: string, subjectId?: number): Promise<Comment[]> {
    return axios.get<Comment[]>(`/comments/${subject + (subjectId ? '/' + subjectId : '')}`).then(resp => resp?.data);
  }

  create(subject: string, subjectId: number, content: string): Promise<Comment> {
    return axios.post<Comment>(`/comments/${subject}/${subjectId}`, { comment: { content } }).then(resp => resp?.data);
  }
}

export const commentsService = new Commentservice();
