class ColorsService {
  private palette = [
    '#74b9ff',
    '#fab1a0',
    '#a29bfe',
    '#00b894',
    '#fdcb6e',
    '#b2bec3',
    '#ff7675',
    '#0984e3',
    '#e84393',
    '#2d3436'
  ];

  public getColor(index: number): string {
    return this.palette[index % this.palette.length];
  }
}

export const colorsService = new ColorsService();
