import { Module } from 'vuex';

import { RootState } from '@/store';
import { Status, StockStatus } from '@/services/stocks-status/stocks-status.types';
import { stocksStatusService } from '@/services/stocks-status/stocks-status.service';

export interface StockState {
  stockStatus: StockStatus;
  status: Status;
  statuses: Status[];
  stockLoading: boolean;
}

const state: StockState = {
  stockStatus: null,
  stockLoading: false,
  statuses: [],
  status: null
};

const options: Module<StockState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setStock: ({ commit, dispatch }, stock: StockStatus | string | number): Promise<void> => {
      if (typeof stock === 'number' || typeof stock === 'string') return dispatch('getStock', stock);
      else commit('stockStatus', stock);
    },
    get: ({ commit, dispatch }, stockStatusId: number): Promise<void> => {
      commit('stockStatus', null);
      commit('stockLoading', true);

      return stocksStatusService
        .get(stockStatusId)
        .then(stock => stock && commit('stockStatus', stock))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('stockLoading', false));
    },
    getStatusId: ({ commit, dispatch }, StatusId: number): Promise<void> => {
      commit('status', null);
      commit('stockLoading', true);

      return stocksStatusService
        .getStockStatusById(StatusId)
        .then(status => status && commit('status', status))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('stockLoading', false));
    },
    getStatusesByIds: ({ commit, dispatch }, StatusIds: number[]): Promise<void> => {
      commit('setStatuses', []);
      commit('stockLoading', true);
      return stocksStatusService
        .getStatusesByIds(StatusIds)
        .then(statuses => statuses && commit('status', statuses))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('stockLoading', false));

      // try {
      //   const statuses = stocksStatusService.getStatusesByIds(StatusIds);
      //   if (statuses) {
      //     commit('setStatuses', statuses);
      //   }
      // } catch (error) {
      //   dispatch('alert/pushError', error, { root: true });
      // } finally {
      //   commit('stockLoading', false);
      // }
    }
  },
  mutations: {
    stockStatus: (state, stockStatus) => (state.stockStatus = stockStatus),
    status: (state, status) => (state.status = status),
    setStatuses: (state, statuses) => (state.statuses = statuses),
    stockLoading: (state, isLoading) => (state.stockLoading = isLoading)
  }
};

export default options;
