import axios from '@/core/axios';

import { Spare, SparesListOptions } from './spares.types';
import { CollectionList } from '../collections/collections.types';

export class SparesService {
  list(opts: SparesListOptions = {}): Promise<CollectionList<'spares'>> {
    const { filters, pagination, options } = opts;
    return axios
      .get<CollectionList<'spares'>>(`/spares`, { params: { ...filters, ...pagination, ...options } })
      .then(resp => resp?.data);
  }

  update(spareId: number, spare: Partial<Spare>): Promise<Spare> {
    if (!spareId) throw new Error('Missing spare id in update');
    return axios.put<Spare>(`spares/${spareId}`, { spare }).then(resp => resp?.data);
  }
}

export const sparesService = new SparesService();
